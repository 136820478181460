import React, { useState, useRef, useEffect } from "react";
const SingleSelectDropdown = ({
  label,
  options,
  onSelect,
  placeholder,
  placeholderColor,
  labelColor,
  isFit,
  selectedValue, // Add selectedValue prop
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Set the initial selected option based on selectedValue
    if (selectedValue) {
      const initialOption = options.find(option => option.value === selectedValue);
      setSelectedOption(initialOption);
    }
  }, [selectedValue, options]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const singleDropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      singleDropdownRef.current &&
      !singleDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={singleDropdownRef}>
      {label && (
        <label className={`block text-base font-medium text-grey20 mb-2 ${labelColor}`}>
          {label}
        </label>
      )}
      <div
        onClick={toggleDropdown}
        className={`border rounded-md p-3 bg-white ${isFit ? "w-fit gap-x-3" : "w-full gap-x-6"} text-left focus:outline-none cursor-pointer flex items-center justify-between`}
      >
        <span style={{ color: placeholderColor }}>
          {selectedOption ? selectedOption.label : placeholder}
        </span>
        <i className={`bi bi-caret-down w-4 h-4 transform ${isOpen ? "rotate-180" : ""}`}></i>
      </div>
      {isOpen && (
        <div className={`absolute z-30 mt-2 bg-white border rounded-md shadow-sm w-full`}>
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className="p-2 cursor-pointer hover:bg-blue-100 w-full"
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SingleSelectDropdown;
