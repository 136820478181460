import React, { useState } from "react";
import Placeholder from "./components/Placeholder";
import AddCategory from "./components/AddCategory";
import AddReward from "./components/AddReward";
import RewardsList from "./components/RewardsList";
import { useQuery } from "react-query";
import { api } from "../../../../api/reward";
import Loader from "../../../../components/loader/Loader";
import CenteredModal from "../../../../components/Modal/CenteredModal";

const Rewards = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [openModal, setOpenModal] = useState(false);
  const [openReward, setOpenReward] = useState(false);

  const {
    data: rewards,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["Rewards"],
    ({ queryKey }) => {
      return api.getReward({ id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: false,
      select: (data) => data,
    }
  );

  const { data: categories, refetch: refetchCategories } = useQuery(
    ["Categories"],
    ({ queryKey }) => {
      return api.getCategories({ id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  // console.log(rewards);

  if (isLoading || isFetching) return <Loader />;

  return (
    <div className="w-full p-5">
      {rewards?.data?.length ? (
        <RewardsList
          data={rewards?.data}
          categories={categories}
          refetch={refetch}
          isLoading={isLoading}
          setOpenModal={() => setOpenModal(!openModal)}
          setOpenReward={() => setOpenReward(!openReward)}
        />
      ) : (
        <Placeholder setOpenModal={() => setOpenModal(!openModal)} />
      )}
      <CenteredModal
        open={openModal}
        setOpen={setOpenModal}
        title={""}
        onClose={() => setOpenModal(false)}
        // withCloseBtn={moduleMediaFiles.length ? true : false}
        closeButtonLabel={"Save Changes"}
      >
        <AddCategory
          hasRewards={Boolean(rewards?.data?.length)}
          refetch={refetchCategories}
          handleModal={() => setOpenModal(!openModal)}
          handleRewardModal={() => setOpenReward(!openReward)}
        />
      </CenteredModal>
      <CenteredModal
        open={openReward}
        setOpen={setOpenReward}
        title={"Create Reward"}
        onClose={() => setOpenReward(false)}
        width={"w-[650px]"}
        // withCloseBtn={moduleMediaFiles.length ? true : false}
        closeButtonLabel={"Save Changes"}
      >
        <AddReward
          refetch={refetch}
          categories={categories}
          handleModal={() => {
            sessionStorage.removeItem("currentReward");
            setOpenReward(!openReward);
          }}
        />
      </CenteredModal>
    </div>
  );
};

export default Rewards;
