import React, { useState } from 'react'
import ViewQuizAndSports from './pages/viewQuizAndSports/ViewQuizAndSports';
import { Route, Routes as QuizAndSportRoutes } from 'react-router-dom';
import CreateSport from './pages/createSport/CreateSport';
import CreateQuiz from './pages/createQuiz/CreateQuiz';

const Routes = () => {

  const [activeSport, setActiveSport] = useState(null)
  const [activeQuiz, setActiveQuiz] = useState(null)

  return (
    <div>
        <QuizAndSportRoutes>
            <Route 
              path="/" 
              element={
                 <ViewQuizAndSports 
                   activeSport={activeSport}
                   setActiveSport={setActiveSport}
                   activeQuiz={activeQuiz}
                   setActiveQuiz={setActiveQuiz}
                 />
               }
             />
            <Route 
                path="/create-quiz"
                element={
                 <CreateQuiz
                    activeQuiz={activeQuiz}
                    setActiveQuiz={setActiveQuiz}
                 />
                 }
             />
             <Route 
                path="/create-sport"
                element={
                 <CreateSport
                    activeSport={activeSport}
                    setActiveSport={setActiveSport}
                 />
                 }
             />
        </QuizAndSportRoutes>
    </div>
  )
}

export default Routes