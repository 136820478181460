import React from 'react'
import BadgeIcon from '../../../../../../../assets/Badge.png';
import BadgeIconOrange from '../../../../../../../assets/Bagde (5).png';
import BadgeIconGreen from '../../../../../../../assets/Badge (1).png';
import OverviewCard from '../../../../../../../components/Cards/OverviewCards';

type SummaryType = {
    coursesSummary: any
}

const Summary: React.FC<SummaryType> = ({coursesSummary}) => {
  return (
    <div className='grid grid-cols-1 pt-2 pb-4 gap-7 md:grid-cols-4'>
        <OverviewCard 
           title='Total Courses'
           total={coursesSummary?.totalCourses}
           iconPath={BadgeIcon}
        />
        <OverviewCard 
           title='Total Enrollment'
           total={coursesSummary?.totalEnrollment}
           iconPath={BadgeIconOrange}
        />
        <OverviewCard 
           title='Total Completed'
           total={coursesSummary?.totalCompleted}
           iconPath={BadgeIconGreen}
        />
        <OverviewCard 
           title='Active Courses'
           total={coursesSummary?.totalActiveCourses}
           iconPath={BadgeIcon}
        />
    </div>
  )
}

export default Summary