import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ViewLearningPath from './pages/viewLearningPath/ViewLearningPath'
import CreateProgram from './pages/program/createProgram/CreateProgram'
import CreatePath from './pages/path/createPath/CreatePath'

const LearningPath: React.FC = () : React.ReactElement => {
  return (
    <div>
        <Routes>
            <Route path="/" element={<ViewLearningPath/>} />
            <Route path="/create-program" element={<CreateProgram />} />
            <Route path="/create-path" element={<CreatePath />} />
            <Route path="/edit-path/:id" element={<CreatePath />} />
        </Routes>
    </div>
  )
}

export default LearningPath