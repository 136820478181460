import React from "react";
// import img from "../../../../../assets/placeholder.png";
import useLearningPath from "../../../../../hooks/useLearningPath";

const Placeholder = ({ setOpenModal }) => {
  //   const { handleSelectionModal } = useLearningPath();

  const user = JSON?.parse(sessionStorage.getItem("user"));

  return (
    <div className="py-5 mx-auto max-w-[700px] w-full bg-[#F4F8FF] mt-7 flex items-center justify-center flex-col">
      {/* <img src={img} className="w-[50%] h-[50%]" /> */}
      <p className="font-medium">Let's get started, {user?.name}</p>
      <h1 className="mt-4 mb-2 text-customOrange text-2xl font-semibold">
        No Rewards Available
      </h1>
      <p className="font-medium mt-4">
        Get started by creating a custom award category.
      </p>
      <div className="w-full flex items-center justify-center gap-3">
        <button
          className="mt-10 border border-darkBlue rounded-md py-3 px-10 bg-darkBlue text-white text-sm"
          onClick={setOpenModal}
        >
          Create Category
        </button>
        {/* <button
          className="mt-10 border border-darkBlue rounded-md py-3 px-10 bg-darkBlue text-white text-sm"
          // onClick={handleSelectionModal}
        >
          Create Reward
        </button> */}
      </div>
    </div>
  );
};

export default Placeholder;
