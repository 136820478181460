import React from 'react'
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal'
import Table from '../../../../../../../../components/table/Table'
import EmptyData from '../../../../../../../../components/EmptyData'

const ViewSportTeams = ({activeSport, openModal, setOpenModal}) => {

    let columns = React.useMemo( () => [
        {
          title: "ID",
          selector: null,
          key: "id",
          render: ({ index }) =>
            index + 1
        },
        {
            title: "Team",
            selector: "",
            key: "",
            render: ({ row}) => {                      
                 return( 
                    <span className="">{row?.team?.name}</span>
                 )
             },
          },
        {
          title: "Participants",
          selector: "",
          key: "",
          render: ({ row }) => {                      
               return( 
                  <span className="">{row?.team?.numberOfParticipants}</span>
               )
           },
        }, 
        {
            title: "Date Added",
            selector: "",
            key: "",
            render: ({ row }) => {                      
                 return( 
                    <span className="">
                        {`${new Date(row?.team?.createdAt).toLocaleDateString('en-ng')}`}
                  </span>
                 )
             },
          }, 
          {
            title: "Last Updated",
            selector: "",
            key: "",
            render: ({ row }) => {                      
                 return( 
                    <span className="">
                        {`${new Date(row?.team?.updatedAt).toLocaleDateString('en-ng')}`}
                  </span>
                 )
             },
          }, 
    ])
 
    const tableProps = { isLoading: false, columns, data: activeSport?.sportTeams }

  return (
    <>  
       <CenteredModal
          title={
            <h5 className='text-md font-semibold bi bi-eye'> 
               &nbsp; {activeSport?.name}'s Teams
           </h5>
          }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[600px] h-auto overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Close'}
        btnBgColor={'bg-secondary'}
        onClose={ () => {
            setOpenModal(false)
        }}
      > 
        <div className='text-left'>
           {
             activeSport?.sportTeams?.length ? <Table {...tableProps} /> :
             <EmptyData text={`There is no team for ${activeSport?.name}`} />
           }
        </div>
      </CenteredModal>
    </>
  )
}

export default ViewSportTeams