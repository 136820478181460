import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import Index from './pages/Index'
import CreateFeedback from './pages/create-feedback/CreateFeedback';
import CreateFeedbackTemplate from './pages/create-feedback-template/CreateFeedbackTemplate';

import { useQuery } from 'react-query';
// import Loader from '../../../../../../components/loader/Loader';
import ViewTemplates from './pages/view-templates/ViewTemplates';
import EditFeedback from './pages/edit-feedback/EditFeedback';
import ViewResponse from './pages/view-response/ViewResponse';
import { feedbackApi } from '../../../../api/feedback';
import Loader from '../../../../components/loader/Loader';

const Feedback = () => {
 const [selectedQuestions, setSelctedQuestions] = useState([])
 const [templeteName, setTemplateName] = useState('')

 const { data: templates, isLoading} = useQuery(
    ['has templates', { perPage: 5, currentPage: 1, }],
    ({queryKey}) => { 
        return feedbackApi.getFeedbackTemplates({...queryKey[1]});
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
  });

  return (
    <section>
        <Routes>
          <Route path='/' element={  
             isLoading ? <Loader /> :
              templates?.data?.length ? 
              <ViewTemplates /> : <Index />
           } />
          <Route path='/create-feedback' element={<CreateFeedback />} />
          <Route path='/edit-feedback' element={<EditFeedback />} />
          <Route path='/view-response' element={<ViewResponse />} />
          <Route 
             path='/create-feedback-template' 
             element={
                 <CreateFeedbackTemplate 
                    selectedQuestions={selectedQuestions}
                    setSelctedQuestions={setSelctedQuestions}
                    templeteName={templeteName}
                    setTemplateName={setTemplateName}
                  />
                }
          />
      </Routes>
    </section>
  )
}

export default Feedback