import React from "react";

import {
  StackedBarChartOption,
  } from "../../../../../../utils/chartOptions";
import StackedBarChart from "../../../../../../components/Charts/StackedBarChart";

const GoalSubmittedChart = () => {
//   const locationData = attendants?.summary?.total_attendant?.map(
//     (data) => data.location
//   );

  const attendeesData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "jul","Aug","Sep","Oct","Nov","Dec" ],
    // labels: locationData,
    datasets: [
        {
            label: "complete",
            backgroundColor: "#E5A70B",
            data: [10, 20, 30, 40, 50, 10, 20, 30, 40, 50,60,80],
            borderWidth: 1,
          },
          {
            label: "in-progress",
            backgroundColor: "#00B3A7",
            data: [20, 30, 40, 50, 60, 10, 20, 30, 40, 50,60,80],
            borderWidth: 1,
          },
    //   {
    //     label: "No of attendees",
    //     data: attendants?.summary?.total_attendant?.map(
    //       (item) => item.location_count
    //     ),
    //     backgroundColor: ["#E5A70B", "#00B3A7"],
    //     borderWidth: 1,
    //   },
    ],
  };
  return (
    <div className="bg-white p-3 rounded-lg shadow">
     <div>
        <h2 className="text-lg text-[#0C0D0F] font-semibold">Goals Submitted</h2>
        <p className="text-sm text-[#727A90] font-medium">Complete and In-progress</p>
     </div>
      <StackedBarChart
        data={attendeesData}
        options={StackedBarChartOption}
      />
    </div>
  );
};

export default GoalSubmittedChart;
