import React from 'react'

const Card = ({title, image, total}) => {
  return (
    <div className='bg-white rounded-xl px-3 py-4 shadow-lg border border-gray-200'>
        <header className='flex justify-between'>
            <h2 className='text-xs'>{title}</h2>
            <img src={image} className='w-[30px] h-[30px]' />
        </header>
        <span className='text-xl font-semibold'>
            {total}
        </span>
    </div>
  )
}

export default Card