import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import SingleSelectDropdown from "../../../../../pages/Admin/components/SingleSelectDropdown";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const meetingOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];
const Options = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];
const CreateMeeting = ({ nextStep }) => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [selectTimeZone, setSelectTimezone] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [meetingType, setMeetingType] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const LOCAL_STORAGE_KEY = "meetingFormData";
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    topic: "",
    summary: "",
    duration: "",
    password: "",
  };
  const handlePrivateMeeting = (option) => {
    setMeetingType(option.value);
  };

  const handleSelectedActivity = (option) => {
    setIsRecording(option.value);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    const dateTimeString = date.toISOString().split(".")[0];
    setDateTime(dateTimeString);

    // Extract the timezone string
    const timeZoneString = date.toString().match(/GMT[^\s]*/)[0];
    setSelectTimezone(timeZoneString);
  };

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("Meeting topic is required"),
    summary: Yup.string().required("Meeting description is required"),
    duration: Yup.number()
      .typeError("Duration must be a number")
      .required("Duration is required and must be a number"),
    password: Yup.string().required("Password is required"),
  });

  // Load form data from localStorage
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (savedData) {
      setMeetingType(savedData.meetingType);
      setIsRecording(savedData.isRecording);
      setSelectTimezone(savedData.selectTimeZone);
      setDateTime(savedData.dateTime);
      if (savedData.startDate) setStartDate(new Date(savedData.startDate));
    }
  }, []);

  // Handle saving form data to localStorage
  const saveFormData = (values) => {
    const extendedValues = {
      ...values,
      meetingType,
      dateTime,
      selectTimeZone,
      isRecording,
      startDate: startDate.toISOString(),
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(extendedValues));
  };

  const handleNext = (values) => {
    saveFormData(values);
    const extendedValues = {
      ...values, // Existing form values
      meetingType, // Boolean for meeting type
      dateTime, // Date and time in ISO format
      selectTimeZone, // Time zone string
      isRecording, // Boolean for show type
    };
    nextStep(extendedValues); // Pass extended values to the next step
  };

  return (
    <div className="p-10">
      {/* <div className="mb-6">
        <h1 className="text-[31px] font-bold text-[#1D1F2C]">Zoom Meeting </h1>
      </div> */}
      <div className="">
        <h3 className="text-lg font-semibold text-[#091F46]">
          STEP 1: Set Up Meeting
        </h3>
        <p className="font-medium text-[#4A4C56] text-base">
          Visit your Zoom account and fetch the API ket to connect Zoom.{" "}
          <Link
            to="https://developers.zoom.us/docs/internal-apps/create/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-[#007AFF]">Go to Zoom website</span>
          </Link>
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleNext}
      >
        {({ values, setFieldError, setFieldValue }) => (
          <Form>
            <div className="mt-8">
              <div className="flex gap-6 mb-6">
                <div className="flex flex-col lg:w-2/3">
                  <label
                    htmlFor="topic"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Meeting Name
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <Field
                    type="text"
                    id="topic"
                    className={`input-style bg-white`}
                    name="topic"
                    placeholder="Enter meeting topic"
                    onChange={(e) => {
                      setFieldValue("topic", e.target.value);
                      saveFormData({ ...values, topic: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="topic"
                    component="div"
                    className="error-style"
                  />
                </div>

                <div className="flex flex-col lg:w-1/3">
                  <label
                    htmlFor="password"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Password
                  </label>
                  <div className="flex relative">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      className="input-style bg-white"
                      placeholder="Enter your password"
                    />
                    <button
                      type="button"
                      aria-label="Toggle password visibility"
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-3 text-gray-500 cursor-pointer focus:outline-none"
                    >
                      {showPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </button>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="p"
                    className="error-style"
                  />
                </div>
              </div>

              <div className="flex flex-col mb-6">
                <label
                  htmlFor="as"
                  className="mb-1 text-base font-medium text-gray600"
                >
                  Meeting Summary
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <Field
                  as="textarea"
                  id="summary"
                  className={`input-style bg-white   
                       py-10   placeholder-gray-400`}
                  name="summary"
                  placeholder="Enter meeting description"
                  onChange={(e) => {
                    setFieldValue("summary", e.target.value);
                    saveFormData({ ...values, summary: e.target.value });
                  }}
                />
                <ErrorMessage
                  name="summary"
                  component="div"
                  className="error-style"
                />
              </div>
            </div>

            <div className="grid grid-cols-4 gap-6 items-center mb-6">
              <div className="flex flex-col ">
                <label
                  htmlFor="duration"
                  className="mb-1 text-base font-medium text-gray600"
                >
                  Duration
                </label>
                <div className="flex relative ">
                  <Field
                    type={"number"}
                    id="duration"
                    name="duration"
                    className="input-style bg-white"
                    placeholder="Enter your duration"
                    onChange={(e) => {
                      setFieldValue("duration", e.target.value);
                      saveFormData({ ...values, duration: e.target.value });
                    }}
                  />
                </div>
                <ErrorMessage
                  name="duration"
                  component="p"
                  className="error-style"
                />
              </div>

              <div className="flex flex-col">
                
                <label
                    htmlFor="date"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                     Date and Time{" "}
                  </label>

                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => {
                    handleDateChange(date);
                    saveFormData(values); // Save after date change
                  }}
                  // onChange={handleDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  className="input-style bg-white"
                />
              </div>
              
              <div className="flex flex-col">
                <p className="mb-1 text-base font-medium text-gray600">
                  Private Meeting
                </p>
                <SingleSelectDropdown
                  options={meetingOptions}
                  onSelect={handlePrivateMeeting}
                  placeholder="private meeting"
                  placeholderColor="#A5A5A5"
                />
              </div>

              <div className="flex flex-col">
                <p className="mb-1 text-base font-medium text-gray600">
                  Enable Recording
                </p>
                <SingleSelectDropdown
                  options={Options}
                  onSelect={handleSelectedActivity}
                  placeholder="Select storage"
                  placeholderColor="#A5A5A5"
                />
              </div>
            </div>

            <div className="flex  gap-x-6 items-center">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className={`w-full font-semibold bg-[#E8EDF4] border border-customBlue text-customBlue p-3 rounded-lg text-xs`}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`w-full font-semibold bg-customBlue text-white p-3 rounded-lg text-xs`}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateMeeting;
