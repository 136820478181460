import React, {useEffect, useState} from 'react'
import Table from '../../../../../../../../components/table/Table';
import { overdueAccounts } from '../../../../components/dummyData';
import Search from '../../../../../../../../components/Inputs/Search';
import { useQuery } from 'react-query';
import { api } from '../../../../../../../../api/billing';
import EmptyData from '../../../../../../../../components/EmptyData';
import Pagination from '../../../../../../../../components/pagination/Pagination';

const OverDueAccount = () => {

    const [perPage, setPerPage] = useState(5);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const { data: pendingPayments, isLoading, refetch } = useQuery(
      ["pending payments", { currentPage, search, perPage }],
      ({ queryKey }) => {
        return api.getPendingPayments({...queryKey[1]});
      },
      {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => data,
      }
    );

    useEffect( () => {
      refetch()
    }, [search, currentPage, perPage] )

    let columns = React.useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 /*+
              ((clients?.meta?.page || 1) - 1) * (clients?.meta?.perPage || perPage ),*/
          },
          {
            title: "Client Name",
            selector: "clientName",
            key: "clientName",
            render: ({ row }) => {                      
                 return( 
                    <span className="">{row?.organization?.name}</span>
                 )
             },
          },
          {
            title: "Email",
            selector: "email",
            key: "email",
            render: ({ item, row }) => {                      
                 return( 
                    <span className="">{row?.organization?.email || '------'}</span>
                 )
             },
          },
          {
            title: "Phone Number",
            selector: "",
            key: "",
            render: ({ row }) => {                      
                 return( 
                    <span className="">{row?.organization?.phoneNumber || '------'}</span>
                 )
             },
          },
          {
            title: "Amount ($)",
            selector: "amount",
            key: "amount",
            render: ({ item = 500}) => {                      
                if(item < 1000){
                   return <span className="bg-red-100 rounded text-red-500 py-1 px-2 text-[11px]">
                              {item}
                           </span>;
                }
                else if(item < 10000 && item > 1000){
                  return <span className="bg-blue-100 rounded text-blue-500 py-1 px-2 text-[11px]">
                         {item}
                     </span>;
                }
                else return <span className="text-green-500 bg-green-100 rounded py-1 px-2 text-[11px]">
                       {item}
                  </span>;
             },
          },
          {
            title: "Category",
            selector: "category",
            key: "category",
            render: ({ item }) => {           
              return <span className="">{item}</span>;
            },
          },
          {
            title: "Payment Status",
            selector: "status",
            key: "status",
            render: ({ item}) => {                      
                if( item !=='approved' ){
                   return <span className="rounded-full text-primary py-1 px-2 text-[11px]">
                              {item}
                           </span>;
                }
                else return <span className="text-green-500 bg-green-100 rounded-full py-1 px-2 text-[11px]">
                       {item}
                  </span>;
             },
          },
          {
            title: "Action",
            selector: null,
            key: null,
            render: () => {           
              return <span className="text-green-500 font-medium">Sent A Reminder</span>;
            },
          },
        ],
        [1, 20, 20]
      );

    const tableProps = { isLoading: false, columns, data: pendingPayments?.data }

  return (
    <div>
      <h5 className='pb-3 flex justify-between items-center border-b font-medium border-gray-200 text-md'>
        Pending Payments
        <Search 
          className={'w-[300px]'}
          onChange={ (event) => setSearch( event.target.value) } 
          placeholder={'e.g access bank'} 
       />
      </h5>
        <p className='text-gray-500 mt-2 text-xs'>
            Clients with most billing activities
        </p>
        { pendingPayments?.data?.length ?
          <Table {...tableProps} />
          : <EmptyData text={'No any pending client payment found.'} />
        }
        <Pagination 
          data={pendingPayments?.meta}
          perPage={perPage}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          setPerPage={setPerPage}
        />
    </div>
  )
}

export default OverDueAccount