import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import { api } from "../../../../../api/zoom";

const ZoomApi = () => {
  const navigate = useNavigate();
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const [buttonText, setButtonText] = useState("Save Changes");

  const validationSchema = Yup.object().shape({
    accountID: Yup.string().required("Account ID is required"),
    clientID: Yup.string().required("Client ID is required"),
    clientSecret: Yup.string().required("Client Secret is required"),
  });

  const initialValues = {
    accountID: "",
    clientID: "",
    clientSecret: "",
  };

  const createZoomSettingMutation = useMutation(api.createZoomSetting, {
    onSuccess: (response) => {
      toast.remove();
      toast.success(response.message);
      setButtonText("Saved"); // Change button text to "Saved"
    },
    onMutate: () => {
      toast.loading("Loading...");
      setButtonText("Saving..."); // Change button text to "Saving..."
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
      setButtonText("Save Changes"); // Revert button text to "Save Changes"
    },
  });

  const handleCreateZoomSetting = (values, actions) => {
    createZoomSettingMutation.mutate(
      {
        orgId,
        payload: {
          accountId: values.accountID,
          clientId: values.clientID,
          clientSecret: values.clientSecret,
          isEnabled: true,
        },
      },
      {
        onSuccess: () => {
          actions.resetForm(); // Reset the form after successful mutation
          actions.setSubmitting(false);
          setButtonText("Save Changes"); // Ensure form is no longer submitting
        },
      }
    );
  };

  const handleTestConnection = async (values, actions) => {
    toast.loading("Loading...");
    let payload = {
      accountId: values.accountID,
      clientId: values.clientID,
      clientSecret: values.clientSecret,
    };

    try {
      const response = await api.testConnection({ orgId, payload });
      toast.remove();
      toast.success(response.message);
      // navigate("/admin/zoom/meetings")
    } catch (error) {
      toast.remove();
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="p-10">
      <div className="mb-16">
        <h3 className="text-2xl font-semibold text-[#091F46]">
          Set up your Zoom Integration
        </h3>
        <p className="font-medium text-[#8E8E8E] text-sm">
          Visit your Zoom account and fetch the API key to connect Zoom.{" "}
          <Link
            to="https://developers.zoom.us/docs/internal-apps/create/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-[#007AFF]">Go to Zoom website</span>
          </Link>
        </p>
      </div>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) =>
            handleCreateZoomSetting(values, actions)
          }
        >
          {({ isSubmitting, values, actions }) => (
            <Form>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-20">
                <div className="flex flex-col">
                  <label
                    htmlFor="accountID"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Account ID
                  </label>
                  <div className="flex relative">
                    <Field
                      type={"text"}
                      id="accountID"
                      name="accountID"
                      className="input-style bg-white"
                      placeholder="Enter your accountID"
                    />
                  </div>
                  <ErrorMessage
                    name="accountID"
                    component="p"
                    className="error-style"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="clientID"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Client ID
                  </label>
                  <div className="flex relative">
                    <Field
                      type={"text"}
                      id="clientID"
                      name="clientID"
                      className="input-style bg-white"
                      placeholder="Enter your clientID"
                    />
                  </div>
                  <ErrorMessage
                    name="clientID"
                    component="p"
                    className="error-style"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="clientSecret"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Client Secret
                  </label>
                  <div className="flex relative">
                    <Field
                      type={"text"}
                      id="clientSecret"
                      name="clientSecret"
                      className="input-style bg-white"
                      placeholder="Enter your clientSecret"
                    />
                  </div>
                  <ErrorMessage
                    name="clientSecret"
                    component="p"
                    className="error-style"
                  />
                </div>
              </div>
              <div className="mt-5 flex gap-x-6 items-center">
                <button
                  type="submit"
                  className={`w-full font-semibold bg-[#E8EDF4] border border-customBlue text-customBlue p-3 rounded-lg text-xs`}
                  disabled={isSubmitting} // Disable the button while submitting
                >
                  {isSubmitting ? "Saving..." : buttonText}
                </button>
                <button
                  type="button" // Use "button" type to avoid triggering form submission
                  onClick={() => handleTestConnection(values, actions)} // Pass values to handleTestConnection
                  className={`w-full font-semibold bg-customBlue text-white p-3 rounded-lg text-xs`}
                >
                  Check Connection
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ZoomApi;
