import React from 'react'
import Table from '../../../../../../../components/table/Table';
import Loader from '../../../../../../../components/loader/Loader';

const LearnersTable = ({learners, perPage, isLoading}) => {

    let columns = React.useMemo( () => [
        {
          title: "ID",
          selector: null,
          key: "id",
          render: ({ index }) =>
            index +
            1 +
            ((learners?.meta?.page || 1) - 1) * (learners?.meta?.perPage || perPage ),
        },
        {
            title: "Name",
            selector: "name",
            key: "name",
            render: ({ item, row }) => {                      
                 return( 
                    <span className="">{row?.participant?.user?.name}</span>
                 )
             },
          },
          {
            title: "Email",
            selector: null,
            key: null,
            render: ({ item, row }) => {                      
                 return( 
                    <span className="">{row?.participant?.user?.email}</span>
                 )
             },
          },
        {
          title: "Date Created",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.participant?.createdAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Last Updated",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.participant?.updatedAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
      ],
      [1, 20, 20]
    );
    
    const tableProps = { isLoading, columns, data: learners?.data }

    if(isLoading) return <Loader />

  return (
    <div>
         <Table {...tableProps} /> 
    </div>
  )
}

export default LearnersTable