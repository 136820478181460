import React, { useEffect, useMemo, useState } from 'react'
import StarterPage from './components/StarterPage'
import { useQuery } from 'react-query';
import { courses } from '../../../../../../app-api/courses';
import { Link } from 'react-router-dom';
import EmptyPlaceHolder from '../../../../../../components/EmptyPlaceHolder';
import Table from '../../../../../../components/table/Table';
import Pagination from '../../../../../../components/pagination/Pagination';

const ViewModules = () => {

    const [perPage, setPerPage] = useState<number>(5);
    const [search, setSearch] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isEmpty, setIsEmpty] = useState<boolean>(false)
    const [touched, setTouched] = useState<boolean>(false)
  
    const { data: modules, isLoading, refetch } = useQuery(
      ["Modules",  currentPage, search, perPage ],
      () => {
        return courses.getModules({ currentPage, search, perPage });
      },
      {
        cacheTime: 10000,
        staleTime: 10000,
        refetchOnWindowFocus: true,
        select: (data: any) => data,
        onSuccess: (response: any) => {
            if(response?.meta?.total) {
                setIsEmpty(false)
                setTouched(true)
            }
            else setIsEmpty(true)
          }
      }
    );

    useEffect( () => {
        refetch()
    }, [])

    let columns = useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index } : {index: number}) =>
              index +
              1 +
              ((modules?.meta?.page || 1) - 1) *
                (modules?.meta?.perPage || perPage),
          },
          {
            title: "Course Name",
            selector: "name",
            key: "name",
          },
          {
            title: "Assessment",
            selector: "isAssessment",
            key: "isAssessment",
            render: ({ item } : {item: boolean} ) => {
              return <span 
                 className={item ? 'bi bi-check-circle text-primary-green text-sm pl-3' :
                    'bi bi-x-octagon text-red-500 text-sm pl-3'
                 }
               />
            },
          },
          {
            title: "Assessment Type",
            selector: "isAssessment",
            key: "isAssessment",
            render: ({ item, row } : {item: boolean, row: any} ) => {
              return  item ? <span>{row?.assessmentType}</span> : <EmptyPlaceHolder count={5} />
            },
          },
          {
            title: "Multi Language",
            selector: null,
            key: null,
            render: ({ row } : any) => {
              return <span 
              className={ row?.languages?.length > 1 ? 'bi bi-check-circle text-primary-green text-sm pl-3' :
                 'bi bi-x-octagon text-red-500 text-sm pl-3'
              }
             />
            },
          },
          {
            title: "Date Added",
            selector: "createdAt",
            key: "createdAt",
            render: ({ item }: { item : string }) => {
              return (
                <span className="">{`${new Date(item).toLocaleDateString(
                  "en-ng"
                )}`}</span>
              );
            },
          },
          {
            title: "Last Updated",
            selector: "updatedAt",
            key: "updatedAt",
            render: ({ item } : { item : string }) => {
              return (
                <span className="">{`${new Date(item).toLocaleDateString(
                  "en-ng"
                )}`}</span>
              );
            },
          },
          {
            title: "Action",
            selector: null,
            key: "actions",
            render: ({ row } : any) => {
              return (
                <Link
                  className="bi bi-pencil-square text-blue-500 text-md ml-2"
                  to={`/app/admin/modules/edit/${row?.id}`}
                  onClick={() => {
                    sessionStorage.setItem("active-module", JSON.stringify(row));
                  }}
                />
              );
            },
          },
        ],
        [1, 20, 20]
      );
    
    const tableProps = { isLoading, columns, data: modules?.data };

  return (
    <div className='p-5'>  
        <h5 className='flex justify-between items-center text-lg font-semibold'>
            Module Overview
            <Link to={'/app/admin/modules/create'} 
              className='btn bg-primary-blue ml-5'
              onClick={ () => sessionStorage.removeItem('active-module') }
            >
                Create New Module
            </Link>
        </h5>
        {
           (!isEmpty || touched) ?
              <section>
                 <header className='flex justify-between items-center mt-1 mb-5'>
                       <h5 className='text-sm '>
                         Total Modules  ({modules?.meta?.total})
                       </h5>
                        <div className='flex items-center'>
                          <input 
                            type="search" 
                            className='input-style bg-primaryGray w-[400px] mt-4'
                            placeholder='&#128269; Search modules....'
                            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                              setSearch(event.target.value)
                            }}
                          />
                        </div>
                    </header>
                    <div className='p-5 bg-white rounded-xl'>
                      <Table {...tableProps} />
                    </div>
                    <Pagination
                        data={modules?.meta}
                        perPage={perPage}
                        pageNo={currentPage}
                        setPageNo={setCurrentPage}
                        setPerPage={setPerPage}
                    />
                </section>
              :
        <StarterPage />
        }
    </div>
  )
}

export default ViewModules