import React, { useMemo } from 'react'
import { Link } from 'react-router-dom';
import Table from '../../../../../../../components/table/Table';
import EmptyPlaceHolder from '../../../../../../../components/EmptyPlaceHolder';

type CoursesTableType = {
    perPage: number,
    courses: any,
    isLoading: boolean
}

const CoursesTable: React.FC<CoursesTableType> = ({courses, perPage, isLoading}) : React.ReactElement => {

    let columns = useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index } : {index: number}) =>
              index +
              1 +
              ((courses?.meta?.page || 1) - 1) *
                (courses?.meta?.perPage || perPage),
          },
          {
            title: "Image",
            selector: "imagePath",
            key: "imagePath",
            render: ({ item } : {item: string}) => {
              return (
                <img
                  className="w-[35px] rounded-lg object-cover h-[35px] ml-2"
                  alt=""
                  src={item}
                />
              );
            },
          },
          {
            title: "Course Name",
            selector: "name",
            key: "name",
          },
          {
            title: "Status",
            selector: "status",
            key: "status",
            render: ({ item }: { item : string }) => {
              if (item === "published") {
                return <span className="text-[#00984C] w-[80px] block text-center py-1 rounded-md bg-green-200">{item}</span>;
              } else return <span className="text-[#FF9100] block w-[80px] text-center py-1 rounded-md bg-orange-200">{item}</span>;
            },
          },
          {
            title: "Category",
            selector: "category",
            key: "category",
            render: ({ row } : any ) => {
              return <span className="">{row?.category?.name}</span>;
            },
          },
          {
            title: "Multi Language",
            selector: null,
            key: null,
            render: ({ row } : any) => {
              return <span 
              className={ row?.languages?.length > 1 ? 'bi bi-check-circle text-primary-green text-sm pl-3' :
                 'bi bi-x-octagon text-red-500 text-sm pl-3'
              }
             />
            },
          },
          {
            title: "Course Code",
            selector: "courseCode",
            key: "courseCode",
            render: ({ item } : { item : string }) => {
              return <span className="">{item || <EmptyPlaceHolder count={1} /> }</span>;
            },
          },
          {
            title: "Tag",
            selector: "courseTag",
            key: "courseTag",
            render: ({ item } : { item : string }) => {
              return (
                <span className="">{item ||  <EmptyPlaceHolder count={1} /> }</span>
              );
            },
          },
          {
            title: "Date Added",
            selector: "createdAt",
            key: "createdAt",
            render: ({ item }: { item : string }) => {
              return (
                <span className="">{`${new Date(item).toLocaleDateString(
                  "en-ng"
                )}`}</span>
              );
            },
          },
          {
            title: "Last Updated",
            selector: "updatedAt",
            key: "updatedAt",
            render: ({ item } : { item : string }) => {
              return (
                <span className="">{`${new Date(item).toLocaleDateString(
                  "en-ng"
                )}`}</span>
              );
            },
          },
          {
            title: "Action",
            selector: null,
            key: "actions",
            render: ({ row } : any) => {
              return (
                <Link
                  className="bi bi-pencil-square text-blue-500 text-md"
                  to={`/admin/course/edit/${row?.id}`}
                  onClick={() => {
                    sessionStorage.setItem("active-course", JSON.stringify(row));
                  }}
                />
              );
            },
          },
        ],
        [1, 20, 20]
      );
    
    const tableProps = { isLoading, columns, data: courses?.data };

  return (
    <div>
         <Table {...tableProps} />
    </div>
  )
}

export default CoursesTable