import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
import AddCourse from "./AddCourse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowsUpDownLeftRight,
  faGear,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { arrayMoveImmutable } from "array-move";
import SortableList, { SortableItem } from "react-easy-sort";
import { api } from "../../../../../../../../api/learningPath";
import img_icon from "../../../../../../../../assets/img_icon.png";
import Settings from "./Settings";
import { useNavigate } from "react-router";
import AddResoursePanel from "./AddResoursePanel";
import EmptyData from "../../../../../../../../components/EmptyData";
import AllAddedResources from "./AllAddedResources";
import { Link } from "react-router-dom";

const PathContent: React.FC = () : React.ReactElement => {   

  const [selectedCourses, setSelectedCourses] = useState<any[]>([]);
  const [addCourseModal, setAddCourseModal] = useState<boolean>(false);
  const [settingsModal, setSettingsModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user")!);
  const active_path = JSON.parse(sessionStorage.getItem("active-path")!);

  const {
    data: resources,
    isLoading,
    refetch,
  } = useQuery(
    ["get-journey-by-id"],
    async () => {
      const response = await api.getIndividualLearningPath({
        org_id: user?.org?.id,
        id: active_path?.id,
      });
     return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
      onSuccess: response => {
         if(response?.journeyCourses?.length) setSelectedCourses(
            response?.journeyCourses?.map( (course: any) => {
                return course?.course
            } )
         )
      }
    }
  );

  const addCourseMutation: any = useMutation(api.addCourses, {
    onSuccess: (data) => {
      toast.remove();
      toast.success('Course deleted successfully');
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response?.data?.message);
    },
  });

  const handleSettingsModal = () => {
    setSettingsModal(!settingsModal);
  };

  const current_path = JSON.parse(sessionStorage.getItem("active-path")!);

  const handleAddCourseModal = () => {
    setAddCourseModal(!addCourseModal);
  };

  const onSortEnd = (oldIndex: any, newIndex: any) => {
    setSelectedCourses((array) =>
      arrayMoveImmutable(array, oldIndex, newIndex)
    );
  };

  const handlePublish = () => {
    sessionStorage.removeItem("active-path");
    toast.success("Published successfully...");
    navigate("/app/admin/learning-path/");
  };

  const removeCoursefromList = (selected_course: any) => {
  
      setSelectedCourses((prevCourses) =>
        prevCourses.filter((course) => course?.id !== selected_course.id)
      );

     addCourseMutation.mutate({
      payload: {
        newCourseIds: selectedCourses.filter( course => course?.id !== selected_course?.id ).map((course, index) => {
          return course?.id
        }),
        deletedCourseIds: selectedCourses?.filter((course) => course?.id === selected_course.id)?.map(
          course => course?.id,
        )
      },
      org_id: user?.org?.id,
      journey_id: current_path?.id,
    });
    
  };

  return (
    <React.Fragment>
      <h3 className="text-md items-center font-semibold mt-3 flex justify-between">
          Add Courses & Resources To Learning Path            
          <div className="flex items-center">
            <Link to={'/app/admin/learning-path/'} className='btn-light pl-8 pr-8 bg-primaryGray'>
               Back
            </Link>
            <button
              onClick={handlePublish}
              className={"bg-darkBlue ml-5 text-white text-xs rounded-md px-10 py-3"}
            > Save Changes </button>
          </div>
      </h3>
      <Settings
        settingsModal={settingsModal}
        handleSettingsModal={handleSettingsModal}
      />
      <AddCourse
        addCourseModal={addCourseModal}
        handleAddCourseModal={handleAddCourseModal}
        selectedCourses={selectedCourses}
        setSelectedCourses={setSelectedCourses}
      />
      <div className="mt-1 relative mr-2 rounded-lg p-2"> 
        <div className="w-full mb-2 flex items-end justify-between">
        </div>
        <div className="flex items-start justify-between gap-2">
          <section className="w-full md:w-[40%] md:mx-auto md:mt-3 flex items-end gap-[10px] justify-center flex-col p-[20px] border-2 border-dotted border-slate-300 rounded-md">
            <div className="items-center justify-between w-full">
            <h1 className="text-sm pb-2 border-b border-gray-200 mb-2">
              Added Courses
            </h1>
              <button
                onClick={handleSettingsModal}
                className="bg-secondary hidden text-white flex items-center justify-center gap-1 text-xs rounded-md px-4 py-2"
              >
                <FontAwesomeIcon icon={faGear} />
                 Add Prerequisites 
              </button>
            </div>
            {
              selectedCourses?.length ? 
              <SortableList
              onSortEnd={onSortEnd}
              draggedItemClassName="dragged"
              className="my-3 w-full p-2"
            >
              {selectedCourses?.map((course: any, index: any) => (
                <div key={index}>
                  <SortableItem key={course}>
                    <div
                      className={`border-2 border-dotted border-slate-400 flex items-center justify-between cursor-grab w-full px-3 py-2 rounded-md ${
                        index + 1 !== selectedCourses?.length && "mb-2"
                      }`}
                    >
                      <div className="text-sm flex items-center justify-start gap-2">
                        <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                        <img
                          src={img_icon}
                          className="w-10 h-10"
                          alt={`img-icon ${index}`}
                        />
                        <p className="text-xs">{course?.name}</p>
                      </div>
                      <div className="text-sm flex items-center justify-end gap-2">
                        <p className="text-xs">{course?.points}</p>
                        <button onClick={ () => removeCoursefromList(course)}>
                          <FontAwesomeIcon
                            className="text-red-500 ml-2"
                            icon={faTrash}
                          />
                        </button>
                      </div>
                    </div>
                  </SortableItem>
                  {index + 1 !== selectedCourses?.length && (
                    <div className="text-xs flex items-center justify-center w-full my-2 p-2">
                      <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                  )}
                </div>
              ))}
            </SortableList>
            :
             <div className="w-full flex justify-center">
                 <EmptyData text={'No Any Course Selected.'} />
             </div>
            }
            <button
              className={"bg-darkBlue text-white text-sm rounded-md px-10 py-2"}
              onClick={handleAddCourseModal}
            >
              Add courses
            </button>
          </section>

          <section className="w-full md:w-[40%] md:mx-auto md:mt-3 flex items-end gap-[10px] justify-center flex-col p-[20px] border-2 border-dotted border-slate-300 rounded-md">
            <div className="items-center justify-between w-full">
              <h1 className="text-sm pb-2 border-b border-gray-200 mb-2">
                 Added Resources & Media
              </h1>
            <AllAddedResources resources={resources?.resources} isLoading={isLoading} refetch={refetch} />  
            </div>
          </section>
          <section className="bg-white w-[30%] p-3 md:mt-3">
              <AddResoursePanel  
                refetch={refetch}
                addResource = {null}
                processFile = {null}
                setShowResourceModal = {null}
                fileType = { null}
                setFileType = {null}
                withTitle = {true}
                openPanel = {false}
                setModules={null}
                modules={null}
              />
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PathContent;
