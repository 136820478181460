import React from "react";
import Button from "../../../../../../components/Buttons/Button";
import OverviewCard from "../../components/OverviewCard";
import BadgeIcon from "../../../../../../assets/Badge.png";
import BadgeIconOrange from "../../../../../../assets/Bagde (5).png";
import BadgeIconGreen from "../../../../../../assets/Badge (1).png";
import BadgeIconLightGreen from "../../../../../../assets/Group 12.png";
import CompletionSection from "./components/CompletionSection";
import EnrolmentRateSection from "./components/EnrolmentRateSection";
import TopPerformingCoursesSection from "./components/TopPerformingCoursesSection";

const Courses = () => {
  return (
    <section className="p-3 bg-[#fafafa] h-[100vh]">
      <header className="flex justify-between">
        <h5 className="text-lg font-semibold">Courses Overview</h5>
        <div className="w-[200px] mb-2">
          <Button
            text={"Generate Report"}
            bgColor={"bg-darkBlue"}
            width={"w-full"}
          />
        </div>
      </header>
      <section className="grid grid-cols-4 gap-5">
        <OverviewCard
          title={"Total Courses"}
          total={3400}
          iconPath={BadgeIcon}
          text={
            <>
              <span className="text-sm text-green-500">10%</span>
              <span className="text-sm text-gray-500 pl-3"> +150 today</span>
            </>
          }
        />
        <OverviewCard
          title={"Courses In Progress"}
          total={5000}
          iconPath={BadgeIconOrange}
          text={
            <>
              <span className="text-sm text-green-500">10%</span>
              <span className="text-sm text-gray-500 pl-3"> +150 today</span>
            </>
          }
        />
        <OverviewCard
          title={"Total Completed"}
          total={3400}
          iconPath={BadgeIconGreen}
          text={
            <>
              <span className="text-sm text-green-500">10%</span>
              <span className="text-sm text-gray-500 pl-3"> +150 today</span>
            </>
          }
        />
        <OverviewCard
          title={"Completion Rate"}
          total={"80%"}
          iconPath={BadgeIconLightGreen}
          text={
            <>
              <span className="text-sm text-green-500">10%</span>
              <span className="text-sm text-gray-500 pl-3"> +150 today</span>
            </>
          }
        />
      </section>
      <section className="mt-5 md:flex justify-between">
        <div className="bg-white md:w-[33%] rounded-lg shadow">
          <CompletionSection />
        </div>
        <div className="bg-white md:w-[65%] p-3 rounded-lg shadow">
          <h5 className="text-md font-medium">Enrollment rate</h5>
          <EnrolmentRateSection />
        </div>
      </section>
      <div className="bg-white mt-4 p-3 rounded-lg shadow">
        <TopPerformingCoursesSection />
      </div>
    </section>
  );
};

export default Courses;
