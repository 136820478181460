import React from "react";
import StepButton from "./StepButton";
import toast from "react-hot-toast";

const ScreenNavigator = ({ activeScreen, setActiveScreen, set1Validated,title1,title2 }) => {
  const navigateScreen = (index, isValid) => {
    if (isValid) setActiveScreen(index);
    else {
      toast.remove();
      toast.error(`please complete step ${index - 1} before you proceed.`);
    }
  };

  return (
    <div className="flex mt-3">
      <StepButton
        completed={activeScreen >= 1}
        index={1}
        title={title1}
        activeScreen={activeScreen}
        onClick={() => navigateScreen(1, true)}
      />
      <StepButton
        index={2}
        title={title2}
        onClick={() => navigateScreen(2, set1Validated)}
        activeScreen={activeScreen}
        // completed={activeScreen >= 2}
      />
    </div>
  );
};

export default ScreenNavigator;
