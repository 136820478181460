import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ViewCohort from './viewCohort/ViewCohort'
import CreateCohort from './createCohort/CreateCohort'
import EditCohort from './editCohort/EditCohort'

const Cohorts: React.FC = () : React.ReactElement => {
  return (
    <div>
        <Routes>
            <Route path="/" element={<ViewCohort />} />
            <Route path="/create" element={<CreateCohort />} />
            <Route path="/edit/:id" element={<EditCohort />} />
        </Routes>
    </div>
  )
}

export default Cohorts