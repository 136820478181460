import React from 'react'

const ResourseCard = ({src, onClick, text, target}) => {
  return (
    <button onClick={onClick} className='py-3 shadow hover:cursor-pointer hover:bg-primary hover:text-white'>
        <img 
            src={src} 
            className='w-[18px] h-[18px] block mx-auto' alt="vector icon"
            />
            <label htmlFor={target} className='block text-center text-xs w-full pt-2 text-wrap'>
                 {text}
            </label>
    </button>
  )
}

export default ResourseCard