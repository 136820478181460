import React, {useMemo, useState} from 'react'
import coursePlaceholder from '../../../../../../../assets/online-course.png'
import { courses } from './dummyData';
import Table from '../../../../../../../components/table/Table';
import { useQuery } from "react-query";
import { api } from "../../../../../../../api/report";
import Pagination from '../../../../../../../components/pagination/Pagination';

const TopPerformingCoursesSection = () => {

  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(sessionStorage.getItem('user'))

  const { data: courses, isLoading, refetch } = useQuery(
    ["top performing courses", { search, perPage, currentPage, id: user?.org?.id }],
    ({ queryKey }) => {
      return api.getTopPerformingCourses(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

    let columns = useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 +
              ((courses?.meta?.page || 1) - 1) * (courses?.meta?.perPage || perPage ),
          },
          {
            title: "Course",
            selector: "courseName",
            key: "courseName",      
            render: ({ item, row }) => {           
              return  <div className='flex items-center'>
                   <img className="w-[40px] h-[40px] ml-2" alt='' src={coursePlaceholder} />
                   <div className='ml-1'>
                     <h6 className='text-xs'>{item}</h6>
                     {/*<p className='text-[11px]'>{row?.client}</p>*/}
                   </div>
              </div>;
            },  
          },
          {
            title: "Learners",
            selector: "numberOfLearners",
            key: "numberOfLearners",
            render: ({ item }) => {           
              return <span className="pl-5">{item}</span>;
            }, 
          },
          {
            title: "Ratings",
            selector: "ratings",
            key: "ratings",
            render: ({ item }) => {                          
               return <span className='text-lg pl-5'>{item || '-'}</span>;
            },
          },
          {
            title: "Completions",
            selector: "numberOfCompleted",
            key: "numberOfCompleted",      
            render: ({ item }) => {           
              return <span className="pl-5">{item}</span>;
            },  
          },
          {
            title: "Average Score",
            selector: "averageScore",
            key: "averageScore",      
            render: ({ item }) => {           
              return <span className="text-lg pl-5">{item || '-'}</span>;
            },  
          },
          {
            title: "Completion Rate",
            selector: "completionRate",
            key: "completionRate",      
            render: ({ item }) => {           
                return <span className="block w-[60px] bg-gray-300 rounded-full h-[10px]">
                <span style={{width: `${item}%`}} className={`h-full text-center text-[8px] ${
                    item !== 0 ? `text-white` : 'text-gray-900'
                } bg-armyGreen block rounded-full`}>
                  {item}%
                </span>
              </span>;
            },  
          } 
        ],
        [1, 20, 20]
      );
    
   const tableProps = { isLoading, columns, data: courses?.data }

  return (
    <> 
        <h5 className='text-md font-medium'>Top Performing Courses</h5>
        <Table {...tableProps} />
        <section className={`mt-1 px-3 rouded-lg bg-white`}>
            <Pagination
              data={courses?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
        </section>
    </>
  )
}

export default TopPerformingCoursesSection