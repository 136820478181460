import React, { useEffect, useState } from 'react'
import StarterPage from './components/StarterPage'
import ChooseType from './components/ChooseType';
import { api } from '../../../../../../api/learningPath';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import Pagination from '../../../../../../components/pagination/Pagination';
import LearningPath from './components/LearningPathTable';
import Summary from './components/Summary';

const ViewLearningPath = () => {

const [selectionModal, setSelectionModal] = useState<boolean>(false);
const [perPage, setPerPage] = useState(5);
const [search, setSearch] = useState<string>("");
const [currentPage, setCurrentPage] = useState(1);
const user = JSON.parse(sessionStorage.getItem("user")!);

  const {
    data: paths,
    isLoading,
    refetch,
  } = useQuery(
    ["paths", search, perPage, currentPage ],
    () => {
      return api.getLearningPath({ search, perPage, currentPage, org_id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const {
    data: pathsSummary,
    isLoading: sumaryLoading
  } = useQuery(
    ["paths"],
    () => {
      return api.getLearningPathSummary(user?.org?.id);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data?.data,
    }
  );

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const refreshStatus = queryParams.get('refresh');
  

  useEffect( () => {
    if(refreshStatus === '1') refetch()
  }, [] )

 const createLearningPath = () => {
   setSelectionModal(true)
 }

  return (
    <div className='p-5'>  
        {
          <ChooseType 
              handleSelectionModal={setSelectionModal}
              selectionModal={selectionModal}
          />
        }
         {
           (!paths?.meta?.total && !isLoading)  ?
              <StarterPage
                selectionModal={selectionModal}
                handleSelectionModal={setSelectionModal}
            />
            : <>
              <Summary pathSummary={pathsSummary} />
              <header className='flex justify-between items-center mt-3 mb-5'>
                       <h5 className='text-sm font-semibold'>
                          Learning Path Overview 
                       </h5>
                        <div className='flex items-center'>
                          <input 
                            type="search" 
                            className='input-style bg-primaryGray w-[400px] ml-4'
                            placeholder='&#128269; Search learning paths....'
                            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                              setSearch(event.target.value)
                            }}
                          />
                          <button onClick={createLearningPath} className='btn bg-primary-blue ml-5'>
                              Create New Learning Path
                           </button>
                        </div>
                    </header>
              <LearningPath 
                isLoading={isLoading} perPage={perPage} paths={paths} 
               />
              <Pagination
                data={paths?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
              />
             </>
         }
    </div>
  )
}

export default ViewLearningPath