import React, { useEffect, useState } from 'react'
import { stepType } from '../CreateCourse'
import EmptyData from '../../../../../../../components/EmptyData'
import zipIcon from "../../../../../../../assets/Group.png"
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup'
import SelectModules from './SelectModules';
import Switch from 'react-switch'
import ResourceLoader from '../../../../../../../components/loader/ResourceLoader';
import { errorResponse } from '../../../../../../../components/utils/errorResponse';
import { createResource } from '../../../../../../../app-api/uploadResourse';
import toast from 'react-hot-toast';
import ReorderList from 'react-reorder-list';
import HorizontalTab from '../../../../../../../components/Tab/HorizontalTab';
import { api as courses } from '../../../../../../../api/courses'; 
import { scrollToTop } from '../../../../../../../components/utils/scrollToTop';
import { useNavigate } from 'react-router-dom';

type CourseContentType = {
    setStep : React.Dispatch<React.SetStateAction<stepType>>
    type?: 'edit' | null,
    courseModules?: []
  }

  type initialValuesType = {
    title: string, 
    description: string,
    assessment?: ('pre-assessment' | 'post-assessment' | "pre and post") 
    module_zip: string,
    title_f?: string, 
    description_f?: string,
    module_zip_f?: string,
    title_p?: string, 
    description_p?: string,
    module_zip_p?: string,
  }

  export type moduleType = {
    title: string,
    id: string
  }

  export type moduleLanguageType = {
    language: "english" | "french" | "portuguese",
    symbol: "en" | "fr" | "pt",
    name: string | undefined, 
    description: string | undefined, 
    isDefault: boolean,
    filePath: string | undefined,
    Points: number
}

const CourseContent: React.FC<CourseContentType> = (props) : React.ReactElement => {
 
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [selectedModules, setSelectedModules] = useState<moduleType[]>([])
    const [isAssessment, setIsAssessment] = useState<boolean>(false)
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [useMultipleLanguages, setUseMultipleLanguages] = useState<boolean>(false); 
    const [moduleLoading, setModuleLoading] = useState<boolean>(false)
    const [deletedModule, setDeletedModules] = useState<string[]>([])
    const navigate = useNavigate()

    const initialValues: initialValuesType = {
        title: '', 
        description: '',
        module_zip: '',
        title_f: '', 
        description_f: '',
        module_zip_f: '',
        title_p: '', 
        description_p: '',
        module_zip_p: '',
        assessment: "pre and post",
      }

      const assessmentType: string[] = ['pre-assessment', 'post-assessment', 'pre and post']

      const validationSchema = Yup.object<Yup.AnyObject, {}>().shape({    
        title: Yup.string().required('Error! field title field is required.'),
        description: Yup.string().required('Error! description field is required.'),
    });

   useEffect( () => {
      if(props.type === 'edit') setSelectedModules(props.courseModules!)
   }, [props.courseModules])

    const uploadModuleZip = async ( event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any ) : Promise<void> => {
      setIsUploading(true)
      try{
         const file: File =  event?.target?.files![0]; 
      if (file) {
         const formData: FormData = new FormData();
         formData.append("file", file);
         const response = await createResource.uploadResource(formData)
         toast.success(response?.data?.message)
         setFieldValue(event.target.name, response?.data?.data)
         event.target.value = ''
         setIsUploading(false)
      }  
      } catch(error){
         setIsUploading(false)
         errorResponse(error)
      }
  }

  const supportedLanguages: ('English' | 'French' | 'Portuguese')[] = ['English', 'French', 'Portuguese']

  const tabsData = (setFieldValue: any, values: any) : { label: string, content: React.ReactElement }[] => { 
    return supportedLanguages.map( (language: string) => { 
     return {
     label: language,
     content: (
         <>
              <div className='grid grid-cols-1 mt-3 gap-3'>
                    <h6 className='text-xs mt-1'> 
                      Resources { language.startsWith('E') ? null : `(${language})` }
                    </h6>
                    <label 
                          htmlFor={language.startsWith('E') ? "module_zip" : `module_zip_${
                            language.toLocaleLowerCase().slice(0,1)
                           }`}
                          className='border hover:cursor-pointer rounded-lg flex justify-center items-center flex-col border-slate-200 shadow h-[80px]'>
                            <img src={zipIcon} alt='zip logo' />
                            <input 
                              type="file" accept=".zip" 
                              id={language.startsWith('E') ? "module_zip" : `module_zip_${
                                language.toLocaleLowerCase().slice(0,1)
                               }`}
                              className='hidden'
                              name={language.startsWith('E') ? "module_zip" : `module_zip_${
                                language.toLocaleLowerCase().slice(0,1)
                               }`}
                              onChange={ (e) =>  uploadModuleZip(e, setFieldValue)}
                            />
                        <span className='text-xs mt-2 text-center'>
                          Upload Module Zip File { language.startsWith('E') ? null : `(${language})` }
                        </span>
                        { values[ language.startsWith('E') ? "module_zip" :`module_zip_${
                                language.toLocaleLowerCase().slice(0,1)
                               }`] &&
                          <span className='text-primary-green py-1 bg-green-100 px-3 text-xs'>
                            { language.startsWith('E') ? null : `(${language}) ` } 
                            Module uploaded successfully!
                        </span>
                        }
                    </label>
                      </div>
                            <section>
                            <div className='mt-3'>
                                <label 
                                 htmlFor={language.startsWith('E') ? "title" : `title_${
                                  language.toLocaleLowerCase().slice(0,1)
                                 }`} 
                                 className='text-xs'>
                                  Module Title { language.startsWith('E') ? null : `(${language})` }
                                  <span className='text-lg text-red-500'>*</span>
                                </label>
                                <Field 
                                    id={language.startsWith('E') ? "title" : `title_${
                                      language.toLocaleLowerCase().slice(0,1)
                                     }`} 
                                    className="input-style bg-primaryGray" 
                                    name={language.startsWith('E') ? "title" : `title_${
                                      language.toLocaleLowerCase().slice(0,1)
                                     }`}  
                                    placeholder={`Type module ${ language.startsWith('E') ? '' : `${language.toLocaleLowerCase()}` } title here...`}
                                />
                                <p className='mt-2 text-red-500 text-sm'>
                                    <ErrorMessage name={language.startsWith('E') ? "title" : `title_${
                                       language.toLocaleLowerCase().slice(0,1)
                                       }`}  
                                    />
                                </p>
                            </div>
                            <div className='mt-2'>
                                <label 
                                  htmlFor={language.startsWith('E') ? "description" : `description_${
                                    language.toLocaleLowerCase().slice(0,1)
                                   }`} 
                                  className='text-xs'
                                >
                                  Module Description { language.startsWith('E') ? null : `(${language})` }
                                  <span className='text-lg text-red-500'>*</span>
                                </label>
                                <Field 
                                    id={language.startsWith('E') ? "description" : `description_${
                                      language.toLocaleLowerCase().slice(0,1)
                                     }`} 
                                    className="input-style pt-5 pb-3 bg-primaryGray" 
                                    name={language.startsWith('E') ? "description" : `description_${
                                      language.toLocaleLowerCase().slice(0,1)
                                     }`} 
                                     placeholder={`Type module ${ language.startsWith('E') ? '' : `${language.toLocaleLowerCase()}` } description here...`}
                                    as='textarea'
                               />
                                <p className='mt-2 text-red-500 text-sm'>
                                    <ErrorMessage 
                                       name={language.startsWith('E') ? "description" : `description_${
                                        language.toLocaleLowerCase().slice(0,1)
                                       }`}
                                   />
                                </p>
                            </div>
              </section>
         </>
       )
    }}
  )
}

   const deleteModule = (id: string) : void => {
    setSelectedModules( selectedModules?.filter( module => module?.id !== id ) )
    setDeletedModules([ 
      ...deletedModule, 
      selectedModules?.find( module => module?.id === id )!?.id
    ])
   }

   const assignModulesToCourse = async () => {
    if(selectedModules?.length){
      const payload = {
        id: JSON.parse(sessionStorage.getItem('active-course')!)?.course?.id,
        data: {
         newModules: selectedModules?.map( (module, index) => {
           return {
              points:  1,
              moduleId: module?.id,
              sorting: index + 1
           }
        } ),
        deletedModules: deletedModule,
      }
     }
      try{
        toast.loading('Loading...')
        const response = await courses.assignModuleToCourse(payload)
        toast.remove()
        toast.success(response?.message)
        scrollToTop()
        navigate('/admin/course/')
     } catch(error:any){
        toast.remove()
        toast.error(error?.response?.data?.message)
      }
    }
    else toast.error('Assign modules to course before you proceed')
 } 
    
    const createModule = async (values: initialValuesType, action: any) : Promise<any> => {
        action?.setSubmitting(false)
        if(useMultipleLanguages){
          if(
            !values.title_f || !values.title_p || !values.description_f || !values.description_p
            || !values.module_zip_f || !values.module_zip_p 
          ){
            toast.error(`Error! module name, description and module zip. 
                           cannot be empty for all languages check and validate 
                           before you proceed.`
                        )
            return;
          }
        }
        if(!values.module_zip){
           toast.error('Error! module zip file is required.')
           return
        }

          const defaultModuleLanguage: moduleLanguageType = {
            name: values.title,
            description: values.description,
            filePath: values.module_zip,
            Points: 1,
            language: 'english',
            symbol: 'en',
            isDefault: true,
          }
          const languages: moduleLanguageType[] = useMultipleLanguages ? [
             defaultModuleLanguage,
            {
              name: values.title_f,
              description: values.description_f,
              filePath: values.module_zip_f,
              Points: 1,
              language: 'french',
              symbol: 'fr',
              isDefault: false,
            },
            {
              name: values.title_p,
              description: values.description_p,
              filePath: values.module_zip_p,
              Points: 1,
              language: 'portuguese',
              symbol: 'pt',
              isDefault: true,
            }
          ] : [defaultModuleLanguage]

        const payload = {
          assessmentType: values.assessment,
          filePath: values.module_zip,
          duration: 84,
          sorting: 15,
          isAssessment: isAssessment,
          Points: 22,
          languages,
        }
      try{
          toast.loading('Loading...')
          setModuleLoading(true)
          const response = await courses.createModule(payload)
          toast.remove()
          setModuleLoading(false)
          toast.success(response?.message)
          setSelectedModules([response?.data, ...selectedModules])
          action.resetForm();
          scrollToTop()
      } catch(error:any){
        toast.remove()
        setModuleLoading(false)
        toast.error(error?.response?.data?.message)
      }
    }

    return (
    <div className=''>
        { isUploading && <ResourceLoader /> }
        {
            <SelectModules 
              openModal={openModal}
              setOpenModal={setOpenModal}
              selectedModule={selectedModules}
              setSelectedModules={setSelectedModules}
            />
         }
        <header className='flex  justify-between'>
            <h3 className='text-md font-semibold'>
               Add modules to course
            </h3>
            <button 
              className='btn bg-primary-blue'
              onClick={assignModulesToCourse}
            >
              Save & Continue
            </button>
       </header>
       <section className='flex justify-center mt-3'>
           <div className='w-[80%] border-2 border-dotted border-slate-400 p-5 rounded-xl py-8 pt-4'>
            <h5 className='text-sm flex justify-between items-center font-semibold mb-5'>
                Selected Modules 
                <button 
                    className='btn bi bi-upload bg-primary-blue font-semibold border border-slate-500'
                    onClick={ () => setOpenModal(true) }
                  >
                     &nbsp; Select modules
                  </button>
            </h5>
            {   
              selectedModules.length ?
               <ReorderList  onPositionChange={ ({newItems}: any)  => {
                const updatedModules = newItems?.map( (item:any) => {
                   return item?.props['data-module']
                })
                setSelectedModules(updatedModules)
             }}
             watchChildrenUpdates={true}
             >
                {
                   selectedModules.length ? 
                     selectedModules?.map( (module: any) => {
                      return (
                       <div data-module={module} key={module?.id} className='flex py-5 hover:cursor-pointer justify-between p-3 border border-dotted border-slate-400 mb-5 rounded-lg shadow items-center'>
                            <div className='flex items-center'>
                              <i className='bi bi-arrows-move text-lg mr-5' />
                              <span className='text-sm font-light'>
                                {module?.name}
                            </span>
                            </div>
                           <span 
                             className='bi bi-trash text-red-500 hover:cursor-pointer text-md ml-2'
                            onClick={ () => deleteModule(module?.id)}
                           />
                       </div>                                            
                      )
                   } )
                : null
                }
                </ReorderList>
              :
               <div className='h-full flex items-center justify-center'>
                  <EmptyData text='You have not added any modules yet.' />
                </div>
            }
           </div>
           <div className='bg-white hidden w-[40%] rounded-xl p-5'>
               <h5 className='text-md font-semibold flex items-center justify-between pb-2 border-b border-gray-200'>
                  Add Modules
                  <button 
                    className='btn bi bi-upload bg-primary-blue font-semibold border border-slate-500'
                    onClick={ () => setOpenModal(true) }
                  >
                     &nbsp; Select existing modules
                  </button>
               </h5>
               <div>
               <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={createModule}
                >
                    {({ values, setFieldError, setFieldValue, setFieldTouched }) => (
                        <Form>
                          <div className="mb-3 border-b border-slate-300 pb-2">
                            <label className="block font-semibold mt-3 w-full text-sm mb-1">
                              Would you like to create modules in other languages?
                            </label>
                            <div className='flex items-center'>
                              <input
                                type="checkbox"
                                className="mr-2"
                                checked={useMultipleLanguages}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUseMultipleLanguages(e.target.checked)}
                              />
                              <label className="text-xs">
                                Yes, I want to create module in different languages
                              </label>
                            </div>
                          </div>
                             {useMultipleLanguages ? (
                                <HorizontalTab 
                                  withPadding={false} tabsData={tabsData(setFieldValue, values)} 
                                />
                           ) :  <HorizontalTab  
                                    withPadding={false} tabsData={tabsData(setFieldValue, values).slice(0,1)}
                                  />
                          } 
                         <section className='grid grid-cols-1 gap-5 mt-1'>
                            <div className='flex border-b border-gray-400 pb-2 -mt-2 items-center justify-between pr-3'>
                             <span className='text-sm'>
                                Is assessment ?
                             </span>
                             <Switch
                                height={20}
                                width={40}
                                handleDiameter={18}
                                checked={isAssessment}
                                onChange={ () : void => {
                                  setIsAssessment(!isAssessment)
                                }}
                            />
                         </div>
                         { isAssessment ? 
                            <div className='-mt-4'>
                                <label htmlFor="assessment" className='text-xs'>
                                  Asessment Type <span className='text-lg text-red-500'>*</span>
                                </label>
                                <Field 
                                    id='assessment' className="input-style bg-primaryGray" 
                                    name='assessment' as='select'
                                >
                                  {assessmentType.map( (assessment) : React.ReactElement =>  {
                                     return (
                                       <option value={assessment} key={assessment}>
                                         {assessment}
                                       </option>
                                     )
                                  })}
                                </Field>
                                <p className='mt-2 text-red-500 text-sm'>
                                    <ErrorMessage name='assessment' />
                                </p>
                            </div>
                         : null
                         }
                         <button disabled={isUploading || moduleLoading} type='submit' className='btn w-full text-center bg-primary-blue'>
                            { isUploading ? 'Uploading Module Zip...' : 'Create Module'}
                         </button>
                         </section>
                       </Form>
                    )}
               </Formik>
               </div>
           </div>
       </section>
    </div>
  )
}

export default CourseContent