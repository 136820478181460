import React from 'react'

const SatisfactionRating = () => {

  const scales = [1,2,3,4,5,6,7,8,9,10]

  return (
    <div className='flex mt-2'>
        { scales.map( scale => {
            return (
              <span key={scale} className='bg-gray-200 p-3 px-4 rounded-lg text-xs mr-3'>
                {scale}
             </span>
            )
        })}
    </div>
  )
}

export default SatisfactionRating