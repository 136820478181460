import React, { useEffect } from "react";
import { Ripple, initTE } from "tw-elements";

const Search = ({
  iconColor,
  value,
  placeholder,
  bgColor,
  onClick,
  onChange,
  showButton = true,
  className,
  width,
  mb = 'mb-3',
  ...rest
}) => {

  useEffect(() => {
    initTE({ Ripple }) 
  }, []);

  return (
    <div className={`${mb} ${width}`}>
      {/* <div className={className || 'mb-5'}> */}
      <div className="relative flex w-full flex-wrap items-stretch">
        <input
          type="search"
         placeholder={`🔍 ${placeholder}` || "🔍e.g learner-email@gmail.com or name.."}
          aria-label="Search"
          className={`input-style bg-primaryGray ${className}`}
          aria-describedby="button-addon1"
          onChange={onChange}
          value={value}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Search;
