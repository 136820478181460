import React from 'react'
import { Routes, Route } from "react-router";
import CreateGoal from './pages/CreateGoal';
import CreateActionPlan from './pages/CreateActionPlan';
import EditActionPlan from './pages/EditActionPlan';


const ActionPlanRoutes = () => {
  return (
    <Routes>
      <Route path="/create-action-plan" element={<CreateActionPlan />} />     
      <Route path="/edit-action/:id" element={<EditActionPlan />} />     
      <Route path="/create-goal" element={<CreateGoal />} />     
      </Routes>
  )
}

export default ActionPlanRoutes