import React, { useState } from 'react'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { feedbackApi } from '../../../../../../../api/feedback'
import { errorResponse } from '../../../../../../../components/utils/errorResponse'
import CenteredModal from '../../../../../../../components/Modal/CenteredModal'
import FeedbackSetting from './FeedbackSetting'

const CustomizeFeedbackModal = ({
  open, setOpen, activeTemplate, refetch, isCourse, setIsCourse, 
  afterCourses, setAfterCourses,
  isMandatory, setIsMandatory, afterAssessment, setAfterAssessment,
  journeyId, setJourneyId, courseId, setCourseId,
}) => {

  const addSettingMutation = useMutation(feedbackApi.addFeedbackSettings,{
    onSuccess: res => {
        toast.remove()
        toast.success(res?.message)
        refetch()
        setOpen(false)
     },
     onMutate: () => {
        toast.loading('Loading...')
     },
     onError: error => errorResponse(error)
  })

  const customizeFeedback = () => {
      const payload = {
        data: {
          isForCourse: isCourse, ifForLearningPath: !isCourse, courseId: courseId?.value || null, 
          journeyId: journeyId?.value || null, afterAssessment, afterCourses, isMandatory,
        },
        id: activeTemplate?.id,
      }
      addSettingMutation.mutate(payload)
  }

  return (
    <> 
      <CenteredModal
          title={
            <h5 className='text-md font-semibold bi bi-eye'> 
               &nbsp; Customize Feedback 
           </h5>
          }
        open={open}
        setOpen={setOpen}
        style={{padding: 0}}
        width={'w-[600px] h-auto overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Save & Close'}
        btnBgColor={'bg-secondary'}
        onClose={customizeFeedback}
      > 
        <div className='text-left'>
           <FeedbackSetting
              isCourse={isCourse}
              setIsCourse={setIsCourse}
              afterCourses={afterCourses}
              setAfterCourses={setAfterCourses}
              afterAssessment={afterAssessment}
              setAfterAssessment={setAfterAssessment}
              isMandatory={isMandatory}
              setIsMandatory={setIsMandatory}
              courseId={courseId}
              setCourseId={setCourseId}
              journeyId={journeyId}
              setJourneyId={setJourneyId}
           />
        </div>
      </CenteredModal>
    </>
  )
}

export default CustomizeFeedbackModal