import React from "react";
import StatCard from "./StatCard";
import useLearners from "../../../../../hooks/useLearners";
import total from "../assets/total.png";
import active from "../assets/active.png";
import completion from "../assets/complete.png";
import { learners } from "../../../../../api/learners";
import { useQuery } from "react-query";

const SummaryAnalytics = ({ status }) => {
  const {
    pathSummary,
    cohortAnalyticSummary,
    pathAnalyticSummary,
    courseSummary,
  } = useLearners();

  const cohortHeaders = [
    {
      title: "Number of Active Learners",
      amount: cohortAnalyticSummary?.numberOfLearners?.toLocaleString(),
      imgUrl: total,
      subtitle: cohortAnalyticSummary?.numberOfLearners
        ? `${cohortAnalyticSummary?.numberOfActiveLearners} of ${cohortAnalyticSummary?.numberOfLearners} learners`
        : "...",
    },
    {
      title: "Completion Progress",
      amount: cohortAnalyticSummary?.percentageCompleted
        ? `${Math.floor(
            cohortAnalyticSummary?.percentageCompleted?.toLocaleString()
          )}%`
        : "-",
      imgUrl: total,
      subtitle: cohortAnalyticSummary?.numberOfLearners
        ? `${cohortAnalyticSummary?.numberOfCompletedLearners} of ${cohortAnalyticSummary?.numberOfLearners} completed`
        : "...",
    },
    {
      title: "Average Assessment Score",
      amount: cohortAnalyticSummary?.percentageCompleted
        ? `${Math.floor(
            cohortAnalyticSummary?.percentageCompleted?.toLocaleString()
          )}%`
        : "-",
      imgUrl: total,
      //   subtitle: "150 Learners",
    },
  ];

  const pathHeaders = [
    {
      title: "Number of Active Learners",
      amount: pathAnalyticSummary?.numberOfLearners?.toLocaleString(),
      imgUrl: total,
      subtitle: pathAnalyticSummary?.numberOfLearners
        ? `${pathAnalyticSummary?.numberOfActiveLearners} of ${pathAnalyticSummary?.numberOfLearners} learners`
        : "...",
    },
    {
      title: "Completion Progress",
      amount: pathAnalyticSummary?.percentageCompleted
        ? `${Math.floor(
            pathAnalyticSummary?.percentageCompleted?.toLocaleString()
          )}%`
        : "-",
      imgUrl: total,
      subtitle: pathAnalyticSummary?.numberOfLearners
        ? `${pathAnalyticSummary?.completed} of ${pathAnalyticSummary?.numberOfLearners} completed`
        : "...",
    },
    {
      title: "Average Assessment Score",
      amount: pathAnalyticSummary?.averageAssessmentScore
        ? `${Math.floor(
            pathAnalyticSummary?.averageAssessmentScore?.toLocaleString()
          )}%`
        : "-",
      imgUrl: total,
      //   subtitle: "150 Learners",
    },
  ];

  const courseHeaders = [
    // {
    //   title: "Total Learners",
    //   amount: courseSummary?.numberOfLearners?.toLocaleString(),
    //   imgUrl: total,
    // },

    {
      title: "Completed",
      amount: courseSummary?.completed?.toLocaleString(),
      percentage: courseSummary?.completedPercentage?.toLocaleString(),
      imgUrl: completion,
    },
    {
      title: "Learners in Progress",
      amount: courseSummary?.inProgress?.toLocaleString(),
      percentage: courseSummary?.inProgressPercentage?.toLocaleString(),
      imgUrl: completion,
    },
    {
      title: "In-active Learners",
      amount: courseSummary?.notStarted?.toLocaleString(),
      percentage: courseSummary?.notStartedPercentage?.toLocaleString(),
      imgUrl: active,
    },
  ];

  const renderContent = () => {
    switch (status) {
      case "profile":
        return null;
      case "cohort":
        return cohortHeaders.map((item, index) => (
          <StatCard key={index} data={item} />
        ));
      case "path":
        return pathHeaders.map((item, index) => (
          <StatCard key={index} data={item} />
        ));
      case "course":
        return courseHeaders.map((item, index) => (
          <StatCard key={index} data={item} />
        ));
      default:
        return null;
    }
  };

  return (
    <div className="mb-4 w-full p-2 grid md:grid-cols-3 grid-cols-1 gap-5">
      {renderContent()}
    </div>
  );
};

export default SummaryAnalytics;
