import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Summary from './pages/summary/Summary'
import PricingAndDiscount from './pages/pricing-and-discount/PricingAndDiscount'
import InvoiceRoutes from './pages/invoice/InvoiceRoutes'
import Transactions from './pages/transactions/Transactions'
import Discount from './pages/discounts/Discount'
import CourseCreditCode from './pages/course-credit-code/CourseCreditCode'

const BillingRoutes = () => {
  return (
    <section> 
      <Routes>
          <Route path='/' element={ <Navigate to={'/admin/billing/summary'} /> } />
          <Route path='/summary' element={ <Summary /> } />
          <Route path='/pricing' element={ <PricingAndDiscount /> } />
          <Route path='/invoice/*' element={ <InvoiceRoutes /> } />
          <Route path='/transactions' element={ <Transactions /> } />
          <Route path='/discount' element={ <Discount /> } />
          <Route path='/course-credit-code' element={ <CourseCreditCode /> } />
      </Routes>
    </section>
  )
}

export default BillingRoutes