import createApiClient from "../api/api";
import { getPathType } from "./types/learningPath.type";

const client = createApiClient()

export const learningPath = {
    getLearningPath: ({ org_id, search, perPage, currentPage } : getPathType) =>
        client
          .get(
            `admin/org/${org_id}/journies?perPage=${perPage}${
              search ? `&search=${search}` : ""
            }&page=${currentPage}`
          )
    .then(({ data }) => data), 

    assignCohorts: ({ org_id, journey_id, payload }: any) =>
        client
          .post(
            `/admin/org/${org_id}/journies/${journey_id}/assign-cohorts`,
            payload
          )
          .then(({ data }) => data),
    
    getCohortUsers: ({ id, search, perPage, currentPage, cohortId } : any) =>
        client
          .get(
            `admin/org/${id}/cohorts/${cohortId}/learners?perPage=${perPage}${
              search ? `&search=${search}` : ""
            }&page=${currentPage}`
          )
    .then(({ data }) => data),

    getCohort: ({ id, search, perPage, currentPage }: any) =>
      client
        .get(
          `admin/org/${id}/cohorts?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`
        )
        .then(({ data }) => data),
  
    createLearningPath: ({ org_id, payload }: any) =>
      client
        .post(`admin/org/${org_id}/journies`, payload)
        .then(({ data }) => data),
  
    editLearningPath: ({ org_id, payload, id } : any) =>
      client
        .patch(`admin/org/${org_id}/journies/${id}`, payload)
        .then(({ data }) => data),  
    
    getLearningPathSummary: (id: string) => client.get(`/admin/org/${id}/journey-summary`)
}