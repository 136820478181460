import React, { useState } from 'react'
import CustomDropdown from '../../../../../../../../../../../../components/dropdown/CustomDropdown'
import { api } from '../../../../../../../../../../../../api/courses';
import { api as learningPathApi } from '../../../../../../../../../../../../api/learningPath';
import { useQuery } from 'react-query';
import CenteredModal from '../../../../../../../../../../../../components/Modal/CenteredModal';
import Button from '../../../../../../../../../../../../components/Buttons/Button';

const FilterModal = ({ 
      openModal, setOpenModal, cohortId, setCohortId,
      learningPathId, setLearningPathId,
      courseId, setCourseId, orgId
 }) => {

    const [cohort, setCohort] = useState(null)
    const [course, setCourse] = useState(null)
    const [learningPath, setLearningPath] = useState(null)

    const {
        data: courses,
      } = useQuery(
        ["get courses"],
        () => {
          return api.getCourses({perPage: 10000, currentPage: 1 });
        },
        {
          cacheTime: 100,
          staleTime: 100,
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          select: (data) => data?.data,
        }
      );

      const {
        data: learningPaths,
      } = useQuery(
        ["get learning path"],
        () => {
          return learningPathApi.getLearningPath({
               org_id: orgId, search: '', perPage: 10000, currentPage: 1 
          });
        },
        {
          cacheTime: 100,
          staleTime: 100,
          refetchOnWindowFocus: false,
          refetchOnMount: false,         
          select: (data) => data?.data,
        }
      );

    const { data: cohorts, isLoading, refetch } = useQuery(
    ["cohort", { id: orgId, search: '', perPage: 10, currentPage: 1 }],
    ({ queryKey }) => {
        return learningPathApi.getCohort(queryKey[1]);
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        select: (data) => data?.data,
    }
    );

    const clearFilter = () => {
        setCohortId('')
        setCourseId('')
        setLearningPathId('')
        setCourse(null)
        setLearningPath(null)
        setCohort(null)
        setOpenModal(false)
    } 

  return (
    <CenteredModal 
            title={
            <h5 className='text-md font-semibold bi bi-plus-circle'>
                &nbsp; Select And Apply Filter 
            </h5>
            }
            open={openModal}
            setOpen={setOpenModal}
            style={{padding: 0}}
            closeButtonLabel={'Clear Filter'}
            width={'w-[350px] overflow-y-auto'}
            headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
            //btnBgColor={'bg-primary'}
       > 
         <div className='text-left p-3'>
            <h3 className='mb-2 text-xs'>
                Filter By Course, Cohort or Learning Path
            </h3>
           <div>
                <span className='text-xs mb-1'>Course</span>
                <CustomDropdown
                   value={course}
                    width={'w-full'}
                    options={
                    courses?.length ?  courses?.map( course => {
                            return {label: course.name, value: course?.id  }
                    })
                    : []
                    }
                    onChange={ value => {
                        setCourseId(value?.value)
                        setCourse(value)
                    } }
                    placeholder={'Filter By Course'}
                />
           </div>
           <div className='mt-3'>
                <span className='text-xs mb-1'>Cohorts</span>
                <CustomDropdown
                    value={cohort}
                    width={'w-full'}
                    options={
                    cohorts?.length ?  cohorts?.map( cohort => {
                            return {label: cohort?.cohort?.name, value: cohort?.cohort?.id  }
                    })
                    : []
                    }
                    onChange={ value =>  {
                        setCohortId(value?.value)
                        setCohort(value)
                    } }
                    placeholder={'Filter By Cohort'}
                />
           </div>
           <div className='mt-3'>
                <span className='text-xs mb-1'>Learning Path</span>
                <CustomDropdown
                   value={learningPath}
                    width={'w-full'}
                    options={
                    learningPaths?.length ?  learningPaths?.map( path => {
                            return {label: path.name, value: path?.id  }
                    })
                    : []
                    }
                    onChange={ value => {
                        setLearningPathId(value?.value)
                        setLearningPath(value)
                    } }
                    placeholder={'Filter By Learning Path'}
                />
           </div>
           <div className='grid ml-3 grid-cols-2 mt-4 gap-0'>
              <Button 
                 text={'Clear Filter'} bgColor={'bg-red-500'}
                 onClick={clearFilter}
              />
              <Button 
                text={'Ok'}  bgColor={'bg-customBlue'}
                onClick={ () => setOpenModal(false) }
               />
           </div>
         </div>
      </CenteredModal>
  )
}

export default FilterModal