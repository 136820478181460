import React from "react";
import { Routes, Route } from "react-router";
import Overview from "./pages/overview/Overview";
import Learners from "./pages/learners/Learners";
import Courses from "./pages/courses/Courses";
import LearningPath from "./pages/learning-path/LearningPath";
import Programs from "./pages/programs/Programs";
import Events from "./pages/events/Events";
import LearnerDetails from "./pages/overview/LearnerDetails";

const ReportAndAnalysisRoutes = () => {
  return (
    <Routes>
      <Route path="/overview" element={<Overview />} />
      <Route path="/learners" element={<Learners />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/learning-path" element={<LearningPath />} />
      <Route path="/programs" element={<Programs />} />
      <Route path="/events" element={<Events />} />
      <Route path="/overview/learner/:learnerId" element={<LearnerDetails />} />
    </Routes>
  );
};

export default ReportAndAnalysisRoutes;
