import React from 'react'
import { useQuery } from 'react-query';

import BadgeIcon from '../../../../../../assets/Badge.png'
import BadgeIconOrange from '../../../../../../assets/Bagde (5).png'
import BadgeIconGreen from '../../../../../../assets/Badge (1).png'
import { formatCurrency } from '../../../billing/site-admin/pages/summary/Summary';
import OverviewCard from '../../../report-and-analysis/components/OverviewCard';
import { api } from '../../../../../../api/action-plan';
// import { orgId } from '../../../../../../utils/org';

const Summary = () => {
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
const { data: dashboardSummary, isLoading } = useQuery(
    ["action-plan-summary"],
    () => { 
        return api.getActionPlanSummary({orgId});
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => data?.data,
  });


 const summary = (summary) => isLoading ? 0 : summary
 
 return (
    <>
        <header className='grid pt-3 grid-cols-1 md:grid-cols-4 gap-5'>
           <OverviewCard 
             title={'Action Plan'}
             total={formatCurrency(summary(dashboardSummary?.actionPlanCount), false)}
             iconPath={BadgeIconGreen}
             py={'py-5'}
             text={
              <p className='flex justify-between text-xs'>
                 <span>+{summary(dashboardSummary?.todayChange
)} today </span>
                 <span className='text-green-500'>
                   +{summary(dashboardSummary?.actionPlanPercentageChange)} today 
                </span>
              </p>
             }
             headerTextSize='text-2xl'
           />
           <OverviewCard
             title={'Submit - Completed'}  
             total={formatCurrency(summary(dashboardSummary?.actionPlanCompleted               ), false)}
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-5'
             text={
              <p className='flex justify-between text-xs'>
                 <span>+{summary(dashboardSummary?.todayCompletedCount)} today </span>
                 <span className='text-green-500'>
                   +{summary(dashboardSummary?.
completedPercentage
)} today 
                </span>
              </p>
             }
           />
           <OverviewCard
             title={'Submit - In Progress'}
             total={formatCurrency(summary(dashboardSummary?.submitInProgressCount

              
                ),false)}
             iconPath={BadgeIcon}
             text={
                <p className='flex justify-between text-xs'>
                   <span>+{summary(dashboardSummary?.todayInProgressCount)} today </span>
                   <span className='text-green-500'>
                     +{summary(dashboardSummary?.inProgressPercentage)} today 
                  </span>
                </p>
             }
             headerTextSize='text-2xl'
             py='py-5'
           />
           <OverviewCard
             title={'Submit - Not Started'}  
             total={formatCurrency(summary(dashboardSummary?.notStartedCount
             ),false)}
             iconPath={BadgeIconOrange}
             text={
                <p className='flex justify-between text-xs'>
                   <span>+{formatCurrency(summary(dashboardSummary?.todayNotStartedCount))} today </span>
                   <span className='text-green-500'>
                     +{summary(dashboardSummary?.notStartedPercentage
)} today 
                  </span>
                </p>
             }
             headerTextSize='text-2xl'
             py='py-5'
           />
        </header>
    </>
  )
}

export default Summary