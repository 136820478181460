import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { learners } from "../../../../../api/learners";
import toast from "react-hot-toast";
import { LearnersContext } from "../../../../../context/learnersContext";

const ResetPassword = () => {
  const { register, handleSubmit } = useForm();

  const { mutateAsync } = useMutation(learners.ResetPassword);

  const { setOpenResetModal } = useContext(LearnersContext);

  const currentLearner = JSON.parse(sessionStorage.getItem("currentLearner"));

  const onSubmit = async (values) => {
    toast.loading("Resetting password...");
    // console.log(payload);
    const payload = {
      ...values,
      email: currentLearner?.user?.email,
      isAdmin: true,
    };
    try {
      const data = await mutateAsync(payload);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        setOpenResetModal(false);
      }, 500);
    } catch (error) {
      toast.remove();
      toast.error(error?.data?.message);
    }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full mx-auto p-4 bg-white rounded-md"
      >
        <div className="mb-4">
          <p className="text-gray-700 w-full text-sm font-medium text-left mb-2">
            OTP
          </p>
          <input
            id="otp"
            type="text"
            {...register("otp")}
            className="appearance-none text-xs border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter OTP"
          />
        </div>

        <div className="mb-6">
          <p className="text-gray-700 text-sm font-medium text-left mb-2">
            Password
          </p>
          <input
            id="password"
            type="password"
            {...register("password")}
            className="appearance-none text-xs border rounded w-full p-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Password"
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-darkBlue text-white font-medium text-sm py-2 px-4 rounded w-full"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
