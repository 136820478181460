import React from "react";
import { Routes, Route } from "react-router";
import Certificates from "./pages/Certificates";
import CreateNewCertificate from "./pages/CreateNewCertificate";
import EditCertificate from "./pages/EditCertificate";
import EditDefaultTemplate from "./pages/EditDefaultTemplate";

const CertificateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Certificates />} />
      <Route path="/create-certificate" element={<CreateNewCertificate />} />
      <Route
        path="/update-certificate/:certificateId"
        element={<EditCertificate />}
      />
      <Route path="/update-template/:id" element={<EditDefaultTemplate />} />
    </Routes>
  );
};

export default CertificateRoutes;
