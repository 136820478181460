import createApiClient from "./api";

const client = createApiClient();

const user = JSON.parse(sessionStorage.getItem("user"));

export const api = {
  createLearningPath: ({ org_id, payload }) =>
    client
      .post(`admin/org/${org_id}/journies`, payload)
      .then(({ data }) => data),

  editLearningPath: ({ org_id, payload, id }) =>
    client
      .patch(`admin/org/${org_id}/journies/${id}`, payload)
      .then(({ data }) => data),

  getLearningPath: ({ org_id, search, perPage, currentPage }) =>
    client
      .get(
        `admin/org/${org_id}/journies?perPage=${perPage}${
          search ? `&search=${search}` : ""
        }&page=${currentPage}`
      )
      .then(({ data }) => data),  

    getIndividualLearningPath: ({ org_id, id }) =>
      client
        .get(
          `/admin/org/${org_id}/journies/${id}`
        )
    .then(({ data }) => data),

  getCourses: ({ search }) =>
    client.get(`admin/courses?search=${search}`).then(({ data }) => data),

  getCohorts: ({ org_id, search }) =>
    client
      .get(`admin/org/${org_id}/cohorts?search=${search}`)
      .then(({ data }) => data),

  getSinglePath: ({ org_id, journey_id }) =>
    client
      .get(`admin/org/${org_id}/journies/${journey_id}`)
      .then(({ data }) => data),

  addCourses: ({ org_id, journey_id, payload }) =>
    client
      .post(
        `/admin/org/${org_id}/journies/${journey_id}/assign-courses`,
        payload
      )
      .then(({ data }) => data),

    addPaths: ({ org_id, journey_id, payload }) =>
      client
        .post(
          `/admin/org/${org_id}/journies/${journey_id}/learning-paths`,
          payload
        )
        .then(({ data }) => data),

  assignPrerequisites: ({ org_id, journey_id, payload }) =>
    client
      .post(
        `/admin/org/${org_id}/journies/${journey_id}/assign-prerequisites`,
        payload
      )
      .then(({ data }) => data),

  getCohort: ({ id, search, perPage, currentPage }) =>
    client
      .get(
        `admin/org/${id}/cohorts?perPage=${perPage}${
          search ? `&search=${search}` : ""
        }&page=${currentPage}`
      )
      .then(({ data }) => data),

    getCohortUsers: ({ id, search, perPage, currentPage, cohortId }) =>
        client
          .get(
            `admin/org/${id}/cohorts/${cohortId}/learners?perPage=${perPage}${
              search ? `&search=${search}` : ""
            }&page=${currentPage}`
          )
    .then(({ data }) => data),
    
  createCohort: (payload) =>
    client
      .post(`admin/org/${payload?.id}/cohorts`, payload?.data)
      .then(({ data }) => data),

  updateCohort: (payload) =>
      client
        .patch(`admin/org/${payload?.id}/cohorts/${payload?.cohortId}`, payload?.data)
        .then(({ data }) => data),

  addLearnersToCohort: (payload) =>
    client
      .post(
        `admin/org/${payload?.id}/cohorts/${payload?.cohortId}/assign-participants`,
        payload?.data
      )
      .then(({ data }) => data),

  assignCohorts: ({ org_id, journey_id, payload }) =>
    client
      .post(
        `/admin/org/${org_id}/journies/${journey_id}/assign-cohorts`,
        payload
      )
      .then(({ data }) => data),

   addMultiLanguageName: (payload) =>
        client
          .patch(`admin/org/${payload?.id}/cohorts/${payload?.cohortId}/name`, payload?.data)
          .then(({ data }) => data),

    getLearningPathSummary: (id) => client.get(`/admin/org/${id}/journey-summary`)
};
