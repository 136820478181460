import React from 'react'
import ReactStarsRating from 'react-awesome-stars-rating';

const StarRating = () => {
  return (
    <div className='flex w-[500px] mt-2 ml-2'>
       <ReactStarsRating starGap={5} value={4.8} />
   </div>
  )
}

export default StarRating