import { Link, Outlet, useLocation } from "react-router-dom";

const SettingsLayout = () => {
  const location = useLocation();

  return (
    <div className="flex h-screen bg-gray-100 gap-4">
      {/* Settings Sidebar */}
      <div className=" bg-white w-fit h-screen p-4 shadow-lg">
        <ul className="space-y-4">
          <li>
            <Link
              to="/admin/settings/admins"
              className={`block px-4 py-2 ${
                location.pathname === "/admin/settings/admins"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
              Admins
            </Link>
          </li>
          <li>
            <Link
              to="/admin/settings/role-and-privileges"
              className={`block px-4 py-2 ${
                location.pathname === "/admin/settings/role-and-privileges"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
              Roles and Privileges
            </Link>
          </li>
          <li>
            <Link
              to="/admin/settings/zoom"
              className={`block px-4 py-2 ${
                location.pathname === "/admin/settings/zoom"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
             Zoom
            </Link>
          </li>
          <li>
            <Link
              to="/admin/settings/email"
              className={`block px-4 py-2 ${
                location.pathname === "/admin/settings/email"
                  ? "bg-gray-100 border-r-4 border-orange-500"
                  : "hover:bg-gray-50"
              }`}
            >
              Email
            </Link>
          </li>
        </ul>
      </div>

      {/* Dynamic Content Area */}
      <div className="content-area flex-1 p-6 bg-transparent h-screen">
        {/* React Router's Outlet component will render the matching content here */}
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
