import React from "react";
import BackgroundImage from "../../../../../../../assets/Background.png";
import ProfileImage from "../../../../../../../assets/profile.png";

const LearnerProfile = () => {
  return (
    <div className="w-80 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
      <img
        alt="profile-background"
        src={BackgroundImage}
        className="w-full mb-4 rounded-t-lg h-28"
      />
      <div className="flex flex-col items-center justify-center p-4 -mt-16">
        <img
          alt="profile-pic"
          src={ProfileImage}
          className="mx-auto object-cover rounded-full h-16 w-16 "
        />
      </div>
      <div className="flex gap-2 items-center p-2">
        <div className="rounded-full h-8 w-8 bg-[#F0F1F3]"></div>
        <div className="flex flex-col">
          <p className="font-semibold text-sm text-[#1D1F2C]">User ID</p>
          <p className="font-normal text-xs text-[#667085]">ID-011221</p>
        </div>
      </div>
      <div className="flex gap-2 items-center p-2">
        <div className="rounded-full h-8 w-8 bg-[#F0F1F3]"></div>
        <div className="flex flex-col">
          <p className="font-semibold text-sm text-[#1D1F2C]">E-mail</p>
          <p className="font-normal text-xs text-[#667085]">Ajoke@mail.com</p>
        </div>
      </div>
      <div className="flex gap-2 items-center p-2">
        <div className="rounded-full h-8 w-8 bg-[#F0F1F3]"></div>
        <div className="flex flex-col">
          <p className="font-semibold text-sm text-[#1D1F2C]">Phone Number</p>
          <p className="font-normal text-xs text-[#667085]">080 414 8778</p>
        </div>
      </div>
      <div className="flex gap-2 items-center p-2">
        <div className="rounded-full h-8 w-8 bg-[#F0F1F3]"></div>
        <div className="flex flex-col">
          <p className="font-semibold text-sm text-[#1D1F2C]">Join Date</p>
          <p className="font-normal text-xs text-[#667085]">1 Day Ago</p>
        </div>
      </div>
      <div className="flex gap-2 items-center p-2">
        <div className="rounded-full h-8 w-8 bg-[#F0F1F3]"></div>
        <div className="flex flex-col">
          <p className="font-semibold text-sm text-[#1D1F2C]">Last Online</p>
          <p className="font-normal text-xs text-[#667085]">1 Day Ago</p>
        </div>
      </div>

      <div className="flex items-center w-full text-white justify-center   my-6 px-8">
        <button
          type="button"
          className="py-2   w-full bg-[#091F46]   rounded-lg "
        >
          Email
        </button>
      </div>

      <p className="flex items-center justify-center  text-base text-gray-400 mb-4">
        <svg
          width="10"
          height="10"
          fill="currentColor"
          className="w-4 h-4 mr-2"
          viewBox="0 0 1792 1792"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
        </svg>
        Edit
      </p>

     
    </div>
  );
};

export default LearnerProfile;
