import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from '../../../../../../../../../../components/Buttons/Button';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { api } from '../../../../../../../../../../api/billing';

const CreditRefund = ({ userId, orgId }) => {
    
  const refundCreditMutation = useMutation(api.refundCredit, {
    onSuccess: (response) => {
      toast.remove();
      toast.success(response?.message);

    },
    onMutate: () => {
      toast.loading("Loading...");
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
    },
  });

  const initialValues = {
    coursesEnrolled: "",
    coursesNotStarted: "",
    refundableCredit: "",
  };

  const validate = (values) => {
    const errors = {}
    if(!values?.refundableCredit){
      errors.refundableCredit = 'Error! credit to be refunded is required.'
    }
    return errors;
  };

  const refundCredit = (values, actions) => {
    // Handle submission
    const payload = {
      createdBy: userId, // userId
      credit: values?.refundableCredit,
      orgId,
      numberOfCoursesEnrolled: null, // not required for now
      numberOfCoursesNotStarted: null // not required for now
  }

   refundCreditMutation.mutate(payload)
    actions.setSubmitting(false);
  };

  return (
    <div className='bg-white w-[500px] mx-auto mt-2 rounded-lg p-5'>
        <Formik
          initialValues={initialValues}
          validate={validate}
         // enableReinitialize={true}
          onSubmit={refundCredit}
        >
          {({ values, setFieldError, setFieldValue }) => (
            <Form>
                <h3 className='text-md font-medium'>
                    Credit Refund
                </h3>
                <p className='text-xs text-gray-700 mt-1 pb-2 border-b border-gray-300'>
                  Credit Management and Refunds
                </p>
               <div className='mt-2'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Course Enrolled
                  </label>
                  <Field type='number' name='coursesEnrolled' className='input-md' />
               </div>
               <div className='mt-3'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Courses Not Started 
                  </label>
                  <Field type='number' name='coursesNotStarted' className='input-md' />
               </div>
               <div className='mt-2 mb-5'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                    Refundable Credit
                  </label>
                  <Field type='number' name='refundableCredit' className='input-md' />
                   <p className='mt-2 text-xs text-red-500' >
                      <ErrorMessage name='refundableCredit' />
                   </p>
               </div>
               <Button 
                  bgColor={'bg-darkBlue'} width={'w-[60%] mx-auto'}
                  text={'Process Refund'}
                  btn_type='submit'
                />
            </Form>
         )}
      </Formik>
    </div>
  )
}

export default CreditRefund