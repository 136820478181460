import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from '../../../../../../../../components/Buttons/Button';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal';
import CustomDropdown from '../../../../../../../../components/dropdown/CustomDropdown';
import { api } from '../../../../../../../../api/clients';
import { api as billingApi } from '../../../../../../../../api/billing'; 

const AddClientToDiscount = ({ openModal, setOpenModal, activeTier, refetch }) => {
    
  const addClientToTierMutation = useMutation(billingApi.addClientToDiscount, {
      onSuccess: (response) => {
        toast.remove();
        toast.success(response?.message);
        refetch()
        setOpenModal(false)
      },
      onMutate: () => {
        toast.loading("Loading...");
      },
      onError: ({ response }) => {
        toast.remove();
        toast.error(response.data?.message);
      },
    });

    const { data: clients, isLoading, } = useQuery(
        ["clients", { perPage: 10000 , currentPage: 1 }],
        ({ queryKey }) => {
          return api.getClients(queryKey[1]);
        },
        {
          cacheTime: 100,
          staleTime: 100,
          select: (data) => data?.data,
          refetchOnWindowFocus: false,
          refetchOnMount: false
        }
      );

  const [client, setClient ] = useState(null) 

  const initialValues = {
    tier: activeTier?.name,
    percentOff: activeTier?.percentOff,
    clientId: "",
  };

  const validate = (values) => {
   const errors = {}
   if(!values?.tier){
     errors.tier = 'Error! tier field is required.'
   }
   else if(!values?.clientId){
      errors.clientId = 'Error! client field is required.'
    }
   return errors;
 };

 const addClientToTier = (values, actions) => {
   addClientToTierMutation.mutate({
    discountId: activeTier?.id,
    orgId: values?.clientId,
   })
   actions.setSubmitting(false);
 };

  return (
        <CenteredModal 
            title={
            <h5 className='text-md font-semibold bi bi-plus-circle'>
                &nbsp; Add Client to Tier
            </h5>
            }
            open={openModal}
            setOpen={setOpenModal}
            style={{padding: 0}}
            width={'w-[450px] overflow-y-auto'}
            headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
            //btnBgColor={'bg-primary'}
       > 
         <div className='text-left p-3'>
         <Formik
          initialValues={initialValues}
          validate={validate}
          enableReinitialize={true}
          onSubmit={addClientToTier}
        >
          { ({setFieldValue}) => (
            <Form>
                <p className='text-xs text-gray-700 pb-2 border-b border-gray-300'>
                  Add Volume Discount Tier  {console.log(activeTier)}
                </p>
                <section className='grid grid-cols-2 gap-5'>
                    <div className='mt-2'>
                        <label htmlFor="course-enrolled" className='text-xs'>
                            Tier
                        </label>
                        <Field readonly disabled name='tier' className='input-md' />
                        <p className='text-xs text-red-500 mt-2'>
                            <ErrorMessage name='tier' />
                        </p>
                    </div>
                    <div className='mt-3'>
                        <label htmlFor="course-enrolled" className='text-xs'>
                            Percent Off
                        </label>
                        <Field readonly disabled name='percentOff' className='input-md' />
                        <p className='text-xs text-red-500 mt-2'>
                            <ErrorMessage name='percentOff' />
                        </p>
                    </div>
                </section>
                
               <div className=' mb-5'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Select Client
                  </label>
                  <CustomDropdown
                    value={client}
                    width={'w-full'}
                    options={
                    clients?.length ?  clients?.map( client => {
                            return {label: client.name, value: client?.id  }
                    })
                    : []
                    }
                    onChange={
                    value => {
                        setClient(value)
                        setFieldValue('clientId', value.value)
                    }
                    }
                    placeholder={'Select Client'}
                 />
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage  name='clientId' />
                  </p>
               </div>
               <Button 
                  bgColor={'bg-darkBlue'} width={'w-[60%] mx-auto'}
                  text={'Add Client'}
                  btn_type='submit'
                />
            </Form>
         )}
        </Formik>
         </div>
      </CenteredModal>
  )
}

export default AddClientToDiscount