import React, { useState } from 'react'
import warningImage from '../../../../../assets/Subtract.png' 
import CreateDemoGraphics from './CreateDemoGraphics'
import { useQuery } from 'react-query'
import { api as auth } from '../../../../../api'
import Loader from '../../../../../components/loader/Loader'
import ActionModal from '../../../../../components/popUp/ActionModal'
import toast from 'react-hot-toast'

export type demographyType = {
   id: string,
   orgId: string,
   createdAt: string | null,
   updatedAt: string | null,
   deletedAt: null,
   fieldName: string;
   fieldType: string;
   isMandatory: boolean;
   fieldCategory: string;
   fieldValidation?: string;
} 

type AccountDemoGraphicsType = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}

const AccountDemoGraphics: React.FC<AccountDemoGraphicsType> = ({setCurrentStep}) => {


  const orgId: string = sessionStorage.getItem('org-id')!

  const { data: demographics, isLoading, refetch } = useQuery(
    'get demographics' , async (): Promise<any> => {
     return await auth.getDemoGraphics(orgId)
  }, { select: response => response?.data})
  
  const [ openModal, setOpenModal ] = useState<boolean>(false)
  const [ activeDemography, setActiveDemography] = useState<demographyType | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

 const createDemographic = () : void => {
     setActiveDemography(null)
    setOpenModal(true)
 }

 const deleteDemoGraphy = async() : Promise<void> => {
    try{
      toast.loading('Loading...')
      const response = await auth.deleteDemoGraphics({ 
         id: activeDemography?.id!, orgId 
      })
      toast.remove()
      toast.success(response?.message)
      setActiveDemography(null)
      refetch()
      setShowDeleteModal(false)
    }catch(error: any){
      toast.remove()
      toast.error(error?.response?.data?.message)
    }
 }
 
const saveChanges = () => {
   sessionStorage.setItem('account-demographics', JSON.stringify(demographics))
   setCurrentStep(5)
}

  return (
    <div>
        <h5 className='text-lg font-semibold'>
          Account Demographics
        </h5>
        <p className='text-sm font-light mt-1'>
         Input the necessary information to complete your account set up
        </p>
        <section className='bg-primaryOrange py-5 px-5 mt-3'> 
          <h2 className='text-sm font-semibold flex items-center'>
             <img src={warningImage} alt="warning" className='h-[20px] w-[20px] mr-2'/>
             Be Mindful
          </h2>
          <p className='text-xs mt-2'>
             Renaming or deleting demographics will apply to all survey that 
             rely upon them. Deletion of a demographic 
            will result in it no longer being available in future surveys, reporting and insights.
          </p>
        </section>
         <section className='flex justify-end mt-8'>
            <button className='btn-primary' onClick={createDemographic} >
               <span className='bi bi-plus-circle' />
              &nbsp; Add New Demographic
            </button>
         </section>
         <CreateDemoGraphics
            openModal={openModal} activeDemography={activeDemography}
            setOpenModal={setOpenModal} setActiveDemography={setActiveDemography}
            refetch={refetch}
         />
         <ActionModal 
           actionText='Yes, Proceed'
           cancelText='Cancel'
           text="Please note that by performing such action, you can't undo"
           title='Did you want to delete this demography'
           onActionClick={deleteDemoGraphy}
           onCancelClick={ () => {
              setShowDeleteModal(false)
           }}
           openModal={showDeleteModal}
           type='warning'
           setOpenModal={setShowDeleteModal}
         />
         {
            isLoading ? <Loader /> :
            <section className='mt-8 px-5'>
               {
                demographics?.length ?
                 demographics?.map( (demography: demographyType)  => {
                   return ( 
                     <div key={demography?.id} className='flex border-b border-slate-200 pb-2 mb-4 justify-between'>
                         <div className='flex'>
                             <span className='text-sm mr-8'>
                               {demography?.fieldName}
                            </span>
                            <span className='text-xs'>
                               ({demography?.fieldCategory})
                            </span>
                         </div>
                        <div className='flex'>
                           <span className='text-xs bg-slate-100 rounded-full w-[80px] text-center mr-5 pt-1'>
                             {demography?.isMandatory ? 'mandatory' : 'optional' }
                           </span>
                           <span className='text-xs w-[110px]'>{demography?.fieldType}</span>
                           <div className='ml-8'>
                              <i 
                                 className='bi bi-pencil-square hover:cursor-pointer text-md text-blue-500'
                                 onClick={ () : void => {
                                    setActiveDemography(demography)
                                    setOpenModal(true)
                                 }}
                               />
                              <i 
                                 className='bi bi-trash hover:cursor-pointer ml-3 text-md text-red-500'
                                 onClick={ () : void => {
                                    setShowDeleteModal(true)
                                    setActiveDemography(demography)
                                 } }
                             />
                           </div>
                        </div>
                     </div>
                   )
                 } )
                : null
               }
           </section>
         }
         <section className='mt-8 flex justify-between'>
               <button onClick={saveChanges} className='text-secondary bg-slate-300 text-sm py-2 px-8 rounded'>
                     Skip & Proceed
               </button>
               <button onClick={saveChanges} className='bg-secondary text-white text-sm py-2 px-8 rounded'>
                     Save Changes
               </button>
         </section>
    </div>
  )
}

export default AccountDemoGraphics