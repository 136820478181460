import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const LineChart = ({ data, options }) => {
  return (
    <div className="">
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
