import React from 'react'
import DataCard from './DataCard'
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const AverageAttemptPerCourseChart = ({chartData}) => {
  return (
    <div className='w-full mt-3 overflow-x-auto pb-5'>
      <p className='text-sm font-light mb-3'> 
         This metrics tracks learners completion per course
      </p>
        <section className='flex'>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2'> Cohort</h5>
                <ul>
                   {chartData?.map( data => {
                     return <li className='whitespace-nowrap border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.cohortName}
                      </li>
                   } )}
                </ul>
            </div>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2 whitespace-nowrap'> Learning Path </h5>
                <ul>
                   {chartData?.map( (data, index) => {
                     return (<li key={index} className='whitespace-nowrap border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.journeyName}
                      </li>)
                   } )}
                </ul>
            </div>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2 whitespace-nowrap'> Learners </h5>
                <ul>
                   {chartData?.map( (data, index) => {
                     return (<li key={index} className='whitespace-nowrap border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.numberOfLearners}
                      </li>)
                   } )}
                </ul>
            </div>
            <div>
               <header className='flex pt-3 items-center border-t border-gray-200'>
                  {
                    chartData?.length ?
                    chartData[0]?.data?.map( data => {
                     return <>
                         <span 
                            className='text-[11px] hover:cursor-pointer border-r px-1 border-gray-400 overflow-hidden whitespace-nowrap text-ellipsis font-medium text-center mr-1 w-[100px]'
                            data-tooltip-id={data?.courseName}
                            data-tooltip-content={data?.courseName}
                         >
                           {data?.courseName}
                       </span>
                     <ReactTooltip id={data?.courseName} place='bottom' content={data?.courseName} />
                     </>
                  } )
                : null}
               </header>
               <section className='mt-3'>
                  {chartData?.map( data => {
                     return <div className='flex items-center justify-between'>
                           <div className='flex w-[calc(100%-170px)]'>
                              { data?.data?.length ?
                                data?.data?.map( data => {
                                 return <DataCard
                                             withBorder width='w-[118px]'
                                             value={data?.courseCompletion}
                                             key={data?.courseCompletion}
                                        />
                              }) : <p className='text-xs px-2 py-3 bg-gray-200'> There is no any completion for this cohort </p>
                           }
                           </div>
                     </div>
                  })}
               </section>
            </div>
        </section>
    </div>
  )
}

export default AverageAttemptPerCourseChart