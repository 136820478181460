import React, { useRef, useState } from "react";
import CustomEmailEditor from "../../../../../components/Editor/EmailEditor";
import toast from "react-hot-toast";
import { api } from "../../../../../api/email";
import MultiSelectInput from "../../../../../components/Inputs/MultiSelectInput";
import { useNavigate } from "react-router";
import EmailEditor from "react-email-editor";

const CreateEmailTemplate = () => {
  const navigate = useNavigate();
  const emailEditorRef = useRef(null);

  const user = JSON.parse(window.sessionStorage.getItem("user"));
  const [templateName, setTemplateName] = useState(""); // For the template name
  const [subject, setSubject] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);

  const handleValuesChange = (values) => {
    setSelectedValues(values);
  };

  // const handleExportHtml = async (html, design) => {
  //   toast.loading("Loading...");
  //   // Custom logic for exporting the template HTML
  //   console.log("Received HTML:", design);
  //   let payload = {
  //     name: templateName,
  //     subject: subject,
  //     templateBody: html, // The body content of the email
  //     jsonBody : JSON.stringify(design),  // The Unlayer design JSON
  //     createdBy: user?.userId,
  //   };
  //   console.log(payload, "pay")
  //   try {
  //     // Send the exported HTML to the backend API
  //     const response = await api.createEmailTemplate({
  //       orgId: user?.org?.id,
  //       payload,
  //     });
  //     toast.remove();
  //     toast.success(response.message);
  //     navigate("/admin/email/template-emails");
  //   } catch (error) {
  //     toast.remove();
  //     toast.error("Error sending email template to the backend:", error);
  //   }
  // };
  const handleExportHtml = async (html, design) => {
    toast.loading("Loading...");

    const unlayer = emailEditorRef.current?.editor;

    // Step 1: Store HTML and Design JSON in sessionStorage first
    sessionStorage.setItem("editor_html", html);

    // Save the design separately using the saveDesign method
    unlayer.saveDesign((design) => {
      sessionStorage.setItem("editor_json", JSON.stringify(design));
    });

    // Step 2: Wait for the HTML and JSON to be stored before making the API call
    setTimeout(async () => {
      let payload = {
        name: templateName,
        subject: subject,
        templateBody: sessionStorage.getItem("editor_html"), // Retrieve from sessionStorage
        jsonBody: sessionStorage.getItem("editor_json"), // Retrieve JSON from sessionStorage
        createdBy: user?.userId,
      };

      try {
        // Send the exported HTML and JSON to the backend API
        const response = await api.createEmailTemplate({
          orgId: user?.org?.id,
          payload,
        });

        toast.remove();
        toast.success(response.message);
        navigate("/admin/email/template-emails");
      } catch (error) {
        toast.remove();
        toast.error(`Error sending email template to the backend: ${error}`);
      }
    }, 100); // Delay the API call to give time for sessionStorage to update
  };

 
  const loadTemplate = (editor) => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer.loadDesign();
  };
  const onReady = (unlayer) => {
    // Editor is ready
    // You can load your template here;
    // The design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };
  return (
    <div className="p-5 sm:p-10">
      <div>
        <div className="flex justify-between">
          <div className="mb-4">
            <label className="block text-base font-medium text-gray-400 mb-2">
              FROM
            </label>
            <input
              type="email"
              value={fromEmail}
              onChange={(e) => setFromEmail(e.target.value)}
              placeholder="sender@email.com"
              className="w-full border rounded-lg  py-3 px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primaryLight focus:border-transparent"
            />
          </div>
          <div className="w-2/3">
            <label className="block text-base font-medium text-gray-400 mb-2">
              REPLY TO
            </label>
            <MultiSelectInput
              selectedValues={selectedValues}
              onValuesChange={handleValuesChange}
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-base font-medium text-gray-400 mb-2">
            Email Subject
          </label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Enter email subject"
            className="w-full border rounded-lg  py-3 px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primaryLight focus:border-transparent"
          />
        </div>
        <div className="mb-4">
          <label className="block text-base font-medium text-gray-400 mb-2">
            Template Name
          </label>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="Enter template name"
            className="w-full border rounded-lg  py-3 px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primaryLight focus:border-transparent"
          />
        </div>
      </div>
      <div className="border rounded-lg p-2">
        <div className="flex justify-end mb-10">
          <button
            onClick={handleExportHtml}
            className="py-3 px-6 bg-customBlue text-white text-xs rounded-md"
          >
            Save email
          </button>
        </div>
        <EmailEditor
          ref={emailEditorRef}
          onLoad={loadTemplate}
          onReady={onReady}
        />
      </div>
    </div>
  );
};

export default CreateEmailTemplate;
