import React, { useState } from 'react'
import StarterPage from '../../../../../components/StarterPage'
import { useQuery } from 'react-query'
import { api as learningPath } from '../../../../../api/learningPath'  
import { Link } from 'react-router-dom'
import CohortsTable from './components/CohortsTable'
import Summary from './components/Summary'
import Pagination from '../../../../../components/pagination/Pagination'

const ViewCohort = () => {

  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)

  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const orgId = JSON.parse(sessionStorage.getItem('user')!)?.org?.id

  const { data: cohorts, isLoading, refetch } = useQuery(
    ["cohort",  orgId, search, perPage, currentPage ],
    () => {
      return learningPath.getCohort({ id: orgId, search, perPage, currentPage });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
      onSuccess: (response: any) => {
            if(response?.meta?.total) {
              setIsEmpty(false)
              setTouched(true)
          }
          else setIsEmpty(true)
        }
    }
  );


  return (
    <div className='p-5'>
        <Summary />
        {
           (!isEmpty || touched) ?
              <section>
                 <header className='flex justify-between items-center mt-1 mb-5'>
                       <h5 className='text-md pt-2 font-semibold'>
                         Cohort Overview
                       </h5>
                        <div className='flex items-center'>
                          <input 
                            type="search" 
                            className='input-style bg-primaryGray w-[400px]'
                            placeholder='&#128269; Search cohorts....'
                            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                              setSearch(event.target.value)
                            }}
                          />
                          <Link to={'/admin/cohort/create'} 
                            className='btn bg-primary-blue ml-5'
                            onClick={ () => sessionStorage.removeItem('active-cohort') }
                          >
                              Create New Cohort
                          </Link>
                        </div>
                    </header>
                    <section className='p-5 bg-white'>
                       <CohortsTable
                          cohorts={cohorts}
                          currentPage={currentPage}
                          perPage={perPage}
                          isLoading={isLoading}
                          search={search}
                          setSearch={setSearch}
                          setCurrentPage={setCurrentPage}
                          refetch={refetch}
                          setPerPage={setPerPage}
                        />
                        <Pagination
                          data={cohorts?.meta}
                          perPage={perPage}
                          pageNo={currentPage}
                          setPageNo={setCurrentPage}
                          setPerPage={setPerPage}
                      />
                    </section>
              </section> :
             <StarterPage 
                header='Create Your First Cohort'
                text='Create cohort, team, add learner and learning path'
                to='/admin/cohort/create'
                linkText='Create Cohort'
            /> 
         }
    </div>
  )
}

export default ViewCohort