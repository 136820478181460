export const convertDate = (
  isoDate,
  dateStyle = "long",
  timeStyle = "short"
) => {
  if (!isoDate) return null;

  let timestamp = Date.parse(isoDate);
  let jsDate = new Date(timestamp);
  let fDate = new Intl.DateTimeFormat("en-uk", {
    dateStyle,
    timeStyle,
    hour12: true, // Ensures the time is displayed with AM/PM
  });
  return fDate.format(jsDate);
};

export function convertTime(seconds) {
  const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60); // 60 seconds in a minute

  if (!hours) {
    return `${minutes} min`;
  }

  if (!minutes) {
    return `${hours} hr${hours > 1 ? "s" : ""}`;
  }

  return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min`;
}
