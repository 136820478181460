import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {  useQuery } from "react-query";
import { api } from "../../../../../api/action-plan";
import { orgId } from "../../../../../utils/org";
import toast from "react-hot-toast";

const CreateGoal = () => {

  const {
    data: goals,
    isLoading,
    refetch,
  } = useQuery(
    ["get-all-goals"],
    async () => {
      const response = await api.getCourses({
        orgId,
       
      });
            return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  return (
    <div className="p-10 2xl:grid 2xl:place-content-center">
      <h1 className="mb-3 text-3xl text-[#1E1E1E]">Action Plan</h1>
      <div className="flex flex-col">
        <h3 className="text-customBlue text-lg font-semibold">Action Plan 1</h3>
        <p className="text-base text-[#4A4C56] font-medium">
          Add your key learnings and goals
        </p>
      </div>
      <Formik
      // initialValues={initialValues}
      // validationSchema={validationSchema}
      // enableReinitialize={true}
      // onSubmit={handleNext}
      >
        {({ values, setFieldError, setFieldValue }) => (
          <Form>
             <div className="mb-3 flex gap-6 items-center">
                <label
                  htmlFor="type"
                  className="text-[#333] mb-2 text-base font-medium"
                >
                  Select Course: 
                  {/* <span className="text-red-500 required-dot">*</span> */}
                </label>
                <Field
                  type="text"
                  id="topic"
                  className={`rounded-lg   appearance-none border 
                    w-1/2 py-2 px-3 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary/80 focus:border-transparent`}
                  name="topic"
                  placeholder="Enter meeting topic"
                />
                <ErrorMessage
                  name="topic"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="d">
                <h2 className="text-primary mb-2 text-base font-medium">
                Key learning
                </h2>
              </div>

       </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateGoal;
