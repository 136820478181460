import React from 'react'
import Button from '../../../../../../../components/Buttons/Button'

const Calculator = () => {
  return (
    <div>
        <section className='grid mt-3 grid-cols-1 gap-3'>
            <div>
                <span className='text-xs'>
                    Number Of Learners
                </span>
                <input type="number" className='input-md' />
            </div>
            <div>
                <span className='text-xs'>
                    Select Course
                </span>
                <select className='input-md'>
                    <option value="">Select Course</option>
                </select>
            </div>
        </section>
        <Button text={'Calculate'} width={'w-[200px] mx-auto mt-3'} bgColor={'bg-darkBlue'}  />
        <section className='grid mt-3 grid-cols-2 gap-3'>
            <div>
                <span className='text-xs'>
                    Credits
                </span>
                <input 
                    type="text" className='input-md' 
                    disabled style={{background: '#ccc'}}
                    value={'500'}
               />
            </div>
            <div>
                <span className='text-xs'>
                    Cost (USD)
                </span>
                <input 
                    type="text" className='input-md' 
                    disabled style={{background: '#ccc'}}
                    value={'10050'}
               />
            </div>
        </section>
        <Button 
             text={'Buy Now'} width={'w-[200px] mx-auto mt-3'}
       />
    </div>
  )
}

export default Calculator