import React, { useState } from "react";
//import PathInfo from "../components/PathInfo";
import { Form, Formik } from "formik";
//import PathContent from "../components/PathContent";
import { useEffect } from "react";
import PathInfo from "./components/PathInfo";
import { useParams } from "react-router-dom";
import PathContent from "./components/PathContent";

const CreatePath = () => {  
  
  const [activeScreen, setActiveScreen] = useState(1);
  const [preview, setPreview] = useState(null);
  const {id} = useParams()
  const current_path = JSON.parse(sessionStorage.getItem("active-path")!);

  const [isCreated,setIsCreated] = useState(false);

  const getLanguageData = (symbol : 'en' | 'fr' | 'pt', key: string) : string => {
    console.log(current_path?.languages);
    
    return id ?  
       current_path?.languages?.length > 1 ? 
         current_path?.languages?.find( (language: any) => {
           return language?.symbol === symbol
         })[key] || '' : '' : ''
  }

  const initialValues = {
    c_title: id ? current_path?.name : "",
    c_des: id ? current_path?.description : "",
    c_title_fr:getLanguageData('fr','name'),
    c_des_fr:getLanguageData('en','description'),
    c_title_pr:getLanguageData('pt','name'),
    c_des_pr:getLanguageData('pt','description'),
    image: id ? current_path?.imagePath : "",
    c_point: "1",
    start_date: id ? current_path?.startDate : "",
    end_date: id ? current_path?.endDate : "",
    c_credit: "1",
    c_type: "learning-path",
    status: id ? current_path?.status === 'published' : false
  };

  useEffect(() => {
    //current_path?.id && setActiveScreen(2);
  },[])

  const validate = (values: any) => {
    const errors: any = {};
    // Existing validations
    if (!values?.c_title) errors.c_title = `Path title is required`;
    else if (!values?.c_des) errors.c_des = `Path description is required`;
    else if (!values?.c_title_fr) errors.c_title_fr = `French Path description is required`;
    else if (!values?.c_title_pr) errors.c_title_pr = `Portuguese Path description is required`;
    else if (!values?.c_des_fr) errors.c_des_fr = `French Path description is required`;
    else if (!values?.c_des_pr) errors.c_des_pr = ` Portuguese Path description is required`;
    else if (!values?.c_credit) errors.c_credit = `Credit is required`;
    else if (!values?.c_point) errors.c_point = `Points is required`;
    else if (!values?.start_date) errors.start_date = `Start Date is required`;
    else if (!values?.end_date) errors.end_date = `End Date is required`;
    else if (!values?.c_type) errors.c_type = `Type is required`;

    // Additional validation: Start date cannot be after the end date
    const startDate = new Date(values.start_date);
    const endDate = new Date(values.end_date);

    if (startDate > endDate) {
      errors.start_date = `Start Date cannot be after End Date`;
      errors.end_date = `End Date cannot be before Start Date`;
    }
    return errors;
  };

  const createPath = (values: any, actions: any) => {
    actions.setSubmitting(false);
  };

  const step1Validated = (values: any) => {
     return (values.c_title &&
     values.c_des &&
     values.c_point &&
     values.c_credit &&
     values.start_date &&
     values.end_date &&
     values.c_type)
  }
     
  return (
    <div className="pt-2">
      <section>
        <Formik
          initialValues={initialValues}
          validate={validate}
          enableReinitialize={true}
          onSubmit={createPath}
        >
          {({ values, setFieldError, setFieldValue, isValid }) => (
            <Form>
              {activeScreen === 1 && (
                <PathInfo
                  values={values} id={id}
                  setFieldValue={setFieldValue}
                  logoPreview={preview}
                  setLogoPreview={setPreview}
                  setActiveScreen={setActiveScreen}
                  setIsCreated={setIsCreated}
                  step1Validated={step1Validated(values)}
                />
              )}
              {activeScreen === 2 && (
                 <PathContent />
              )}
              <button
                type="submit"
                id="create-path-btn-2"
                className={`hidden`}
                disabled={isValid}
              ></button>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
};

export default CreatePath;
