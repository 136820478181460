import React, { useState } from "react";
import { useQuery } from "react-query";
import { api } from "../../../../../../api/action-plan";
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";

import ProgressBar from "../../../../../../components/progress/ProgressBar";

const GoalProgress = () => {
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
  
  const {
    data: goalProgress,
    isLoading,
    refetch,
  } = useQuery(
    ["get-goal-progress", orgId],
    async () => {
      const response = await api.getGoalProgress({
        orgId });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div>
        <h2 className="text-lg text-[#0C0D0F] font-semibold">Goals</h2>
        <p className="text-sm text-[#727A90] font-medium">Submit Target</p>
        <div>
          <ProgressBar progress={(goalProgress?.completedToday /
                  goalProgress?.percentageDifference) *
                  100}   
               />
          <p className="flex justify-between mt-3">
            <span className="text-gray-500 font-medium text-xs">
              +{goalProgress?.completedToday} courses today
            </span>
            <span className="text-green-500 font-medium text-xs">
              +{goalProgress?.completionPercentage}
            </span>
          </p>
          <p className="text-xs text-gray-500 font-semibold mt-2 pt-2 border-t border-gray-200">
            Learner completed{" "}
            <span className="text-gray-900">
              {goalProgress?.completedToday}
            </span>{" "}
            courses today.
          </p>
          <p className="flex justify-between mt-3">
            <span className="text-gray-500 font-medium text-xs flex items-center">
              Completion &nbsp;{" "}
              <span className="text-primary text-md font-medium">
                {(goalProgress?.completedToday /
                  goalProgress?.percentageDifference) *
                  100}
                %
              </span>
            </span>
            <span className="text-gray-500 font-medium text-xs flex items-center">
              Target &nbsp;{" "}
              <span className="text-green-500 text-md font-medium"> 100% </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GoalProgress;
