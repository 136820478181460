import React from 'react';
import BadgeIcon from '../../../../../../../assets/Badge.png';
import BadgeIconGreen from '../../../../../../../assets/Badge (1).png';
import OverviewCard from '../../../../../../../components/Cards/OverviewCards';

type SummaryType = {
    pathSummary: any
}

const Summary: React.FC<SummaryType> = ({pathSummary}) => {

  return (
    <div className='grid grid-cols-1 pt-2 pb-4 gap-7 md:grid-cols-3'>
        <OverviewCard 
           title='Total Learning Paths'
           total={pathSummary?.learningPaths}
           iconPath={BadgeIcon}
        />
        <OverviewCard 
           title='Active'
           total={pathSummary?.activeLearningPaths}
           iconPath={BadgeIconGreen}
        />
        <OverviewCard 
           title='In-active'
           total={pathSummary?.inactiveLearningPaths}
           iconPath={BadgeIcon}
        />
    </div>
  )
}

export default Summary