import React, {useState} from "react";
import Card from "./Card";
import femaleAvatar from '../../../../../../../assets/Female.png'
import { useQuery } from "react-query";
import { api } from "../../../../../../../api/report";
import Loader from "../../../../../../../components/loader/Loader";
import UserImg from "../../../../../../../assets/user.PNG"

function TopLearners() {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(sessionStorage.getItem('user'))

  const { data: learners, isLoading, refetch } = useQuery(
    ["learners summary", { search, perPage, currentPage, id: user?.org?.id }],
    ({ queryKey }) => {
      return api.getLearnersSummary(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  return (
    <Card className={"h-full overflow-y-scroll"}>
      <div className="mb-4">
        <h2 className="text-lg text-black font-semibold">Top Learners</h2>
        <h2 className="text-sm text-gray-500 font-medium">Performance by cohorts</h2>
      </div>
      { isLoading ?  <Loader /> :
          learners?.data?.length ?
            learners?.data?.map( learner => {
              return (
                <div key={learner?.id} className="space-y-2  mb-3 flex flex-row justify-between">
                  <div className="flex items-center gap-2 ">
                    <div className="h-12 w-12 rounded-md">
                      <img src={UserImg} className="w-full h-full object-cover" alt="avatar" />
                    </div>
                    <div>
                      <h4 className="text-sm font-bold">{learner?.name}</h4>
                      <h5 className="text-xs text-gray-500 font-normal">
                        {learner?.numberOfEnrolledCohorts} Cohorts
                      </h5>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <h4 className="text-sm">
                      {learner?.numberOfCompletedCohorts} done
                     </h4>
                    <span class="h-min bg-green-100 text-green-800 text-xs font-medium me-2 px-1.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                      +{ (learner?.numberOfCompletedCohorts / learner?.numberOfEnrolledCohorts) * 100 }%
                    </span>
                  </div>
                </div>
              )
            })
        : 
        <p className="py-5 text-center">
           No data found.
        </p>
      }
    </Card>
  );
}

export default TopLearners;
