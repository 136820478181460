import React from "react";

const StatCard = ({ data }) => {
  return (
    <div className="relative border-white text-sm font-medium rounded-md bg-white shadow-lg p-5">
      <div className="flex items-center justify-between">
        <h1 className="text-gray-500">{data?.title}</h1>
        <div className="">
          <img src={data?.imgUrl} alt="" />
        </div>
      </div>
      <p className="mt-3 font-semibold text-xl">
        {data?.amount || "-"}{" "}
        <span className={data?.percentage ? "" : "hidden"}>
          ({Math.floor(data?.percentage)})%
        </span>
      </p>
      {data?.subtitle && (
        <p className="mt-2 text-gray-400 text-sm">{data?.subtitle}</p>
      )}
    </div>
  );
};

export default StatCard;
