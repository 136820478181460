import React from 'react'
import Table from '../../../../../../../../components/table/Table';
import Loader from '../../../../../../../../components/loader/Loader';
import EmptyData from '../../../../../../../../components/EmptyData';

const BundlesTable = ({ isLoading, data, perPage, setOpenBundleModal, setActiveBundle }) => {

  let columns = React.useMemo( () => [
    {
      title: "ID",
      selector: null,
      key: "id",
      render: ({ index }) =>
        index +
        1 +
        ((data?.meta?.page || 1) - 1) * (data?.meta?.perPage || perPage ),
    },
    {
        title: "Title",
        selector: "name",
        key: "name",
        render: ({ item, row }) => {                      
             return( 
                <span className="">{item}</span>
             )
         },
      },
    {
      title: "Minimum Credit",
      selector: "minimumCredit",
      key: "minimumCredit",
      render: ({ item, row }) => {                      
           return( 
              <span className="">{item}</span>
           )
       },
    },
    {
      title: "Maximum Credit",
      selector: "maximumCredit",
      key: "maximumCredit",
      render: ({ item, row }) => {                      
           return( 
              <span className="">{item}</span>
           )
       },
    },
    {
      title: "Discount (%)",
      selector: "discount",
      key: "discount",
      render: ({ item }) => {                      
        return <span className="">{item}</span>;
      }
    },
    {
      title: "Start Date",
      selector: "startDate",
      key: "startDate",
      render: ({ item }) => {           
        return <span className="">
            {`${ new Date(item).toLocaleDateString('en-ng')}`}
        </span>;
      },
    },
    {
      title: "End Date",
      selector: "endDate",
      key: "endDate",
      render: ({ item }) => {           
        return <span className="">
            {`${ new Date(item).toLocaleDateString('en-ng')}`}
        </span>;
      },
    },
    {
      title: "Date Added",
      selector: "createdAt",
      key: "createdAt",
      render: ({ item }) => {           
        return <span className="">
            {`${ new Date(item).toLocaleDateString('en-ng')}`}
        </span>;
      },
    },
    {
      title: "Action",
      selector: null,
      key: null,
      render: ({row}) => {           
        return <span className="font-medium">
           <span className='bi hover:cursor-pointer bi-pencil-square text-blue-500 mr-3' 
               onClick={ () => {
                 setActiveBundle(row)
                 setOpenBundleModal(true)  
               }}
           />
           <span className='bi hidden bi-trash text-red-500'></span>
        </span>;
      },
    },
  ],
  [1, 20, 20]
);

const tableProps = { isLoading, columns, data: data?.data }

if(isLoading) return <Loader />

  return (
    <div>
         {
            data?.data?.length ?
            <Table {...tableProps} /> : <EmptyData text={'No any bundle found'} />
         }
    </div>
  )
}

export default BundlesTable