import React, { useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { ScaleLoader } from "react-spinners";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../../../api/email";
import Search from "../../../../../components/Inputs/Search";

const EmailTemplateSelection = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const {
    data: templates,
    isLoading,
    refetch,
  } = useQuery(
    ["all-templates", search, perPage, currentPage],
    async () => {
      const response = await api.getAllEmailTemplates({ orgId, search,perPage, currentPage });
      console.log(response, "response");
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );


  return (
    <div>
      <div className="max-w-screen-xl mx-auto p-5 sm:p-10">
        <div className="flex justify-between mb-3">
          <div className="flex flex-col">
          <h3 className="text-lg font-semibold text-[#091F46] ">
            All templates
          </h3>
          <p className="text-sm text-gray-500">
            Select an email template to edit or create a new one
          </p>
          </div>
          <div className=" flex gap-4  justify-end">
            <button
              type="button"
              onClick={() => {
                navigate("/admin/email/template-emails");
              }}
              className="p-3 bg-primary text-white text-xs rounded-md"
            >
              See all emails
            </button>
            <button
              type="button"
              onClick={() => {
                navigate("/admin/email/create-email");
              }}
              className="p-3 bg-customBlue text-white text-xs rounded-md"
            >
              + Create  Template
            </button>
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <Search
            onChange={(event) => setSearch(event.target.value)}
            placeholder={"Search Email Templates"}
            className={"w-fit"}
          />
        </div>
        <div>
          {isLoading ? (
            <div className="mt-10 flex justify-center items-center">
              <ScaleLoader color="#FF9100" />
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 gap-10">
              {templates?.length > 0 ? (
                templates.map((template, index) => (
                  <div
                    key={index}
                    className="border-r border-b border-l border-gray-400 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal"
                  >
                    <img
                      src="https://images.pexels.com/photos/1181467/pexels-photo-1181467.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                      className="w-full mb-3"
                    />
                    <div className=" p-4 ">
                      <div className="">
                        <p className="text-gray-900 font-bold text-lg mb-2 hover:text-indigo-600 inline-block">
                          {template.name}
                        </p>
                        <div
                          className="flex justify-between
                        items-center"
                        >
                          <p className="text-gray-700 text-sm">
                            {template.subject}
                          </p>
                          <div className="flex gap-2">
                            <i
                              className="bi bi-pencil-fill cursor-pointer"
                              onClick={() => {
                                navigate(
                                  `/admin/email/edit-template/${template.templateId}`
                                );
                              }}
                            ></i>
                            {/* <i className="bi bi-eye cursor-pointer"></i> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex justify-center">
                  <p className="text-center">No Data Available</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateSelection;
