import React from "react";
import LineChart from "../../../../../../../../components/Charts/LineChart";
import { api } from "../../../../../../../../api/billing";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const CreditUsageChart = ({ chartData, loading }) => {
   
  const user = JSON.parse(sessionStorage.getItem("user"));

  const {
    data: creditUsageData,
    isLoading: loadCreditUsage,
    
  } = useQuery(
    ["credit-usage"],
    async () => {
      const response = await api.getCreditUsage(user?.org?.id);
       return response?.data?.creditUsage;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  const labels = creditUsageData?.map((entry) => entry.date);
  const dataPoints = creditUsageData?.map((entry) => entry.cost);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Usage",
        data: dataPoints,
        fill: false,
        backgroundColor: "#071839",
        borderColor: "#D8DCED",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <p className="text-[#0C0D0F] text-lg font-semibold">Credit Usage</p>
          {/* <p className="text-[#0C0D0F] text-sm font-normal">Cohort xxxxx</p> */}
        </div>
        {/* <div className="w-fit">
          <select className="input-md">
            <option value="">Select Course</option>
          </select>
        </div> */}
      </div>
      <div className=" flex items-center justify-center mt-6">
        <LineChart data={data} loading={loading} options={options} />
      </div>
    </div>
  );
};

export default CreditUsageChart;
