import React, { useEffect, useState } from "react";
import Tab from "./components/horizontal-tab";
import OverviewCard from "../../components/OverviewCard";
import BadgeIcon from "../../../../../../assets/Badge.png";
import BadgeIconOrange from "../../../../../../assets/Bagde (5).png";
import BadgeIconGreen from "../../../../../../assets/Badge (1).png";
import BadgeIconLightGreen from "../../../../../../assets/Group 12.png";
import Button from "../../../../../../components/Buttons/Button";
import ActivitySection from "./components/ActivitySection";
import CoursesSection from "./components/CoursesSection";
import LearnerProfile from "./components/LearnerProfile";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
import { api } from "../../../../../../api/report";
import GenerateReportModal from "./components/GenerateReportModal";
import { useParams } from "react-router-dom";

const LearnerDetails = () => {
  const { learnerId } = useParams();
  const [openModal, setOpenModal] = useState(false);

  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;


  const tabsData = [
    {
      label: `Courses`,
      content: <CoursesSection />,
    },
    {
      label: `Activity`,
      content: <ActivitySection />,
    },
  ];

  const { data } = useQuery(
    ["learner-summary"],
    async () => {
      const response = await api.getIndividualLearnerSummary({orgId, learnerId});
      return response;
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <div className="p-6">
      <header className="flex justify-between">
        <div className="">
          <h5 className="text-lg font-semibold mb-2">Learner Details</h5>
          <p className="text-[#091F46] text-sm font-medium">
            Dashboard/Learner/Learner Details
          </p>
        </div>
        <div className="w-[200px] mb-2">
          <Button
            text={"+ Generate Report"}
            bgColor={"bg-darkBlue"}
            width={"w-full"}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        </div>
      </header>

      <div className="flex">
        <div className="w-1/3">
          <LearnerProfile />
        </div>
        <div className="w-2/3">
          <section className="grid grid-cols-4 gap-5">
            <OverviewCard
              title={"Total Courses"}
              total={data?.data?.totalCourses}
              iconPath={BadgeIcon}
              text={
                <>
                  <span className="text-sm text-green-500">10%</span>
                  <span className="text-sm text-gray-500 pl-3">
                    {" "}
                    +150 today
                  </span>
                </>
              }
            />
            <OverviewCard
              title={"Courses In Progress"}
              total={data?.data?.totalCoursesInProgress}
              iconPath={BadgeIconOrange}
              text={
                <>
                  <span className="text-sm text-green-500">10%</span>
                  <span className="text-sm text-gray-500 pl-3">
                    {" "}
                    +150 today
                  </span>
                </>
              }
            />
            <OverviewCard
              title={"Total Completed"}
              total={data?.data.totalCoursesCompleted}
              iconPath={BadgeIconGreen}
              text={
                <>
                  <span className="text-sm text-green-500">10%</span>
                  <span className="text-sm text-gray-500 pl-3">
                    {" "}
                    +150 today
                  </span>
                </>
              }
            />
            <OverviewCard
              title={"Completion Rate"}
              total={data?.data.completionRate}
              iconPath={BadgeIconLightGreen}
              text={
                <>
                  <span className="text-sm text-green-500">10%</span>
                  <span className="text-sm text-gray-500 pl-3">
                    {" "}
                    +150 today
                  </span>
                </>
              }
            />
          </section>
          <Tab tabsData={tabsData} />
        </div>
      </div>
      <GenerateReportModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        // refetch={refetch}
        // action={action}
        // activeRole={activeRole}
      />
    </div>
  );
};

export default LearnerDetails;
