import React from "react";
import { Routes, Route } from "react-router";

import EmailTemplateSelection from "./pages/EmailTemplateSelection";

import AllEmailTemplate from "./pages/AllEmailTemplate";
import EditTemplate from "./pages/EditTemplate";
import CreateEmailTemplate from "./pages/CreateEmailTemplate";

const EmailEditorRoutes = () => { 
  return (
    <Routes>
      <Route path="/" element={<EmailTemplateSelection />} />
      <Route path="/template-onboarding" element={<EmailTemplateSelection />} />
      <Route path="/create-email" element={<CreateEmailTemplate />} />
      <Route path="/template-emails" element={<AllEmailTemplate/>} />
      <Route path="/edit-template/:templateId" element={<EditTemplate/>} />
    </Routes>
  );
};

export default EmailEditorRoutes;
