import React, { useEffect, useState } from 'react'
import StackedBarChart from './StackedChart'
import { dashboardApi } from '../../../../../api/dashboard'
import { useQuery } from 'react-query'
import { formatCurrency } from '../../billing/site-admin/pages/summary/Summary'

export const colors = ['#0E316E','#F86624','#87AA4B','#eab308']

const LearnersStage = ({ cohorts, setCohorts, data }) => {

const [aciveFilter, setActiveFilter] = useState('daily')

const cohortIds = ( cohorts?.length >= 2 ) ? cohorts?.map( (cohort) => cohort?.value ).join(',') : ''

const filters = [
    { type: 'daily', active: false },
    { type: 'weekly', active: true },
    { type: 'monthly', active: false },
  ];

  const uniqueKey = "client-dashboard-learners-stage"

  const { data: stagesRate, isLoading, refetch } = useQuery(
    [uniqueKey, { period: aciveFilter, cohorts: cohortIds } ],
    ({queryKey}) => { 
        return cohortIds && dashboardApi.getClientStagesRate(queryKey[1]);
    },
    {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        select: response => response?.stageData
    });

  const labels = [  
    {title: "Not Started", key: "notStarted"},
    {title: "Enrolment", key: "enrolled"},
    {title: "Completed", key: "completed"}, 
    {title: "Started", key: "inProgress"},  
  ]
   
  return (
    <div className='bg-white p-4 rounded-lg shadow'>  
        <header className='flex justify-between'>
             <h5 className='font-bold'>Learner’s Stage</h5>
             {/*<div className='flex items-center'>
                { filters?.map( filter => {
                    return(
                      <button 
                        key={filter.type}
                        className={`py-1 capitalize ${
                        filter.type ===  aciveFilter ? ' bg-green-100 text-green-500' : ' text-gray-800 bg-gray-300'
                        } text-xs px-5 mr-3 rounded-lg`}
                        onClick={ () => {
                          setActiveFilter(filter.type)
                          refetch({queryKey: [uniqueKey, { period: filter.type, cohorts: cohortIds } ]})
                        } }
                        >
                            {filter.type}
                    </button>)
                } )}
            </div>*/}
        </header>
        <p className='text-xs mb-3'>
          Status of Learners at each stage
        </p> 
        <div className='flex items-center mt-3 mb-3 xl:ml-12'>
        {labels?.map( (label, index) => {
           return <div className='flex py-1 px-4 bg-slate-200 items-center mr-5'>
                    <span 
                       className={`w-[15px] rounded-full h-[15px]`}
                       style={{backgroundColor: colors[index]}}
                    /> 
                    <span className='ml-2 text-xs capitalize'>
                      {label?.title}
                      </span>
                </div>
           })}
         </div>
       <StackedBarChart 
          barThickness={60}
          withLegend={false}
          chartData={
             {
                labels: (cohorts?.length || !isLoading) ?  
                  cohorts?.map( (cohort, index) => {
                    const getCohort = data?.find( c => c?.cohort?.id === cohort?.value )
                    return data?.length ?  getCohort?.cohort?.name : '----'
                  })
                : [],
                datasets: (stagesRate?.length || !isLoading) ? labels?.map( (label, index) => { 
                  return {
                            label: label?.title,
                            backgroundColor: colors[index],
                            borderWidth: 1,
                            data: (cohorts?.length && stagesRate?.length) ?
                            cohorts?.map( (_cohort, cohortIndex) => {
                               const stageRate = stagesRate?.find( c => c?.cohortId === _cohort?.value )
                                return (stageRate !== undefined) ? stageRate[label?.key] : 0  
                            })   
                             : [0,0,0,0]
                          }
                  }) : []
              }
          }
      />
    </div>
  )
}

export default LearnersStage