import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { api } from "../../../../../api/email";

const EmailSettingStep3 = ({ prevStep, formData }) => {
  const navigate = useNavigate();
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const validationSchema = Yup.object().shape({
    toEmail: Yup.string().required("email is required"),
    subject: Yup.string().required("subject is required"),
    emailBody: Yup.string().required("email body is required"),
  });

  const initialValues = {
    toEmail: "",
    subject: "",
    emailBody: "",
  };

  const createEmailSettingMutation = useMutation(api.sendTestEmail, {
    onSuccess: (response) => {
      toast.remove();
      toast.success(response.message);
      navigate("/admin/email/template-configuration");
    },
    onMutate: () => {
      toast.loading("Loading...");
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
    },
  });

  const handleCreateEmailSetting = (values, actions) => {
    createEmailSettingMutation.mutate(
      {
        orgId,
        payload: {
          toEmail: values.toEmail,
          subject: values.subject,
          emailBody: values.emailBody,
        },
      },
      {
        onSuccess: () => {
          actions.resetForm(); // Reset the form after successful mutation
          actions.setSubmitting(false);
        },
      }
    );
  };

  return (
    <div className="p-10">
      
      <div className="mb-16">
        <h3 className="text-2xl font-semibold text-[#091F46]">
          Send Test Email
        </h3>
        <p className="font-medium text-[#8E8E8E] text-sm">
          Emails will be sent with configuration below:
        </p>
      </div>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) =>
            handleCreateEmailSetting(values, actions)
          }
        >
          {({ isSubmitting, values, actions }) => (
            <Form>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-20">
                <div className="flex flex-col">
                  <label
                    htmlFor="toEmail"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Email Address
                  </label>
                  <div className="flex relative">
                    <Field
                      type={"email"}
                      id="toEmail"
                      name="toEmail"
                      className="input-style bg-white"
                      placeholder="Enter your Email"
                    />
                  </div>
                  <ErrorMessage
                    name="toEmail"
                    component="p"
                    className="error-style"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="subject"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Subject:
                  </label>
                  <div className="flex relative">
                    <Field
                      type={"text"}
                      id="subject"
                      name="subject"
                      className="input-style bg-white"
                      placeholder="Enter your subject"
                    />
                  </div>
                  <ErrorMessage
                    name="subject"
                    component="p"
                    className="error-style"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="emailBody"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Body
                  </label>
                  <div className="flex relative">
                    <Field
                      type={"textarea"}
                      id="emailBody"
                      name="emailBody"
                      className="input-style bg-white"
                      placeholder="Enter your email body"
                    />
                  </div>
                  <ErrorMessage
                    name="emailBody"
                    component="p"
                    className="error-style"
                  />
                </div>
              </div>
              <div className="mt-5 flex gap-x-6 items-center">
                <button
                  type="button"
                  onClick={prevStep}
                  className={`flex items-center justify-center gap-1   
                       w-full font-semibold bg-[#E8EDF4] border border-customBlue text-customBlue p-3 rounded-lg text-xs`}
                >
                  <i className="bi bi-arrow-left text-white "></i>
                  Back
                </button>
                <button
                  type="submit"
                  className={`w-full font-semibold bg-customBlue text-white p-3 rounded-lg text-xs`}
                  disabled={isSubmitting} 
                >
                  Send Test Email
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EmailSettingStep3;
