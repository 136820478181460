import React, { useState } from "react";
import { useQuery } from "react-query";
import { api } from "../../../../../../api/action-plan";
import ConfirmModal from "../../../zoom-integration/components/ConfirmModal";
import { Link, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import moment from "moment";
import toast from "react-hot-toast";
import Pagination from "../../../../../../components/pagination/Pagination";
import { orgId } from "../../../../../../utils/org";
import CenteredModal from "../../../../../../components/Modal/CenteredModal";
import Progress from "../../../../../../components/progress/Progress";
import { api as resourcesApi } from "../../../../../../api/resources";
import VerticalMenu from "../../../../../../components/dropdown/VerticalMenu";
import MenuItem from "../../../../../../components/dropdown/MenuItem";
import Search from "../../../../../../components/Inputs/Search";

const AllActionPlanModal = ({
  openActionPlanModal,
  setOpenActionPlanModal,
  refetch
}) => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const active_path = JSON.parse(sessionStorage.getItem("active-path"));
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const [selectedActions, setSelectedActions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectActionPlan = (id) => {
    if (selectedActions.includes(id)) {
      setSelectedActions(
        selectedActions.filter((actionId) => actionId !== id)
      );
    } else {
      setSelectedActions([...selectedActions, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedActions([]);
    } else {
      setSelectedActions(actionPlans.map((action) => action.plan.id));
    }
    setSelectAll(!selectAll);
  };

  const { data: actionPlans, isLoading } = useQuery(
    ["get-all-plans", search, perPage, currentPage],
    async () => {
      const response = await api.getAllActionPlans({
        orgId,
        search,
        perPage,
        currentPage,
      });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteActionPlan = async () => {
    if (!selectedAction) return;
    try {
      toast.loading('Deleting action plan...')
      const response = await api.deleteActionPlan({
        orgId,
        actionPlanId: selectedAction?.plan.id,
      });
      setDeleteModal(false);
      toast.remove()
      toast.success(response.message);
     //   refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const addToResources = async (actionPlans) => {
    let resourcesData = actionPlans.map((id) => ({
      filePath: null, // Optional
      type: "action-plan", // Required
      itemId: id, // id of the action plan
    }));

    let payload = {
      resourcesData,
      parentId: active_path?.id, // Required
      source: "learning-path",
    };
    try {
      toast.loading("Loading...");
      const res = await resourcesApi.createResources(payload);
      toast.dismiss();
      toast.success(res.message);
      setOpenActionPlanModal(false)
      refetch()
    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <CenteredModal
      title={"All Action Plans"}
      hasHeader={false}
      open={openActionPlanModal}
      setOpen={setOpenActionPlanModal}
      width={"w-[1200px]"}
    >
      <div className="bg-white p-3 rounded-lg shadow mt-6">
        <div className="flex justify-between mb-3">
          <h3 className="text-lg font-semibold text-[#091F46]">
            Action Plans
          </h3>
          <div className="flex gap-6 justify-end">
            <Search
              onChange={(event) => setSearch(event.target.value)}
              placeholder={"Search ..."}
              className={"w-fit"}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className={`p-2 bg-customBlue text-white text-sm rounded-md ${
              selectedActions.length === 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={() => addToResources(selectedActions)}
            disabled={selectedActions.length === 0}
          >
            Add to Resources
          </button>
        </div>
        <div>
          {isLoading ? (
            <div className="mt-10 flex justify-center items-center">
              <ScaleLoader color="#FF9100" />
            </div>
          ) : (
            <div className="mt-4">
              <div className="overflow-x-auto">
                <table className="table-container">
                  <thead className="table-header">
                    <tr>
                      <th className="table-head">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className="table-head">ID</th>
                      <th className="table-head">Name</th>
                      <th className="table-head">No of Goals</th>
                      <th className="table-head">Key Learnings</th>
                      <th className="table-head">Submit Goals</th>
                      <th className="table-head">Achievement</th>
                      <th className="table-head">Date</th>
                      <th className="table-head">Action</th>
                    </tr>
                  </thead>

                  <tbody className="table-body text-xs">
                    {actionPlans?.length > 0 ? (
                      actionPlans?.map((action, index) => (
                        <tr key={index}>
                          <td className="table-data">
                            <input
                              type="checkbox"
                              checked={selectedActions.includes(
                                action.plan.id
                              )}
                              onChange={() =>
                                handleSelectActionPlan(action.plan.id)
                              }
                            />
                          </td>
                          <td className="table-data ">{index + 1}</td>
                          <td className="table-data ">
                            {action?.plan.name}
                          </td>
                          <td className="table-data ">
                            {action?.plan.numberOfGoals}
                          </td>
                          <td className="table-data ">
                            {action?.plan.keyLearningPerGoal}
                          </td>
                          <td className="table-data">
                            <Progress
                              width={80}
                              value={parseInt(
                                action?.goalPercentage
                              )}
                            />
                          </td>
                          <td className="table-data">
                            <Progress
                              width={80}
                              value={parseInt(
                                action?.achievementPercentage
                              )}
                            />
                          </td>
                          <td className="table-data">
                            {moment(action.plan.updatedAt).format(
                              "YYYY/MM/DD, h:mm a"
                            )}
                          </td>
                          <td className="table-data flex justify-center px-2">
                            <VerticalMenu>
                              <div className="w-fit flex flex-col gap-2">
                                <MenuItem
                                  icon={
                                    <i className="bi bi-trash3-fill text-red-500 cursor-pointer"></i>
                                  }
                                  label="Delete"
                                  onClick={() => {
                                    setSelectedAction(action);
                                    setDeleteModal(true);
                                  }}
                                />
                              </div>
                            </VerticalMenu>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="table-data" colSpan="12">
                          <p className="text-center">
                            No Data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {deleteModal && (
                    <ConfirmModal
                      title="Delete Action Plan"
                      description={`Are you sure you want to delete ‘‘${selectedAction?.plan?.name}”?`}
                      onYes={deleteActionPlan}
                      onClose={() => setDeleteModal(false)}
                    />
                  )}
                </table>
              </div>
            </div>
          )}
        </div>
        <section className="mt-1 px-3 rounded-lg bg-white">
          <Pagination
            data={actionPlans?.meta}
            perPage={perPage}
            pageNo={currentPage}
            handlePerPageChange={(value) => setPerPage(value)}
            handlePageChange={(value) => setCurrentPage(value)}
          />
        </section>
      </div>
    </CenteredModal>
  );
};

export default AllActionPlanModal;
