import React, { useContext } from "react";
import { LearnersContext } from "../../../../../context/learnersContext";
import { CompletedState, SuspendedState } from "./svg";

const ViewCourse = () => {
  const { currentLearner } = useContext(LearnersContext);

  // console.log(currentLearner);

  return (
    <div className="">
      <div className="bg-gray-200 p-2 flex items-center justify-between">
        <p className="font-semibold">{currentLearner?.name}</p>
        {/* <p className="font-semibold">
          Courses: {currentLearner?.numberOfCompletedCourses}/
          {currentLearner?.numberOfCourses}
        </p> */}
      </div>
      <div className="mt-4">
        {/* <h1 className="underline w-full text-xs text-start">Courses</h1> */}
        {currentLearner?.courses?.map((course) => (
          <div
            key={course?.courseId}
            className="bg-gray-100 p-3 mt-1 flex items-center justify-between"
          >
            <p className="text-sm">{course?.courseName}</p>
            <p className="text-sm">Score: {course?.overallScore}</p>
            <div
              className={`text-sm flex items-center justify-center gap-1 font-medium capitalize ${
                course?.status === "completed"
                  ? "text-green-600"
                  : "text-red-500"
              }`}
            >
              <span>
                {" "}
                {course?.status === "completed" ? (
                  <CompletedState />
                ) : (
                  <SuspendedState />
                )}
              </span>
              {course?.status}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewCourse;
