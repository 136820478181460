import React from 'react'
import Invoices from '../../../../../../client-admin/pages/components/Invoices'
import { generateYears } from '../../../../../../client-admin/pages/summary/Summary'

const Invoices_ = ({invoices, isLoading, setYear }) => {

  return (
   <div className='p-3 mt-5 bg-white rounded-lg'>
      <h5 className='pb-2 flex justify-between border-b border-gray-200 text-md'>
                Invoices
                <div className='w-[200px] flex items-center'>
                  <select 
                      className='input-md'
                      onChange={ (e) => setYear(parseInt(e.target.value)) }
                      >
                    {generateYears().map( year => {
                        return (
                          <option key={year} value={year}>{year}</option>
                        )
                    })}
                  </select>
                  <button className='bi ml-2 bg-primary text-white py-1 rounded-lg px-2 bi-funnel text-lg' />
                </div>
      </h5>
      <Invoices 
        invoices={invoices}
        isLoading={isLoading}
      />
    </div>
  )
}

export default Invoices_