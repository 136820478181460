import React, { useEffect, useState } from 'react';
import Team from './Team';
import { useQuery } from 'react-query';
import { api } from '../../../../../api/teams';
import Loader from '../../../../../components/loader/Loader';
import EmptyData from '../../../../../components/EmptyData';
import Pagination from '../../../../../components/pagination/Pagination';

const TeamLeaderBoard = () => {

const [perPage, setPerPage] = useState(5);
const [currentPage, setCurrentPage] = useState(1);
const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
const { data: teams, isLoading, refetch } = useQuery(
    ["client dashboard team leaderboard"],
    () => { 
        return api.getTeams({ orgId, perPage, currentPage });
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
  });

  useEffect( () => {
    refetch()
  }, [ currentPage, perPage, isLoading] )

  return (
    <div>
      {
        isLoading ? <Loader /> : 
        teams?.data?.length ? 
          teams?.data?.map( team => {
            return <Team
                     key={team?.team?.id} name={team?.team?.name}
                      point={team?.points} cohort={team?.cohortName}
                 />
          })
        :
        <EmptyData text={'There is no any team available.'} />
      }
      <Pagination 
          data={teams?.meta}
          perPage={perPage}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          setPerPage={setPerPage}
       />
    </div>
  )
}

export default TeamLeaderBoard