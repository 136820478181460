import React from 'react'

const CompanyRoleButton = ({text, active, onClick, width, height }) => {
  return (
    <>
       <button 
           type='button' onClick={onClick}
           style={{border: active ? '1px solid #FF9100' : '1px solid #444'}} 
          className={
             `${ width || 'w-[120px]' } ${height} flex py-2 text-xs rounded-full items-center ${
           active ? 'text-primary border border-primary' :
                  'border border-gray-200'
            }`}>
           <span className='bi bi-check-circle text-sm pl-3 mr-2'></span>
           {text}
       </button>
    </>
  )
}

export default CompanyRoleButton