import React, { useState } from "react";

const ConfirmModal = ({ title, description, onYes, onClose, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleYesClick = () => {
    setIsOpen(false);
    onYes(); // Call the callback function when "Yes" is clicked
  };

  const handleCloseClick = () => {
    setIsOpen(false);
    onClose(); // Call the callback function when the modal is closed without clicking "Yes"
  };

  return (
    <div className="fixed bg-[rgba(0,0,0,0.4)] top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50 z-50">
      <div className="px-10 py-[20px] bg-white rounded-lg flex flex-col justify-center  relative shadow-2xl">
        <div className="flex flex-col ">
          <h1 className="mb-6 font-black text-xl tracking-normal">{title}</h1>
          <p className="text-center pb-5">
             <span className="text-6xl bi bi-recycle"></span>
          </p>
          <p className="mb-[24px] font-medium text-grey500 text-lg tracking-normal">
            {description}
          </p>
        </div>

        <div className="mt-4 flex justify-end items-center gap-x-6">
          <button
            onClick={handleYesClick}
            className="bg-white text-red-500 py-2.5 px-20 border border-red-500 rounded-lg font-bold text-md"
          >
            Yes
          </button>
          <button
            onClick={handleCloseClick}
            className="bg-red-500 text-white py-2.5 px-20 rounded-lg font-bold text-md"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
