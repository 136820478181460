import React from 'react'
import ViewClients from './pages/viewClients/ViewClients'
import { Route, Routes } from 'react-router-dom'
import CreateClient from './pages/createClient/CreateClient'

const Clients = () => {
  return (
    <div>
        <Routes>
            <Route path="/" element={<ViewClients />} />
            <Route path="/create" element={<CreateClient />} />
        </Routes>
    </div>
  )
}

export default Clients