import React, { useEffect, useState } from 'react'
import Table from '../../../../../../components/table/Table'
import ProgressBar from '../../../../../../components/progress/ProgressBar'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import ViewUsers from './ViewUsers';
import { useNavigate } from 'react-router';

type CohortTableType = {
    perPage: number, 
    setPerPage: React.Dispatch<React.SetStateAction<number>>,
    search: string,
    setSearch: React.Dispatch<React.SetStateAction<string>>,
    currentPage: number, 
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>, 
    cohorts: any, 
    isLoading: boolean, 
    refetch: any
}

const CohortsTable: React.FC<CohortTableType> = ({
    perPage, setPerPage, search, setSearch,
    currentPage, setCurrentPage, cohorts, 
    isLoading, refetch, 
}) => {
  
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [activeCohortId, setActiveCohortId] = useState<null | string>(null)
    const navigate = useNavigate()

    useEffect( () =>  {
        refetch()
    } , [] )

      let columns = React.useMemo( () => [
        {
          title: "ID",
          selector: null,
          key: "id",
          render: ({ index } : {index: number}) =>
            index +
            1 +
            ((cohorts?.meta?.page || 1) - 1) * (cohorts?.meta?.perPage || perPage ),
        },
        {
            title: "Name",
            selector: "name",
            key: "name",
            render: ({ item, row } : any) => {                      
                return( 
                    <span className="">
                        {row?.cohort?.name}
                  </span>
                )
            },
          },
        {
          title: "Status",
          selector: null,
          key: null,
          render: ({ row } : any) => {                      
              return( 
                  <span className={
                      `${row?.status === 'in progress' ? 'block text-primary rounded-full w-[80px] text-center py-1 border border-primary'
                       : 'text-customBlue block w-[80px] text-center py-1 border border-primary-blue rounded-full' 
                     }`}>
                     {row?.status}
                  </span>
              )
          },
        },
        {
          title: "Learners",
          selector: null,
          key: null,
          render: ({ row }: any) => {                      
              return( 
                  <span className="pl-3">
                    {row?.numberOfParticipants}
                 </span>
              )
          },
        },
        {
          title: "Progress",
          selector: "progress",
          key: "progress",
          render: ({ item } : {item : number} ) => {                      
            return <span className='w-[100px]'>
                  <ProgressBar height='8px' progress={item} />
            </span>
          }
        },
        {
            title: "Team Enabled",
            selector: "teamEnabled",
            key: "teamEnabled",
            render: ({ row } : any) => {                      
              return <span 
                        className={ !row?.cohort?.teamEnabled ? 'bi bi-x-octagon text-red-500 pl-8' :
                                  'bi bi-check-circle text-green-500 pl-8'}
                      />
            }
          },
        {
          title: "Date Created",
          selector: null,
          key: null,
          render: ({ row } : any) => {           
            return <span className="">
                {`${ new Date(row?.cohort?.createdAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Last Updated",
          selector: null,
          key: null,
          render: ({ row } : any) => {           
            return <span className="">
                {`${ new Date(row?.cohort?.updatedAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Action",
          selector: null,
          key: null,
          render: ({row} : any) => {           
            return  <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                        <button
                            className="text-sm bi bi-three-dots-vertical font-semibold" 
                            aria-label="Customise options" 
                        />
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Portal>
                        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                        <DropdownMenu.Item
                            className="DropdownMenuItem text-left  hover:bg-gray-200 text-primary-blue"
                            onClick={ () => navigate(`/app/admin/cohort/edit/${row?.cohort?.id}`)  }
                        >
                            <i className='bi bi-pencil-square pr-1' /> Edit cohort
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator className="DropdownMenuSeparator" />
                        <DropdownMenu.Item 
                            className="DropdownMenuItem text-left hover:bg-gray-200 text-primary-blue"
                            onClick={ () => {
                                setActiveCohortId(row?.cohort?.id)
                                setOpenModal(true)
                            } }
                        >
                            <i className='bi bi-eye pr-1' /> View learners
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator className="DropdownMenuSeparator hidden" />
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
          },
        },
      ],
      [1, 20, 20]
    );

 const tableProps = { isLoading, columns, data: cohorts?.data }
   
  return (
    <div className='p-5 bg-white'>
        <ViewUsers 
           activeCohortId={activeCohortId} openModal={openModal} 
           setOpenModal={setOpenModal}
         />
        <Table {...tableProps} />
    </div>
  )
}

export default CohortsTable