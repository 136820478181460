import React from 'react'

const OpenEndedComment = () => {
  return (
    <div>
        <textarea 
          style={{height: 70}} className='input-md shadow w-[600px]'
          placeholder='add your comments.....' 
        />
    </div>
  )
}

export default OpenEndedComment