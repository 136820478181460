import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useQuery } from "react-query";
import { ScaleLoader } from "react-spinners";
import { api } from "../../../../api/admin-management";

const AddAppModal = ({ isOpen, onClose, selectedApps, setSelectedApps }) => {

  const { data: appsData, isLoading } = useQuery(
    ["all-apps-module"],
    async () => {
      const response = await api.getAllModulesApps();
            return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );




  // Handle form submission
  const handleAddApps = () => { 

      onClose(); // Close modal after adding
  };

  if (!isOpen) return null;

  

  // Handle checkbox change
  const handleSelection = (app) => {
    setSelectedApps((prevSelected) => {
      // Check if the app is already selected by its id
      if (prevSelected.some((selected) => selected.id === app.id)) {
        // If it is selected, remove it from the array
        return prevSelected.filter((selected) => selected.id !== app.id);
      }
      // If it is not selected, add it to the array
      return [...prevSelected, app];
    });
  };
  


  return (
    <div className="fixed inset-0 flex items-start justify-end bg-gray-800 bg-opacity-50 mr-2">
      <div className="bg-white w-96 max-h-full rounded-l-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Add App(s)</h2>
          <button onClick={onClose} className="text-red-500 font-bold">
            ✕
          </button>
        </div>
        <p className="mb-4">Grant access to selected apps.</p>

        {/* App List with scrolling */}
        <div className="space-y-4 overflow-y-auto max-h-80">
          {isLoading ? (
            <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#FF9100" />
          </div>
          ) : (
            appsData.map((app) => (
              <div
                key={app.id}
                className="flex items-center justify-between p-4 border rounded-lg shadow-sm cursor-pointer"
                // onClick={() => handleSelection(app)}
              >
                <div className="flex items-center">
                  <span className="text-2xl">💰</span>
                  <span className="ml-3">{app.name}</span>
                </div>
                <input
                  type="checkbox"
                  id={app.slug}
                  value={app.name}
                  onChange={() => handleSelection(app)}
                />
                {/* <input
                  type="checkbox"
                  checked={selectedApps.includes(app.id)}
                  onChange={() => handleToggleApp(app.id)}
                  className="w-5 h-5"
                /> */}
              </div>
            ))
          )}
        </div>

        <div className="flex gap-6 mt-6">
          <button
            onClick={handleAddApps}
            className="bg-customBlue text-white px-4 py-2 rounded-md hover:opacity-90"
          >
            Add
          </button>
          <button
            onClick={onClose}
            className="bg-primary text-white px-4 py-2 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAppModal;
