import React from 'react'
import { useNavigate } from "react-router";
import CertificateEditor from '../../../../../components/Editor/CertificateEditor'

const CreateNewCertificate = () => {
  const navigate = useNavigate();
  return (
    <div className="p-5 sm:p-10">
        <div className="flex justify-end">
        <button
          type="button"
          onClick={() => {
            navigate(-1);
            sessionStorage.removeItem("elements");
            sessionStorage.removeItem("backgroundImage");
          }}
          className={`flex items-center justify-center gap-1  font-semibold 
                       bg-customBlue
                     text-white py-2 px-6 rounded-lg text-sm`}
        >
          <i className="bi bi-arrow-left text-white "></i>
          Back
        </button>
      </div>
        <CertificateEditor />
    </div>
  )
}

export default CreateNewCertificate