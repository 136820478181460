export const welcomePage = `
  <h1><strong>Welcome to {{cohort_name}}</strong></h1><p><br></p>
  <p> We are absolutely thrilled to have you join our online training program!
   By embarking on this journey, you’re taking a significant step toward enhancing 
   your knowledge and skills, and we couldn’t be more excited to support you along the way.</p>
   <p>&nbsp;</p><p><strong>About Service School House:&nbsp;</strong></p>
   <p>Service School House's library of courses provides you with the skills and 
   knowledge needed to excel in your role within the organization. These self-paced 
   courses can be taken at anytime and anywhere. Our goal is to upskill you to deliver 
   outstanding service!!!&nbsp;</p><p> </p><p>At Service School House, our comprehensive
    library of courses is designed to equip you with the skills and expertise you need to
     thrive in your role. Whether you’re aiming to deepen your understanding or gain new insights,
      our self-paced courses offer the flexibility to learn anytime, anywhere. Our mission is to
       empower you to deliver exceptional service and achieve excellence in your career!</p>
       <p> </p><p>Get ready to unlock your potential and make the most of this opportunity.
        We’re here to support you every step of the way!</p><h2>Warm Regards</h2><p><br></p>
`