import React from 'react'

const AsideItem = ({icon, title, text}) => {
  return (
    <div className='flex items-center mb-4'>
        <img src={icon} alt="icon" className='w-[30px] h-[30px]' />
        <div className='pl-3'>
           <h6 className='text-xs font-semibold text-gray-500'>{title}</h6>
           <p className='capitalize text-[11px] mt-1'>{text}</p>
        </div>
    </div>
  )
}

export default AsideItem