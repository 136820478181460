import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { ScaleLoader } from "react-spinners";
import moment from "moment/moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../../api/email";
import CustomEmailEditor from "../../../../../components/Editor/EmailEditor";
import MultiSelectInput from "../../../../../components/Inputs/MultiSelectInput";
import EmailEditor from "react-email-editor";
import { isObject, isString } from "formik";

const EditTemplate = () => {
  const emailEditorRef = useRef(null);
  const navigate = useNavigate();
  const user = JSON.parse(window.sessionStorage.getItem("user"));
  const [templateName, setTemplateName] = useState("");
  const [subject, setSubject] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [initialDesign, setInitialDesign] = useState(null);
  const [templateBody, setTemplateBody] = useState("");

  const { templateId } = useParams();
  const [selectedValues, setSelectedValues] = useState([]);

  const handleValuesChange = (values) => {
    setSelectedValues(values);
  };

  const {
    data: templates,
    isLoading,
    refetch,
  } = useQuery(
    ["a-template"],
    async () => {
      const response = await api.getEmail({ orgId:user?.org?.id, templateId });
      const { name, subject, templateBody, jsonBody } = response?.data;
      setTemplateName(name);
      setSubject(subject);
      setInitialDesign(JSON.parse(jsonBody));
      setTemplateBody(templateBody);

     
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const handleExportHtml = async (html, design) => {
    toast.loading("Loading...");
    const unlayer = emailEditorRef.current?.editor;

    // Step 1: Store HTML and Design JSON in sessionStorage first
    sessionStorage.setItem("editor_html", html);

    // Save the design separately using the saveDesign method
    unlayer.saveDesign((design) => {
      sessionStorage.setItem("editor_json", JSON.stringify(design));
    });
    setTimeout(async () => {
      let payload = {
        name: templateName,
        subject: subject,
        templateBody: sessionStorage.getItem("editor_html"), // Retrieve from sessionStorage
        jsonBody: sessionStorage.getItem("editor_json"), // Retrieve JSON from sessionStorage
        createdBy: user?.userId,
      };

      try {
        // Send the exported HTML and JSON to the backend API
        const response = await api.updateEmailTemplate({
          orgId: user?.org?.id,
        templateId,
        payload,
        });

        toast.remove();
        toast.success(response.message);
        navigate("/admin/email/template-emails");
      } catch (error) {
        toast.remove();
        toast.error(`Error sending email template to the backend: ${error}`);
      }
    }, 100);
    };

  const onReady = (unlayer) => {
    // Editor is ready
    // You can load your template here;
    // The design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  const loadTemplate = (editor) => {
    const unlayer = emailEditorRef.current?.editor;
    // unlayer.loadDesign(JSON.parse(sessionStorage.getItem("editor_json")));
    unlayer.loadDesign(initialDesign);
  };

  return (
    <div>
      <div className="p-5 sm:p-10">
        <div className="flex justify-between">
        <h2 className="mb-6 text-lg font-semibold text-[#091F46]">
          Edit Email Template
        </h2>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className={`flex items-center justify-center gap-1  font-semibold 
                       bg-customBlue
                     text-white py-2 px-6 rounded-lg text-sm`}
        >
          <i className="bi bi-arrow-left text-white "></i>
          Back
        </button>
        </div>
        <div>
          <div className="flex justify-between">
            <div className="mb-4">
              <label className="block text-base font-medium text-gray-400 mb-2">
                FROM
              </label>
              <input
                type="text"
                value={fromEmail}
                onChange={(e) => setFromEmail(e.target.value)}
                placeholder="sender@email.com"
                className="w-full border rounded-lg  py-3 px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primaryLight focus:border-transparent"
              />
            </div>
            <div className="w-2/3">
              <label className="block text-base font-medium text-gray-400 mb-2">
                REPLY TO
              </label>
              <MultiSelectInput
                selectedValues={selectedValues}
                onValuesChange={handleValuesChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-base font-medium text-gray-400 mb-2">
              Email Subject
            </label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter email subject"
              className="w-full border rounded-lg  py-3 px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primaryLight focus:border-transparent"
            />
          </div>
          <div className="mb-4">
            <label className="block text-base font-medium text-gray-400 mb-2">
              Template Name
            </label>
            <input
              type="text"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              placeholder="Enter template name"
              className="w-full border rounded-lg  py-3 px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primaryLight focus:border-transparent"
            />
          </div>
        </div>

        <div className="border rounded-lg p-2">
          <div className="flex justify-end mb-10">
            <button
              onClick={handleExportHtml}
              className="py-3 px-6 bg-customBlue text-white text-xs rounded-md"
            >
              Update
            </button>
          </div>
          {!isLoading && (
            <EmailEditor
              ref={emailEditorRef}
              onLoad={loadTemplate}
              onReady={onReady}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTemplate;
