import React from "react";

const MenuItem = ({ icon, label, onClick }) => (
  <button onClick={onClick} className="flex items-center gap-4 w-fit border border-slate-100 px-4 py-2 text-left bg-white duration-200 hover:text-gray-900 text-gray-700 hover:bg-gray-100  cursor-pointer">
    {icon}
    {label}
  </button>
);

export default MenuItem;
