/* eslint-disable no-restricted-globals */
import React, { useMemo, useContext } from "react";
import Table from "../../../../../components/table/Table";
import { convertDate } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../../../components/pagination/Pagination";
import ReactSwitch from "react-switch";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { api } from "../../../../../api/reward";
import toast from "react-hot-toast";
import CategoryList from "./CategoryList";
import { RewardsContext } from "../../../../../context/rewardContext";

const RewardsList = ({
  data,
  refetch,
  setOpenModal,
  setOpenReward,
  isLoading,
  categories,
}) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { mutateAsync } = useMutation(api.editReward);
  const { mutateAsync: DeleteReward } = useMutation(api.deleteReward);

  const { setCurrentReward } = useContext(RewardsContext);

  const handleToggle = async (values) => {
    if (
      confirm(
        `Kindly confirm you wish to ${
          values?.status === "active" ? "de-activate" : "activate"
        } this reward?`
      )
    ) {
      toast.loading("Processing...");
      const payload = {
        status: values?.status === "active" ? "inactive" : "active",
        isPointBased: values?.isPointBased,
        numberOfPoints: values?.numberOfPoints,
        isBadgeBased: values?.isBadgeBased,
        badgeFileUrl: values?.badgeFileUrl,
        isForCourse: values?.isForCourse,
        isForQuiz: values?.isForQuiz,
        isForWebinar: values?.isForWebinar,
        isForLearningPath: values?.isForLearningPath,
        isRepeated: values?.isRepeated,
        startDate: values?.startDate,
        endDate: values?.endDate,
        startTime: values?.startTime,
        endTime: values?.endTime,
        updatedBy: values?.updatedBy,
        orgRewardCategoryId: values?.category?.id,
        name: values?.name,
        description: values?.description,
        actionTrigger: values?.actionTrigger,
      };

      try {
        const data = await mutateAsync({
          id: user?.org?.id,
          rewardId: values?.id,
          payload,
        });
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          refetch();
        }, 1000);
      } catch (e) {
        console.log(e);
        toast.remove();
        toast.error(e?.data?.message);
      }
    }
  };

  const handleDelete = async (row) => {
    if (confirm("Kindly confirm you wish to delete this reward")) {
      try {
        toast.loading("Processing...");
        const data = DeleteReward({ id: user?.org?.id, rewardId: row?.id });
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          refetch();
        }, 1000);
      } catch (e) {
        toast.remove();
        toast.error(e?.data?.message);
      }
    }
  };

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1,
      },
      {
        title: "Name",
        selector: "name",
        key: "name",
        render: ({ item, row }) => {
          return (
            <Link
              onClick={() => {
                setCurrentReward(row);
              }}
              className="text-armyGreen hover:underline"
              to={`${row?.id}`}
            >
              {item}
            </Link>
          );
        },
      },
      {
        title: "Category",
        selector: "category",
        key: "category",
        render: ({ item }) => {
          return <span>{item?.name}</span>;
        },
      },
      {
        title: "Description",
        selector: "description",
        key: "description",
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item, row }) => {
          return (
            <span>
              {
                <ReactSwitch
                  onColor="#091F46"
                  onChange={() => handleToggle(row)}
                  checked={item === "active"}
                />
              }
            </span>
          );
        },
      },
      {
        title: "Created By",
        selector: "createdByUser",
        key: "createdByUser",
        render: ({ item, row }) => {
          return (
            <span>
              {item?.name}, {convertDate(row?.createdAt)}
            </span>
          );
        },
      },
      {
        title: "Last Modified",
        selector: "updatedByUser",
        key: "updatedByUser",
        render: ({ row }) => {
          return <span>{convertDate(row?.updatedAt)}</span>;
        },
      },
      {
        title: "Actions",
        selector: null,
        key: null,
        render: ({ row }) => {
          return (
            <div className="flex items-center justify-start gap-2">
              <button
                className="text-blue-500 text-lg"
                onClick={() => {
                  sessionStorage.setItem("currentReward", JSON.stringify(row));
                  setTimeout(() => {
                    setOpenReward();
                  }, 1000);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                className="text-red-500"
                onClick={() => handleDelete(row)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const tableProps = { data, isLoading, columns };
  return (
    <div className="w-full mt-7 ">
      <div className="w-full mb-7 ">
        <div className="flex items-center justify-end gap-2">
          <button
            className="border border-darkBlue rounded-md py-3 px-10 bg-darkBlue text-white text-sm"
            onClick={setOpenModal}
          >
            Create Category
          </button>
          <button
            className="border border-customOrange rounded-md py-3 px-10 bg-customOrange text-white text-sm"
            onClick={() => {
              sessionStorage.removeItem("currentReward");
              setOpenReward();
            }}
          >
            Create Reward
          </button>
        </div>
      </div>
      <div className="w-full flex mb-7 items-center justify-center mt-3 border border-white shadow-lg p-3 bg-white rounded-md">
        <Table {...tableProps} />
      </div>
      <CategoryList />
    </div>
  );
};

export default RewardsList;
