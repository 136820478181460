import React, { useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { api } from "../../../../api/reward";
import { Link } from "react-router-dom";
import Trend from "./components/Trend";
import StatCard from "../learners/components/StatCard";
import active from "../learners/assets/active.png";
import EmptyData from "../../../../components/EmptyData";
import { RewardsContext } from "../../../../context/rewardContext";

const ViewReward = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { id } = useParams();

  const { currentReward: rewardInfo } = useContext(RewardsContext);

  // console.log(rewardInfo);

  //   console.log(id);

  const { data: currentReward } = useQuery(
    ["Rewards List"],
    ({ queryKey }) => {
      return api.getLeaderboard({ id: user?.org?.id, rewardId: id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  // console.log(currentReward);

  const summary = {
    title: "Number of earners",
    amount: currentReward?.data?.leaderboard?.length?.toLocaleString(),
    imgUrl: active,
  };

  return (
    <div className="w-full p-5">
      <span className="text-xs">
        <Link className="hover:underline" to={"/admin/rewards"}>
          Rewards
        </Link>{" "}
        / Engagements
      </span>
      <h1 className="font-semibold mt-3">{rewardInfo?.name}</h1>
      <p className="text-gray-500 text-xs">{rewardInfo?.description}</p>
      <div className="w-full mt-4 grid grid-cols-1 md:grid-cols-4">
        <StatCard data={summary} />
      </div>
      <div className="border border-white rounded-md shadow-lg bg-white p-5 mt-5 flex items-start justify-between gap-5">
        <div className="flex-1">
          <h1 className="font-semibold mt-3">Reward Distribution</h1>
          <p className="text-gray-500 text-xs">
            Earners distribution over time.
          </p>
          <div className="flex mt-4 items-center justify-between bg-white shadow-sm rounded-md">
            <Trend />
          </div>
        </div>
        <div className="flex-1">
          <h1 className="font-semibold mt-3">Leaderboard</h1>
          <p className="text-gray-500 text-xs">
            Learners with highest number of points and badges
          </p>
          {currentReward?.data?.leaderboard ? (
            currentReward?.data?.leaderboard?.map((data) => (
              <div
                key={data?.userId}
                className="border flex mt-4 items-center justify-between border-darkBlue bg-white shadow-sm p-3 mb-3 rounded-md"
              >
                <p className="text-xs font-medium">{data?.name}</p>
                <div className="flex items-center justify-end gap-2">
                  <p className="text-xs">{data?.totalPoints} points</p>
                  {/* <p className="text-xs">{data?.date}</p> */}
                </div>
              </div>
            ))
          ) : (
            <EmptyData text={"Nothing to show here..."} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewReward;
