import React, {useState} from 'react';
import Table from '../../../../../../../../components/table/Table';
import EmptyData from '../../../../../../../../components/EmptyData';
import PaymentConfirmationModal from './PaymentConfirmationModal';
import Loader from '../../../../../../../../components/loader/Loader';

const PaymentHistory = ({isLoading, transactions, refetch, perPage, openModal, setOpenModal}) => {

  const [activeTransaction, setActiveTransaction] = useState(null)

    let columns = React.useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 +
              ((transactions?.meta?.page || 1) - 1) * (transactions?.meta?.perPage || perPage ),
          },
          {
            title: "Transaction Id",
            selector: "transactionId",
            key: "transactionId",
            render: ({ item, row }) => {                      
                 return( 
                    <span className="text-blue-800">{item}</span>
                 )
             },
          },
          {
            title: "Client Name",
            selector: "clientName",
            key: "clientName",
            render: ({ row }) => {                      
                 return( 
                    <span className="">{row?.organization?.name}</span>
                 )
             },
          },
          {
            title: "Amount (USD)",
            selector: "amount",
            key: "amount",
            render: ({ item = 500}) => {                      
                if(item < 1000){
                   return <span className="bg-red-100 rounded text-red-500 py-1 px-2 text-[11px]">
                              {item}
                           </span>;
                }
                else if(item < 10000 && item > 1000){
                  return <span className="bg-blue-100 rounded text-blue-500 py-1 px-2 text-[11px]">
                         {item}
                     </span>;
                }
                else return <span className="text-green-500 bg-green-100 rounded py-1 px-2 text-[11px]">
                       {item}
                  </span>;
             },
          },
          {
            title: "Payment Type",
            selector: "type",
            key: "type",
            render: ({ item }) => {           
              return <span className="">{item}</span>;
            },
          },
          {
            title: "Payment date",
            selector: "createdAt",
            key: "createdAt",
            render: ({ item }) => {           
              return <span className="">
                 {`${ new Date(item).toLocaleDateString('en-ng')}`}
              </span>;
            },
          },
          {
            title: "Payment Status",
            selector: "status",
            key: "status",
            render: ({ item}) => {                      
                if( item !=='approved' ){
                   return <span className="bg-red-100 rounded-full text-red-500 py-1 px-2 text-[11px]">
                              {item}
                           </span>;
                }
                else return <span className="text-green-500 bg-green-100 rounded-full py-1 px-2 text-[11px]">
                       {item}
                  </span>;
             },
          },
          {
            title: "Action",
            selector: null,
            key: null,
            render: ({row}) => {           
              return <span 
                       className="bi bi-eye font-medium text-lg text-blue-500 hover:cursor-pointer" 
                       onClick={ () => {
                        setActiveTransaction(row)
                        setOpenModal(true)
                       } }
                  />;
            },
          },
        ],
        [1, 20, 20]
      );

    const tableProps = { isLoading, columns, data: transactions?.data }

  if(isLoading) return <Loader />

  return (
    <div>
      <PaymentConfirmationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        refetch={refetch}
        activeTransaction={activeTransaction}
        setActiveTransaction={setActiveTransaction}
      />
       { 
         transactions?.data?.length ?
          <Table {...tableProps} />
          : 
          <EmptyData text={'There is no any transaction history yet.'} />
       }
    </div>
  )
}

export default PaymentHistory