import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../../../../../../../components/Buttons/Button';
import { useMutation, useQuery } from 'react-query';
import Search from '../../../../../../../components/Inputs/Search';
import { learners as learnersAPi } from '../../../../../../../api/learners';
import { api } from '../../../../../../../api/teams';
import Loader from '../../../../../../../components/loader/Loader';
import Table from '../../../../../../../components/table/Table';
import EmptyData from '../../../../../../../components/EmptyData';
import Pagination from '../../../../../../../components/pagination/Pagination';
import toast from 'react-hot-toast';

const AssignLearners = () => {

  const { id, name } = useParams();
  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cohortId = queryParams.get('cohortId');
  const navigate = useNavigate()

  const {
    data: learners,
    isLoading,
    refetch,
  } = useQuery(
    ["view cogort learners"],
    ({ queryKey }) => {
      return learnersAPi.getLearners_Cohort({ 
        ...queryKey[1], perPage, page: currentPage, search, statusFilter: '',
        cohortId
      });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount:false,
      select: (data) => data,
    }) 

    useEffect( () => {
        refetch()
     }, [search, currentPage, perPage, isLoading,] )
 
     useEffect(() => {
         if (selectAll) {
           const btn = document.querySelector("#select-all-learners");
           if (btn) btn.click();
         }
       }, [learners?.data]);
 
       useEffect(() => {
         if(!isLoading){
             const selectedLearners = sessionStorage.getItem('learners')
             if(learners?.data && JSON.parse(selectedLearners)?.length){
                 const selected = document.querySelectorAll('.checkbox-inner')
                 selected.forEach( learner => {
                   learner.checked = JSON.parse(selectedLearners)?.
                                    findIndex( l => l?.cohortParticipantId === learner?.id ) !== -1
                 } )
             }
         }
       }, [learners?.data, isLoading]); 
 
       useEffect(() => {
         if (sessionStorage.getItem("learners"))
           sessionStorage.removeItem("learners");
       }, []);

       const addLearnersToTeamsMutation = useMutation(api.assignLearnersToTeam, {
        onSuccess: () => {
            toast.remove()
            toast.success('Learners Added To Teams successfully!')
            navigate(-1)
        },
        onError: error => {
            toast.remove()
            if(error.response.status === 500) toast.error(error.response.statusText)
            else if(error.response.status === 400) toast.error(error?.response?.data?.message)
            else{
               if(error?.response?.data?.errors?.length > 1){
                error?.response?.data?.errors?.map( error => {
                  toast?.error(error?.message)
                })
               }
               else toast.error(error?.response?.data?.errors[0]?.message)
            }
        },
        onMutate: () => {
            toast.loading('Loading...')
        }
      })
 
     let columns = useMemo(
         () => [
           {
             title: (
               <input
                 //checked={selectAll}
                 type="checkbox"
                 id="select-all-learners"
                 onClick={(event) => {
                   //setSelectAll(true)
                   const isChecked = event.target.checked;
                   document
                     .querySelectorAll(".learners-select-check")
                     .forEach((element) => {
                       element.checked = isChecked;
                       element.style.backgroundColor = isChecked ? "#ccc" : null;
                     });
                   setSelectAll(isChecked);
                   if (isChecked) {
                     if (sessionStorage.getItem("learners"))
                       sessionStorage.removeItem("learners");
                   }
                 }}
                 className="w-[18px] rounded-lg h-[18px]"
               />
             ),
             selector: null,
             key: "check",
             render: ({ index, row }) => {
               const learners = sessionStorage.getItem("learners");
               return (
                 <input
                   type="checkbox"
                   id={row?.cohortParticipantId}
                   defaultChecked={
                     learners
                       ? 
                        JSON.parse(learners).findIndex( l => l?.user?.email === row?.user?.email) !== -1
                       : false
                   }
                   className={`w-[18px] checkbox-inner checked:bg-primary border checked:border-primary rounded-lg h-[18px] learners-select-check`}
                   onClick={(event) => {
                     const learners = sessionStorage.getItem("learners");
                     if (learners) {
                       if (event.target.checked) {
                         sessionStorage.setItem(
                           "learners",
                           JSON.stringify([...JSON.parse(learners), row])
                         );
                       } else {
                         sessionStorage.setItem(
                           "learners",
                           JSON.stringify(
                             [...JSON.parse(learners)].filter((learners) => {
                               return learners?.email !== row?.email;
                             })
                           )
                         );
                       }
                     } else sessionStorage.setItem("learners", JSON.stringify([row]));
                   }}
                   onChange={(event) => {
                     const selectAllBtn = document.querySelector(
                       "#select-all-learners"
                     );
                     if (selectAllBtn.checked) {
                       event.target.checked = true;
                     }
                   }}
                 />
               );
             },
           },
           {
             title: "ID",
             selector: null,
             key: "id",
             render: ({ index }) =>
               index +
               1 +
               ((learners?.current_page || 1) - 1) *
                 (learners?.per_page || perPage),
           },
           {
             title: "Name",
             selector: "name",
             key: "name",
             render: ({row}) => {
                 return <span className='xs'> { row?.name || '-----------'} </span>
             }
           },
           {
             title: "Email",
             selector: "email",
             key: "email",
             render: ({row}) => {
                 return <span className='xs'> { row?.email || '------------'} </span>
             }
           },
           {
             title: "Status",
             selector: "status",
             key: "status",
             render: ({item}) => {
                 return <span className='xs'> { item || '------------'} </span>
             }
           },
           {
             title: "Enrollment Status",
             selector: "enrolledAt",
             key: "enrolledAt",
             render: ({item}) => {
                 return <span className={`xs ${item === null ? 'text-primary' : 'text-green-500'}`}> 
                            {item !== null ? 'Enrolled' : 'Not Enrolled'} 
                       </span>
             }
           },
           {
            title: "Enrollment Date",
            selector: "enrolledAt",
            key: "enrolledAt",
            render: ({item}) => {
                return <span className={`xs ${item === null ? 'bi bi-x-octagon text-red-500 pl-8' : ''}`}> 
                           {item !== null ? new Date(item).toLocaleDateString('en-ng') : ''} 
                      </span>
            }
          },
         ],
         [learners?.current_page, learners?.per_page, perPage]
       );
     
  const tableProps = { isLoading, columns, data: learners?.data }

  const addLearnersToTeam = () => {
    const learners = sessionStorage.getItem("learners");
    if(learners){
        const payload = {
            orgId, 
            payload: {
                cohortId, selectAll,
                cohortParticipantIds: selectAll ? [] : JSON.parse(learners)?.map( l => l?.cohortParticipantId ) 
            }
        }
       addLearnersToTeamsMutation.mutate(payload)
    }
    else toast.error('Error! Select Learners to proceed')
  }

  return (
    <div>
        <header className='flex justify-between'> 
             <h2 className='capitalize'>Add Leaners To {name} Team</h2>
             <Button 
               renderAs='link' 
               to={-1} 
               text={'Back'}
               width={'w-[130px]'}
               bgColor={'bg-customBlue'}
             />
        </header>  
        <p className='text-xs text-gray-600 -mt-4'>
           Manage Learners
        </p>
        <section className='flex bg-white mt-5 pt-1 items-center px-3 justify-between'>
            <h4 className='text-sm'>
               Cohort  Learners  ({learners?.meta?.total})
            </h4>
            <div className='flex items-center'>
            <Search
               placeholder={'Search Learners...'}
               width={'w-[300px] mb-0 mt-2 mr-5'}
               onChange={ e => setSearch(e.target.value) }
             />
             <Button 
                  text={'Assign Learners To Team'}
                  width={'w-[160px]'}
                  bgColor={'bg-customBlue'}
                  onClick={ addLearnersToTeam }
                />
        </div>
        </section>
        <section className='mt-5 bg-white'>
                  {
                    isLoading ? <Loader /> : 
                    learners?.data?.length ?
                        <Table {...tableProps} /> : 
                        <EmptyData text={'No learner found.'} />
                  }
             </section>
              <section className='mt-5 bg-white px-3'>
                <Pagination 
                    data={learners?.meta}
                    perPage={perPage}
                    pageNo={currentPage}
                    setPageNo={setCurrentPage}
                    setPerPage={setPerPage}
                />
              </section>
    </div>
  )
}

export default AssignLearners