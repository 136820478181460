import React, { useState } from 'react'
import Table from '../../../../../../../../components/table/Table';
import Loader from '../../../../../../../../components/loader/Loader';
import EmptyData from '../../../../../../../../components/EmptyData';
import AddClientToDiscount from './AddClientToDiscount';

const DiscountTable = ({isLoading, data, perPage, setOpenEditTierModal, setActiveDiscount, refetch}) => {

  const [openModal, setOpenModal] = useState(false)
  const [activeTier, setActiveTier] = useState(null)

  let columns = React.useMemo( () => [
    {
      title: "ID",
      selector: null,
      key: "id",
      render: ({ index }) =>
        index +
        1 +
        ((data?.meta?.page || 1) - 1) * (data?.meta?.perPage || perPage ),
    },
    {
      title: "Tier",
      selector: "name",
      key: "name",
      render: ({ item, row }) => {                      
           return( 
              <span className="">{item}</span>
           )
       },
    },
    {
      title: "Discount Type",
      selector: "discountType",
      key: "discountType",
      render: ({ item, row }) => {                      
           return( 
              <span className="">{item}</span>
           )
       },
    },
    {
      title: "Discount Amount",
      selector: "discountAmount",
      key: "discountAmount",
      render: ({ item = 200 }) => {                      
        return <span className="">{item}</span>;
      }
    },
    {
      title: "No Of Clients",
      selector: "",
      key: "",
      render: ({ row }) => {                      
        return <span className="text-primary">{row?.organizations?.length}</span>;
      }
    },
    {
      title: "Status",
      selector: "status",
      key: "status",
      render: ({ item }) => {           
        return item === 'active'?
         (<span className="bg-customBlue text-xs text-white py-3 px-5 rounded-lg">
            Active
        </span>) :
        (<span className="bg-gray-300 text-xs text-white py-3 px-5 rounded-lg">
          Disabled
      </span>)
      },
    },
    {
      title: "",
      selector: "",
      key: "",
      render: ({ item, row }) => {           
        return <span 
                onClick={ () => {
                  setActiveTier(row)
                  setOpenModal(true)
                }} 
                className="bg-[#F86624] text-xs text-white py-3 px-5 rounded-lg"
               >
            Add Client
        </span>;
      },
    },
    {
      title: "Action",
      selector: null,
      key: null,
      render: ({row}) => {           
        return <span className="font-medium">
           <span className='bi bi-pencil-square text-blue-500 mr-3' 
              onClick={ () => {
                setActiveDiscount(row)
                setOpenEditTierModal(true)
              } }
           />
           <span className='bi bi-trash hidden text-red-500'></span>
        </span>;
      },
    },
  ],
  [1, 20, 20]
);

  const tableProps = { isLoading, columns, data: data?.data }

  if(isLoading) return <Loader />

  return (
    <div>
        <AddClientToDiscount
            activeTier={activeTier}
            openModal={openModal} 
            setOpenModal={setOpenModal} 
            refetch={refetch}
         />
         { data?.data?.length ?  
            <Table {...tableProps} />
           :  <EmptyData text={'No Volume discound found.'} />
         }
    </div>
  )
}

export default DiscountTable