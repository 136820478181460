import React from 'react'
import Table from '../../../../../../../../components/table/Table';
import { Link } from 'react-router-dom';
import EmptyData from '../../../../../../../../components/EmptyData';
import Loader from '../../../../../../../../components/loader/Loader';

const PaymentHistory = ({isLoading, paymentHistory}) => {
 
    let columns = React.useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index + 1  
          },
          {
            title: "Client Name",
            selector: "clientName",
            key: "clientName",
            render: ({ row }) => {                      
                 return( 
                    <Link 
                       className="capitalize"
                       to={`/admin/billing/invoice/client/${row?.org?.id}`}
                    >
                        {row?.org?.name}
                    </Link>
                 )
             },
          },
          {
            title: "Email",
            selector: "email",
            key: "email",
            render: ({ row }) => {                      
                 return( 
                    <span className="">{row?.org?.email || '-------'}</span>
                 )
             },
          },
          {
            title: "Amount (USD)",
            selector: "amount",
            key: "amount",
            render: ({ row }) => {   
                const item = row?.invoice?.totalAmount                   
                if(item < 1000){
                   return <span className="bg-red-100 rounded text-red-500 py-1 px-2 text-[11px]">
                              {item}
                           </span>;
                }
                else if(item < 10000 && item > 1000){
                  return <span className="bg-blue-100 rounded text-blue-500 py-1 px-2 text-[11px]">
                         {item}
                     </span>;
                }
                else return <span className="text-green-500 bg-green-100 rounded py-1 px-2 text-[11px]">
                       {item}
                  </span>;
             },
          },
          {
            title: "No of Transcations",
            selector: "",
            key: "",
            render: ({ row }) => {           
              return <span className="pl-8">{row?.invoice?.numberOfTransactions}</span>;
            },
          },
          {
            title: "Action",
            selector: null,
            key: null,
            render: ({row}) => {           
              return ( 
                <Link 
                   className="bi bi-eye hover:text-white hover:bg-primary pl-3 border border-primary py-2 px-4 text-primary font-medium rounded-full text-xs"
                   to={`/admin/billing/invoice/client/${row?.org?.id}`}
                 > &nbsp; View More </Link>
              )
            },
          },
        ],
        [1, 20, 20]
      );

    const tableProps = { isLoading, columns, data: paymentHistory }

    if(isLoading) return <Loader />

    return (
      <div> 
          {
            paymentHistory?.length ?
            <Table {...tableProps} />
            :
            <EmptyData text={'There is no any payment history available yet.'} />
          }
      </div>
    )
}

export default PaymentHistory