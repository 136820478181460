import React from 'react'
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal'

const GenerateReportModal = ({openModal, setOpenModal}) => {
  return (
    <>
       <CenteredModal 
        title={
           <h5 className='text-md font-semibold bi bi-plus-circle'>
              &nbsp; Generate Report
          </h5>
         }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[450px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Download'}
        //btnBgColor={'bg-primary'}
        onClose={ () => {
             //  
        }}
      >
         <section className='text-left pb-3 px-5'>
              <h5 className='text-md'> Select Download Options </h5>
              <div className='mt-3'>
                 <label className='text-xs'>Report Type</label>
                 <select className='input-md'>
                    <option value="invoice">Invoice</option>
                    <option value="transactionHistory">
                        Transaction History
                    </option>
                 </select>
              </div>
              <div className='mt-3'>
                 <label className='text-xs'>Download As</label>
                 <select className='input-md'>
                    <option value="pdf">PDF</option>
                    <option value="xlsx">
                        XLSX/CSV
                    </option>
                 </select>
              </div>
         </section>
      </CenteredModal>
    </>
  )
}

export default GenerateReportModal