import React, {useState, useEffect } from 'react'
import Header from '../../components/Layout/Header/Header'
import FormHeader from './components/FormHeader'
import Input from './components/Input'
import Button from '../../components/Buttons/Button'
import toast from 'react-hot-toast'

const PasswordSection = ({setCurrentStep, isLoading, currentStep, setFieldValue, values }) => {
  
  const isNotValidated = !values?.company_name || !values?.company_address || !values?.role 
  const [ passwordVisible, setPasswordVisible ] = useState(false)
  const [ confirmPasswordVisible, setConfirmPasswordVisible ] = useState(false)

  useEffect( () => {
      if(isNotValidated){
        
         setCurrentStep(1)
         //toast.error('Error! complete the previous step before you proceed.')
      }
  }, [currentStep] )

  return (
    <div className='pb-8'>  
        <section className='mt-0 rounded-lg shadow w-[96%] md:w-[450px] mx-auto bg-lightOrange py-5 md:px-8'>
            <FormHeader text='You Are Almost Done!' />
                <Input 
                    label={'Password'} type='password' 
                    name={'password'}
                    visible={passwordVisible}
                    setVisible={setPasswordVisible}
                />
                <ul className='my-3 bg-white p-2 w-[90%] rounded-lg'>
                   <li className={`${ 
                                    values.password.length < 8 ? 'text-gray-400' : 'text-green-500'
                                } bi bi-check-circle text-xs py-1 pl-2`}>
                      &nbsp; Password must be at least 8 characters long.
                   </li>
                   <li className={`${ 
                                    !/[A-Z]/.test(values.password) ? 'text-gray-400' : 'text-green-500'
                                } bi bi-check-circle text-xs py-1 pl-2`}>
                      &nbsp; Password must contain at least one uppercase letter.
                   </li>
                   <li className={`${ 
                                    !/[a-z]/.test(values.password) ? 'text-gray-400' : 'text-green-500'
                                } bi bi-check-circle text-xs py-1 pl-2`}>
                      &nbsp;  Password must contain at least one lowercase letter.
                   </li>
                   <li className={`${ 
                                  !/\d/.test(values.password) ? 'text-gray-400' : 'text-green-500'
                                } bi bi-check-circle text-xs py-1 pl-2`}>
                      &nbsp;  Password must contain at least one number.
                   </li>
                   <li className={`${ 
                                    !/[!@#$%^&*()_+}{"':;?/>.<,]/.test(values.password) ? 'text-gray-400' : 'text-green-500'
                                } bi bi-check-circle text-xs py-1 pl-2`}>
                      &nbsp; Password must contain at least one special character.
                   </li>
                </ul>
                <Input 
                   label={'Confirm Password'}
                   type='password'
                   name={'cpassword'} 
                   visible={confirmPasswordVisible}
                   setVisible={setConfirmPasswordVisible}  
                  />
                <div className='mt-8 pb-4'>
                   <Button 
                      text={'Complete Registration'}
                      style={{opacity: isLoading ? 0.6 : 1}}
                      disabled = {isLoading}
                      onClick={ () => {
                            if(!values?.password || !values?.cpassword){
                               toast.error('Complete the required fields')
                            }
                            document.querySelector('#submit-btn').click()
                      }}
                   />
                </div>
        </section>
    </div>
  )
}

export default PasswordSection