import React from 'react'
import { courses } from '../../../../components/dummyData';
import Table from '../../../../../../../../components/table/Table';

const Courses = () => {

    let columns = React.useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 /*+
              ((clients?.meta?.page || 1) - 1) * (clients?.meta?.perPage || perPage ),*/
          },
          {
            title: "Name",
            selector: "name",
            key: "name",
            render: ({ item, row }) => {                      
                 return( 
                    <span className="">{item}</span>
                 )
             },
          },
          {
            title: "Duration",
            selector: "duration",
            key: "duration",
            render: ({ item }) => {           
              return <span className="">{item}</span>;
            },
          },
          {
            title: "Credit Cost",
            selector: "creditCost",
            key: "creditCost",
            render: ({ item }) => {           
              return <span className="">{item}</span>;
            },
          },
          {
            title: "Cost",
            selector: "cost",
            key: "cost",
            render: ({ item = 5000 }) => {           
              return <span className="">{item}</span>;
            },
          },
          {
            title: "Discount",
            selector: "discount",
            key: "discount",
            render: ({ item }) => {           
              return <span className="">{item}</span>;
            },
          },
          {
            title: "Action",
            selector: null,
            key: null,
            render: () => {           
              return <span className="bi bi-pencil-square text-blue-500"></span>;
            },
          },
        ],
        [1, 20, 20]
      );

    const tableProps = { isLoading: false, columns, data: courses }

  return (
    <div>
        <Table 
          {...tableProps}
          headerClassName={'border-t border-gray-200'}
        />
    </div>
  )
}

export default Courses