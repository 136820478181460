import React from "react";
import Header from "../../components/Layout/Header/Header";
import FormHeader from "./components/FormHeader";
import Ellipse3 from "../../assets/Ellipse 3.png";
import Ellipse1 from "../../assets/Ellipse 1 (1).png";
import Input from "./components/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { useMutation } from "react-query";
import { api } from "../../api";
import toast from "react-hot-toast";
import * as Yup from "yup";

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdminRoute = location.pathname.includes("admin");
  const url = window.location.origin

  const initialValues = {
    email:sessionStorage.getItem("reg-email") || "",
    otp: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Email is Required"),
    otp: Yup.string().required("Otp is Required"),
  });

  const emailVerificationMutation = useMutation(api.resetPassword, {
    onSuccess: (response) => {
      toast.remove();
      toast.success(response.message);
      navigate("/auth/login");
    },
    onMutate: () => {
      toast.loading("Loading...");
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
    },
  });

  const verifyEmail = (values, actions) => {
    // Handle submission

    emailVerificationMutation.mutate({
      email:  values?.email,
      otp: values?.otp,
    });
    actions.setSubmitting(false);
  };

  const resendVerificationEmail = async () => {
    toast.loading("Loading...");
    let payload = {
      email:  sessionStorage.getItem("reg-email"),
      baseUrl: `${url}/auth/verify-email`,
    };
    try {
      const response = await api.resendEmailVerification(payload);
      toast.remove();
       toast.success(response.message);
    } catch (error) {
      toast.remove();
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <Header isAdminRoute={isAdminRoute} />
      <div
        className={`mt-12 relative w-[90%] mx-auto shadow-lg rounded-lg md:w-[450px] ${
          isAdminRoute ? "bg-white" : "bg-lightOrange"
        } py-5 px-8`}
      >
        <img
          src={Ellipse3}
          alt="ellipse"
          className="w-[70px] h-[40px] top-0 right-0 absolute"
        />
        <FormHeader
          text="Email Verification"
          className={`${
            isAdminRoute ? "text-customBlue" : "text-primary"
          } font-bold text-2xl mt-8`}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={verifyEmail}
        >
          {() => (
            <Form>
              <Input
                label={"Email"}
                name={"email"}
                type="email"
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <Input
                label={"Otp"}
                name={"otp"}
                type="text"
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <div className="mt-8">
                <button
                  type="submit"
                  id="submit-btn"
                  className={`w-[40%] block mx-auto font-semibold ${
                    isAdminRoute ? "bg-customBlue" : "bg-primary"
                  } text-white py-3 rounded-lg text-xs`}
                >
                  Verify
                </button>
                <div className="mt-5 text-xs pl-8 flex justify-between items-center w-full">
                  <Link
                    className={`text-xs ${
                      isAdminRoute
                        ? "underline text-customBlue"
                        : "text-primary"
                    } font-semibold`}
                    to="/"
                  >
                    Go Back
                  </Link>
                  <button
                    type="button"
                    onClick={resendVerificationEmail}
                    className={`w-fit  font-semibold ${
                      isAdminRoute ? "bg-primary" : " bg-customBlue"
                    } text-white p-3 rounded-lg text-xs`}
                  >
                    Resend mail
                  </button>
                </div>
              </div>
              <img
                src={Ellipse1}
                alt="ellipse"
                className="w-[70px] h-[40px] bottom-[1px] left-0 absolute"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EmailVerification;
