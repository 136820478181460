
import React from 'react'
import { Routes, Route } from "react-router";
import AdminManagement from './AdminManagement';

const AdminMangementRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminManagement />} />
    </Routes>
  )
}

export default AdminMangementRoute