import React from "react";
import { Route, Routes } from "react-router";
import Rewards from "./Rewards";
import ViewReward from "./ViewReward";
import ViewCategory from "./ViewCategory";
import { RewardsProvider } from "../../../../context/rewardContext";

const RewardRoutes = () => {
  return (
    <RewardsProvider>
      <div className=" p-5">
        <Routes>
          <Route path="/" element={<Rewards />} />
          <Route path="/:id" element={<ViewReward />} />
          <Route path="/category/:id" element={<ViewCategory />} />
        </Routes>
      </div>
    </RewardsProvider>
  );
};

export default RewardRoutes;
