import React, { useState } from "react";

import { api } from "../../../../api/admin-management";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddAppModal from "./AddAppModal";


const AddNewRole = () => {
  const navigate = useNavigate();
  const [selectedApps, setSelectedApps] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = JSON.parse(window.sessionStorage.getItem("user"));
  const orgId = user?.org?.id;
  const accountType = user?.role?.slug;
  const isClientAdmin = accountType === "client-admin";

  const [activeTab, setActiveTab] = useState("Courses"); // Default active tab
  const [selectedPrivileges, setSelectedPrivileges] = useState([]); // To store selected privilege IDs

  
  // Function to toggle privilege selection
  const togglePrivilege = (privilegeId) => {
    setSelectedPrivileges((prevSelected) =>
      prevSelected.includes(privilegeId)
        ? prevSelected.filter((id) => id !== privilegeId)
        : [...prevSelected, privilegeId]
    );
  };

  const initialValues = {
    name: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name field is required"),
    description: Yup.string().required("Description field is required"),
  });


  const handleDeleteApp = (appId) => {
    setSelectedApps((prevApps) => prevApps.filter((app) => app.id !== appId));
  };

  const toggleSelectAll = (subAppId, selectAll) => {
    setSelectedPrivileges((prevSelected) => {
      const subApp = selectedApps
        .flatMap((app) => app.moduleSubApps)
        .find((sub) => sub.id === subAppId);
      const subAppPrivilegeIds = subApp.moduleSubAppPrivileges.map((p) => p.id);

      if (selectAll) {
        // Select all privileges in the sub-app
        return [...new Set([...prevSelected, ...subAppPrivilegeIds])];
      } else {
        // Deselect all privileges in the sub-app
        return prevSelected.filter((id) => !subAppPrivilegeIds.includes(id));
      }
    });
  };

  const isAllSelected = (subAppId) => {
    const subApp = selectedApps
      .flatMap((app) => app.moduleSubApps)
      .find((sub) => sub.id === subAppId);
    return subApp.moduleSubAppPrivileges.every((privilege) =>
      selectedPrivileges.includes(privilege.id)
    );
  };

  const handleCreate = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await api.createRole({
        name: values.name,
        description: values.description,
        privileges: selectedPrivileges,
        ...(isClientAdmin ? { orgId: orgId } : {}),
      });
      toast.success(response.data.message);
      navigate("/app/admin/settings/role-and-privileges");
    } catch (error) {
      toast.warning(error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="p-4 md:p-6">
      <div className="flex justify-between items-center mb-6">
      <h3 className="text-xl font-semibold text-customBlue mb-3">New Role</h3>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className={`flex items-center justify-center gap-1  font-semibold 
                       bg-primary
                     text-white py-2 px-6 rounded-lg text-sm`}
        >
          <i className="bi bi-arrow-left text-white "></i>
          Back
        </button>        
        
      </div>
      <div className="">
        <h3 className="text-xl font-medium text-customBlue mb-3">
          Role Details
        </h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleCreate}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="flex gap-6">
                <div className="flex flex-col mb-4  w-full">
                  <label
                    htmlFor="name"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Role Name
                  </label>
                  <div className="flex relative">
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="input-style bg-primaryGray"
                      placeholder="Enter Name"
                    />
                  </div>
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="error-style"
                  />
                </div>
                <div className="flex flex-col mb-4 w-full">
                  <label
                    htmlFor="description"
                    className="mb-1 text-base font-medium text-gray600"
                  >
                    Role Description
                  </label>
                  <div className="flex relative">
                    <Field
                      type="text"
                      id="description"
                      name="description"
                      className="input-style bg-primaryGray"
                      placeholder="Enter Description"
                    />
                  </div>
                  <ErrorMessage
                    name="description"
                    component="p"
                    className="error-style"
                  />
                </div>
              </div>

              <div className="shadow-lg rounded-2xl bg-white p-4 mb-4">
                <h4 className="text-xl font-semibold text-customBlue">
                  Access Control
                </h4>
                <div className="flex justify-between items-center mb-2">
                  <p className="text-gray-500">
                    Select which apps and associated actions/forms to grant
                    access to
                  </p>
                  <span
                    onClick={() => setIsModalOpen(true)}
                    className="text-primary font-normal text-lg cursor-pointer"
                  >
                    <i class="bi bi-patch-plus "></i> Add Apps
                  </span>
                </div>
                <p className="text-gray-300 text-sm font-normal">APPS</p>
                {selectedApps.length > 0 ? (
                  <div>
                    {/* List of selected apps */}
                    {selectedApps.map((app) => (
                      <div
                        key={app.id}
                        className="border rounded-lg bg-white shadow-md p-4 space-y-4 mb-6"
                      >
                        {/* App Header with Delete Button */}
                        <div className="flex justify-between items-center">
                          <div className="ml-4">
                            <h3 className="text-lg font-medium">{app.name}</h3>
                          </div>
                          <button
                            onClick={() => handleDeleteApp(app.id)}
                            className="  hover:text-red-700"
                          >
                            <span className="text-red-500">🗑️</span>
                          </button>
                        </div>

                        {/* Tabs Navigation */}
                        <div className="relative">
                          <div
                            id="tab-container"
                            className="overflow-x-auto flex space-x-4 border-b-2 pb-1 whitespace-nowrap"
                          >
                            {app.moduleSubApps.map((subApp) => (
                              <div
                                key={subApp.id}
                                className={`inline-block cursor-pointer px-4 py-2 ${
                                  activeTab === subApp.name
                                    ? "text-primary border-b-4 border-primary"
                                    : "text-gray-700"
                                }`}
                                onClick={() => setActiveTab(subApp.name)}
                              >
                                {subApp.name}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="text-gray-400 mb-4 font-semibold text-sm">ACTIONS, PRIVILEGES AND FORMS</div>

                        {/* Privileges */}
                        {app.moduleSubApps
                          .filter((subApp) => subApp.name === activeTab)
                          .map((filteredSubApp) => (
                            <div key={filteredSubApp.id}>
                              <div className="flex items-center mb-6 gap-x-4">
                                <input
                                  type="checkbox"
                                  checked={isAllSelected(filteredSubApp.id)}
                                  onChange={(e) =>
                                    toggleSelectAll(
                                      filteredSubApp.id,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label className="">
                                  Grant Access to All Privileges in this sub-app
                                </label>
                              </div>

                              {filteredSubApp.moduleSubAppPrivileges.map(
                                (privilege) => (
                                  <div
                                    key={privilege.id}
                                    className="flex items-center gap-x-4"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedPrivileges.includes(
                                        privilege.id
                                      )}
                                      onChange={() =>
                                        togglePrivilege(privilege.id)
                                      }
                                    />
                                    <label>{privilege.name}</label>
                                  </div>
                                )
                              )}
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="shadow-md rounded-md bg-white p-10">
                    <p>
                      Create a new role and assign app-specific privileges. You
                      can always return at any time to edit the privileges of
                      any created roles.
                    </p>
                  </div>
                )}
              </div>

              <div className="flex gap-x-6">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-primaryBlue hover:opacity-50 text-white  mb-4  rounded-md cursor-pointer font-bold py-3 px-6"
                >
                  {isSubmitting ? (
                    <div className="flex justify-center">
                      <ClipLoader color="#fff" />
                    </div>
                  ) : (
                    "Create"
                  )}
                </button>
                <button
                  onClick={() => navigate(-1)}
                  type="button"
                  className="bg-primary hover:opacity-50 text-white  mb-4  rounded-md cursor-pointer font-bold py-3 px-6"
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <AddAppModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedApps={selectedApps}
          setSelectedApps={setSelectedApps}
        />
      </div>
    </div>
  );
};

export default AddNewRole;
