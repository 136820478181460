import React, { useEffect, useState } from 'react'
import { api as clientsApi } from '../../../../../../api/clients'; 
import { useQuery } from 'react-query';
import EmptyPlaceHolder from '../../../../../../components/EmptyPlaceHolder';
import EmptyData from '../../../../../../components/EmptyData';
import Table from '../../../../../../components/table/Table';
import Loader from '../../../../../../components/loader/Loader';
import Pagination from '../../../../../../components/pagination/Pagination';
import { useNavigate } from 'react-router';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Summary from './components/Summary';

const ViewClients: React.FC = () => {

  const key : string = 'view-clients'
  const [perPage, setPerPage] = useState<number>(15);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate()
  const [ showCreateClientModal, setShowCreateClientModal ] = useState<boolean>(false)

  const { data: clientsSummary} = useQuery(
    ['get client summary'],
    () => { 
        return clientsApi.getClientsSummary();
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        select: res => res?.data
  });

  const { data: clients, isLoading, refetch } = useQuery(
    [key, { perPage, currentPage, search, }],
    ({queryKey}: any) => { 
        return clientsApi.getClients(queryKey[1]);
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
  });



  useEffect( () : void => {
    refetch({queryKey: [key, { perPage, currentPage, search, }]})
  }, [search, currentPage, perPage] )

  let columns = React.useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index } : { index: number }) =>
          index +
          1 +
          ((clients?.meta?.page || 1) - 1) * (clients?.meta?.perPage || perPage ),
      },
      {
        title: "Client Name",
        selector: "name",
        key: "name",
        render: ({ item, row } : any) => {                      
             return <span>
                 {item}
             </span>
         },
      },
      {
        title: "Email",
        selector: "email",
        key: "email",
        render: ({ item } : any) => {           
          return <span className="">
             {item || <EmptyPlaceHolder count={10} /> }
          </span>;
        },
      },
      {
        title: "Phone Number",
        selector: "phoneNumber",
        key: "phoneNumber",
        render: ({ item } : any) => {           
          return <span className="">
             {item || <EmptyPlaceHolder />}
          </span>;
        },
      },
      {
        title: "Payment Type",
        selector: "paymentType",
        key: "paymentType",
        render: ({ item }: any) => {           
          return <span 
                  className={item === 'pre-paid' ? 'text-primary flex border border-primary py-1 w-[80px] justify-center rounded-full'
                  : 'text-primary-green border border-primary-green flex w-[80px] justify-center px-2 py-1 rounded-full'}
                 >
                     {item}
                 </span>;
        },
      },
      {
        title: "Business Type",
        selector: "type",
        key: "type",
        render: ({ item } : any) => {           
          return <span className="">
             {item || <EmptyPlaceHolder />}
          </span>;
        },
      },
      {
        title: "Business Size",
        selector: "size",
        key: "size",
        render: ({ item } : any) => {           
          return <span className="">
             {item || <EmptyPlaceHolder count={3} />}
          </span>;
        },
      },
      {
        title: "Date Added",
        selector: "createdAt",
        key: "createdAt",
        render: ({ item } : any) => {           
          return <span className="">
             {`${ new Date(item).toLocaleDateString('en-ng')}`}
          </span>;
        },
      },
      {
        title: "Action",
        selector: null,
        key: null,
        render: ({ row } : any) => {           
          return (
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                <button
                    className="text-sm bi bi-three-dots-vertical font-semibold" 
                    aria-label="Customise options" 
                 />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                <DropdownMenu.Item
                    className="DropdownMenuItem text-left  hover:bg-gray-200 text-primary-blue"
                >
                    <i className='bi bi-pencil-square pr-1' /> Edit Profile
                </DropdownMenu.Item>
                <DropdownMenu.Separator className="DropdownMenuSeparator" />
                <DropdownMenu.Item 
                    className="DropdownMenuItem text-left hover:bg-gray-200 text-red-500"
                    onClick={ () => navigate('/') }
                >
                    <i className='bi bi-box-arrow-in-left pr-1' /> Deactivate Account
                </DropdownMenu.Item>
                <DropdownMenu.Separator className="DropdownMenuSeparator hidden" />
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
          )
        },
      },
    ], [1, 20, 20])
 
    const tableProps = { isLoading, columns, data: clients?.data }

   const createClient = () => {
     navigate('/admin/clients/create')
   }

  return (
    <div className='p-5'>  
       <section>
         <Summary clientSummary={clientsSummary} />
       </section>
       <header className='flex mt-5 justify-between items-center'>
           <h6 className='text-lg font-semibold'>
              Clients Overview
           </h6>
           <div className='flex items-center'>
               <input 
                   type="search" 
                   className='input-style bg-primaryGray w-[300px]'
                   placeholder='&#128269; Search clients....'
                   onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                     setSearch(event.target.value)
                     refetch({ queryKey: [key, { perPage, currentPage, search: event.target.value } ]})
                   }}
                />
                <button 
                  className='btn pt-2 pb-2 bg-primary-blue flex ml-5 items-center'
                  onClick={createClient}
                >
                   <i className='bi bi-plus pr-1 text-lg' /> Create Client Profile
                </button>
           </div>
       </header>
       <section className='mt-5'>
           {
            isLoading ? <Loader /> :
           clients?.data?.length ? 
               <div className='bg-white rounded-lg p-3'>
                   <Table {...tableProps} />
                   <Pagination
                      data={clients?.meta}
                      perPage={perPage}
                      pageNo={currentPage}
                      setPageNo={setCurrentPage}
                      setPerPage={setPerPage}
                    />
               </div>
            :
             <div className='pt-8'>
                <EmptyData 
                    text={'There is no any available client yet.'}
                />
           </div>
           }
       </section>
    </div>
  )
}

export default ViewClients