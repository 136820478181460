import React from 'react'
import BadgeIcon from '../../../../../../../assets/Badge.png';
import BadgeIconOrange from '../../../../../../../assets/Bagde (5).png';
import BadgeIconGreen from '../../../../../../../assets/Badge (1).png';
import OverviewCard from '../../../../../../../components/Cards/OverviewCards';

type SummaryType = {
    clientSummary: any
}

const Summary: React.FC<SummaryType> = ({clientSummary}) => {
  return (
    <div className='grid grid-cols-1 pt-2 pb-4 gap-7 md:grid-cols-3'>
        <OverviewCard 
           title='Total Clients'
           total={clientSummary?.totalOrganizations}
           iconPath={BadgeIcon}
        />
        <OverviewCard 
           title='Pre Paid Clients'
           total={clientSummary?.prePaidOrganizations}
           iconPath={BadgeIconOrange}
        />
        <OverviewCard 
           title='Post Paid Clients'
           total={clientSummary?.postPaidOrganizations}
           iconPath={BadgeIconGreen}
        />
    </div>
  )
}

export default Summary