import React from 'react'

const EmptyPlaceHolder: React.FC<{count?: number}> = ({ count = 5 }) => {
  return (
    <span>
        {
          Array.from({ length: count }, (_, index: number) : number => index + 1)
          .map( (item) => <i key={item} className='bi bi-dash-lg' /> )
        }
    </span>
  )
}

export default EmptyPlaceHolder