import createApiClient from "./api";

const client = createApiClient();

export const api = {
    createAdmin: (payload) => client.post(`admin/admins`, payload),

    updateAdmin: ({ adminId, payload }) =>
        client
            .patch(`/admin/admins/${adminId}`, payload)
            .then(({ data }) => data),

    getAllAdmins: ({ orgId, search, perPage, currentPage }) =>
        client
            .get(
                `admin/admins?${orgId ? `orgId=${orgId}&` : ""}search=${search}&perPage=${perPage}&page=${currentPage}`
            )
            .then(({ data }) => data),

    deleteAdmin: ({ adminId }) =>
        client
            .delete(`/admin/admins/${adminId}`)
            .then(({ data }) => data),

    getAllRoles: ({ orgId, search, perPage, currentPage }) =>
        client
            .get(
                `admin/roles?${orgId ? `orgId=${orgId}&` : ""}search=${search}&perPage=${perPage}&page=${currentPage}`
            )
            .then(({ data }) => data),

    getAllModulesApps: () =>
        client
            .get(`admin/module-apps`)
            .then(({ data }) => data),

    createRole: (payload) => client.post('/admin/roles', payload),

    updateRole: ({ roleId, payload }) =>
        client
            .patch(`/admin/roles/${roleId}`, payload)
            .then(({ data }) => data),

    deleteRole: (roleId) =>
        client
            .delete(`/admin/roles/${roleId}`)
            .then(({ data }) => data),
};
