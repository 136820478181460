import React, { useEffect, useState } from 'react'
import addGroupImage from '../../../../../../assets/Add Group.png'
import { errorResponse } from '../../../../../../components/utils/errorResponse'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from 'react-query'
import { teams as teamsApi } from '../../../../../../app-api/teams'
import ButtonSelect from '../../../../../../components/Buttons/ButtunSelect'
import CustomDropdown from '../../../../../../components/dropdown/CustomDropdown'
import CreateTeams from './CreateTeams'
import Counter from '../../../../../../components/Counter'
import Switch from 'react-switch'

const AddTeams: React.FC<any> = ({
        setCurrentStep, teams, setTeams, team, setTeam, enableLeaderBoard, setValue,
        setEnableLeaderBoard , mapLearnerMethod, setMapLearnerMethod, cohort,
        increment, decrement, value,  cohortId, selectedTeams, setSelectedTeams
}) => {

  const [selectionType, setSelectionType] = useState<any>(false)
  const orgId = JSON.parse(sessionStorage.getItem('user')!)?.org?.id

  const { data, isLoading, } = useQuery(
   ["view teams"],
   () => {
     return teamsApi.getTeams({ perPage: 10000, currentPage:1, orgId });
   },
   {
     cacheTime: 100,
     staleTime: 100,
     refetchOnWindowFocus: false,
     refetchOnMount:false,
     select: (data: any) => data?.data,
   })

   useEffect( () : void => {
      type teamType = { name: string, id: string }
      if(cohort?.teams?.length){
         setTeam( cohort?.teams?.map( (team: teamType) => {
            return { label: team?.name, value: team?.id }
        } ) )
        setEnableLeaderBoard(cohort?.teams[0]?.leaderboardEnabled)
        setValue(cohort?.teams[0]?.numberOfParticipants)
      }
   }, [cohort])

   const addTeamsMutation : any = useMutation(teamsApi.createOrganisationTeams, {
      onSuccess: (data: any) => {
          toast.remove()
          toast.success('Teams Created successfully!')
          if(data?.data?.length) setSelectedTeams(data?.data)
      },
      onError: error => errorResponse(error),
      onMutate: () => {
          toast.loading('Loading...')
      }
    })

    const addLearnersToTeamsMutation: any = useMutation(teamsApi.assignOrganisationTeamsToCohort, {
      onSuccess: () => {
          toast.remove()
          toast.success('Teams Settings Applied successfully!')
          setCurrentStep(2)
      },
      onError: (error: any) => errorResponse(error),
      onMutate: () => {
          toast.loading('Loading...')
      }
    })

   const createTeams = () => {
      const hasEmptyName = teams.some( (team: any) =>  team.name === '');
      if(hasEmptyName){
        toast.error('Error! Complete Adding Name To Your Teams')
      }
      else {
        addTeamsMutation.mutate({ orgId, payload: {
            cohortId,
            teams: teams?.map( (team: any) => {
              return { name: team.name, leaderboardEnabled: enableLeaderBoard }
            })
         }})
      }
   }

   const addTeams = () => {

      if(!selectionType){
        if(!team?.length) toast.error('Error! Select teams to proceed')
        else{
         const payload = {
            orgId, payload: {
              teams: team?.map( (team: any) => {
               return {
                  id: team?.value, cohortId,
                  assignType: mapLearnerMethod === 1 ? "auto" : "manual",
                  numberOfParticipants: mapLearnerMethod === 1 ? value : null
              }
            })
           }
         }
         addLearnersToTeamsMutation.mutate(payload)
       }
      }
      else {
         if(!selectedTeams?.length) toast.error('Error! create teams to proceed.')
         else{
            const payload = {
               orgId, payload: {
                 teams: selectedTeams?.map( (team: any) => {
                  return {
                      id: team?.id,
                      assignType: mapLearnerMethod === 1 ? "auto" : "manual",
                      cohortId,
                      numberOfParticipants: mapLearnerMethod === 1 ? value : null
                  }
                })
             }
            }
          addLearnersToTeamsMutation.mutate(payload)
         }
      }
   }

  return (
    <>
       <section className='border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 mt-8'>
             <div className='w-[40%] py-7 flex items-center justify-center'>
                 <img src={addGroupImage} className='w-[260px] h-[260px]' alt="add group" />
             </div>
               <section className='w-[58%]'>
               <div className='rounded-e-lg bg-white p-5 px-8 h-full'>
                  <h5 className='text-xl font-semibold'>
                    Team Information
                  </h5>
                  <p className='text-xs font-semibold border-b border-gray-300 pb-2 mt-1 mb-3'>
                      Select from existing or create new teams.
                  </p>
             <div className='border-t border-gray-300 mt-3 pt-3'>
                <div className='mb-4 mt-2 flex items-center'>
                        <h3 className='flex text-sm items-center'>
                        <ButtonSelect 
                           text={'Select Existing'} 
                           active={selectionType === false}
                           onClick={ () => setSelectionType(false) }
                           width='w-[150px]'
                        /> 
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <ButtonSelect 
                           text={'Create Team'}
                           active={selectionType}
                           onClick={ () => {
                              setSelectionType(true) 
                           }}
                        />
                        </h3>
                </div>
                { selectionType === false ?
                 <CustomDropdown
                 options={  isLoading ? [] : 
                    data?.length ? data?.map( (team: any) => {
                      return { label: team?.team?.name , value: team?.team?.id }
                    } ) : []
                 }
                 value={team} 
                 placeholder={'Select Existing Team..'}
                 onChange={ (value: any) => {
                  setTeam(value)
                 }}
                 multiSelect                
                 onBlur={null}
                 name={'category'}
                 className={'bg-primaryGray select-custom'}
                 width={'w-full'}
               />
                  : <CreateTeams inputWidth={250} teams={teams} setTeams={setTeams} />
                 }
                 { selectionType && <div className='flex justify-end mt-2'>
                     <button
                       className='btn bg-primary-blue pl-12 pr-12'
                       onClick={createTeams}
                     > 
                       Create team
                     </button>
                  </div>}
                 <section className='mt-5'>
                     <h3 className='flex justify-between text-xs items-center'>
                        Enable Team In Leader Board 
                        <Switch
                            height={20}
                            width={40}
                            handleDiameter={18}
                            checked={enableLeaderBoard}
                            onChange={() => setEnableLeaderBoard(!enableLeaderBoard)}
                        />
                     </h3>
               </section>
               <h2 className='mt-3 mb-0 text-sm pt-2 border-t font-semibold border-slate-300'>Apply Settings</h2>
            <section>
             <p className='text-xs mb-3 text-gray-700'>Assign learners to team automatically in batches or manually</p>
             <h3 className='flex text-sm items-center'>
                <ButtonSelect 
                   text={'Automatic'} 
                   active={ mapLearnerMethod === 1}
                   onClick={ () => setMapLearnerMethod(1) }
                /> 
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <ButtonSelect 
                    text={'Manual'}
                    active={mapLearnerMethod === 2}
                    onClick={ () => {
                        setMapLearnerMethod(2)
                        //setOpenModal(true)
                    } }
                />
                {
                  mapLearnerMethod === 1 ? 
                  <>
                     <span className='text-xs ml-5 mr-3'>Batch Of</span>
                     <Counter value={value} increment={increment} decrement={decrement} />
                  </>
                  : null
                }
             </h3>
        </section>
                  <div className='flex mt-5 justify-end'>
                      <button className='btn bg-primary-blue pl-12 pr-12' onClick={ addTeams }>Add Teams To Cohort </button> 
                  </div>    
             </div>
                </div>
             </section>
         </section>
    </>
  )
}

export default AddTeams