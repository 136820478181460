import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { api } from "../../../../../api/reward";

const AddCategory = ({
  handleModal,
  hasRewards,
  handleRewardModal,
  refetch,
}) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { register, handleSubmit } = useForm();

  const { mutateAsync } = useMutation(api.createCategory);

  const handleCategory = async (payload) => {
    try {
      toast.loading("Processing...");
      const data = await mutateAsync({ id: user?.org?.id, payload });
      //   console.log(data);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        handleModal();
        refetch();
        if (!hasRewards) return handleRewardModal();
        // eslint-disable-next-line no-restricted-globals
        // location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit(handleCategory)}>
        <div className="w-full">
          <div className="flex-1 flex mb-7 items-start flex-col justify-center gap-1">
            <p className="text-sm">Name</p>
            <input
              className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
              type="text"
              {...register("name", {
                required: true,
              })}
            />
          </div>
          <div className="flex-1 flex items-start flex-col justify-center gap-1">
            <p className="text-sm">Description</p>
            <textarea
              className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
              rows={5}
              {...register("description", {
                required: true,
              })}
            />
          </div>
        </div>
        <div className="w-full flex items-center justify-start">
          <input
            type="submit"
            value="Submit"
            className="mt-5 text-sm w-full px-5 py-2 border border-customBlue cursor-pointer bg-customBlue text-white rounded duration-100"
          />
        </div>
      </form>
    </div>
  );
};

export default AddCategory;
