import React, { useEffect } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from 'react-router-dom';
import logo from "../../../../assets/logo-2.png";
import footerLogo from "../../../../assets/Content structure-bro 1.png"
import { isDarkColor } from '../../../../components/utils/themeDetector';

const SideBar = () => {

  const location = useLocation();
  const org: any = JSON.parse(sessionStorage.getItem('user')!)
  const isClient: boolean = org?.role?.slug === 'client-admin'
  const themes = sessionStorage.getItem('account-customization')

  return (
    <div style={{ backgroundColor: org?.orgAppearance?.themeColour || themes ?
        `${themes ? JSON.parse(themes)?.theme : org?.orgAppearance?.themeColour }`
      :
       "#0A3059" }} 
     className={` text-white relative shadow`}
     >  
        <Sidebar
          rootStyles={{
            height: "100vh",
            //backgroundColor: "#091F46",
            opacity: 1,
            paddingRight: 10,
            paddingLeft: 10
          }}
          width={'250px'}
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                return {
                  color: active ? "#FF9100 !important" :
                    org?.orgAppearance?.themeColour || themes ?
                     isDarkColor(
                      themes ? JSON.parse(themes)?.theme : org?.orgAppearance?.themeColour
                    ) ? '#FFFFFF !important' : '#222 !important'
                    : undefined,
                backgroundColor: active ? '#FFFFFF' : 'transparent',
                borderRadius: active ? '10px' : 0
              };
            },
          }}
        >
          <div className="border-b mb-4 border-gray-600 flex items-center pt-3 pb-2 px-3">
            <img
              src={
                themes ? JSON.parse(themes)?.logo :
                  org?.orgAppearance?.desktopLogo ? org?.orgAppearance?.desktopLogo : logo
              }
              alt="logo" className="w-[110px] h-[50px] pl-5"
            />
          </div>
          <MenuItem
            active={location.pathname.includes("/app/admin/dashboard")}
            icon={<span className="bi bi-grid text-2xl "></span>}
            component={<Link to={"/app/admin/dashboard"} />}
          >
            Dashboard
          </MenuItem>
          {!isClient &&
            <MenuItem
              active={location.pathname.includes("/app/admin/clients")}
              icon={<span className="bi bi-people text-2xl"></span>}
              component={<Link to={"/app/admin/clients"} />}
            >
              Clients Management
            </MenuItem>}
          {!isClient ?
            <SubMenu
                icon={<span className="bi bi-mortarboard text-2xl"></span>}
                label="Course Management"
                className=''
            >
              <MenuItem
                active={location.pathname.includes("/app/admin/courses/")}
                component={<Link to={"/app/admin/courses/"} />}
              >
                <i className="bi bi-mortarboard"></i> <span className="text-xs">Courses</span>
              </MenuItem>
              <MenuItem
                active={location.pathname.includes("/app/admin/modules/")}
                component={<Link to={"/app/admin/modules/"} />}
              >
                <i className="bi bi-box-seam"></i> <span className="text-xs">Modules</span>
              </MenuItem>
            </SubMenu>
            : null}
          {isClient ?
            <SubMenu
                icon={<span className="bi bi-people text-2xl"></span>}
                label="Cohort Management"
                className=''
            >
              <MenuItem
                active={location.pathname.includes("/app/admin/cohort/")}
                component={<Link to={"/app/admin/cohort/"} />}
              >
                <i className="bi bi-people"></i> <span className="text-xs">
                  Cohort
                </span>
              </MenuItem>
            </SubMenu>
            : null}
          {isClient ?
            <MenuItem
              active={location.pathname.includes("/app/admin/learning-path/")}
              component={<Link to={"/app/admin/learning-path/"} />}
              className='text-2xl'
              icon={<i className="bi text-2xl bi-graph-up-arrow"></i>}
            >
              <span className="">
                Learning Path
              </span>
            </MenuItem>
            : null}

          {isClient &&
            <SubMenu
              icon={<span className="bi bi-megaphone text-2xl"></span>}
              label="Communication"
              className=''
            >
              <MenuItem
                active={location.pathname.includes("/app/admin/zoom")}
                component={<Link to={"/app/admin/zoom"} />}
              >
                <i className="bi bi-camera-reels"></i> <span className="text-xs">Zoom</span>
              </MenuItem>
              <MenuItem
                active={location.pathname.includes("/app/admin/email")}
                component={<Link to={"/app/admin/email"} />}
              >
                <i className="bi bi-envelope-arrow-down"></i> <span className="text-xs">Email</span>
              </MenuItem>
            </SubMenu>
          }

          {isClient &&
            <MenuItem
              active={location.pathname.includes("/app/admin/certificate")}
              icon={<span className="bi bi-gear text-2xl "></span>}
              component={<Link to={"/app/admin/certificate"} />}
            >
              Certificates
            </MenuItem>}

          <MenuItem
            active={location.pathname.includes("/app/admin/rewards")}
            icon={<span className="bi bi-trophy text-2xl"></span>}
            component={<Link to={"/app/admin/rewards"} />}
          >
            Rewards
          </MenuItem>
          {isClient &&
            <MenuItem
              active={location.pathname.includes("/app/admin/settings")}
              icon={<span className="bi bi-gear text-2xl "></span>}
              component={<Link to={"/app/admin/settings"} />}
            >
              Settings
            </MenuItem>}
        </Menu>
        <div className='mt- absolute bottom-0 left-[30px]'>
          <img src={footerLogo} alt="footer logo" className='h-[120px] w-[120px] block mx-auto' />
        </div>
      </Sidebar>
    </div>
  )
}

export default SideBar