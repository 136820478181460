import React from 'react'
import DataCard from './DataCard'

export const weeks = [1,2,3,4,5,6,7,8,9,10,11,12]

const CourseCompletionRatePerWeekChart = ({chartData}) => {

  return (
    <div className='w-full mt-3 overflow-x-auto pb-5'>
      <p className='text-sm font-light mb-3'> 
         This metrics tracks the percentage of learners in each cohort who completes a 
         course each week, it helps understand how progress over time.
         <br />
          <strong>Insight:</strong> this reveals if certain cohorts are progressing more slowly or faster through
         the learning path.
      </p>
        <section className='flex'>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2'> Cohort</h5>
                <ul>
                   {chartData?.map( data => {
                     return <li className='whitespace-nowrap border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.cohortName}
                      </li>
                   } )}
                </ul>
            </div>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2 whitespace-nowrap'> Learning Path </h5>
                <ul>
                   {chartData?.map( (data, index) => {
                     return (<li key={index} className='whitespace-nowrap border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.journeyName}
                      </li>)
                   } )}
                </ul>
            </div>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2 whitespace-nowrap'> Learners </h5>
                <ul>
                   {chartData?.map( (data, index) => {
                     return (<li key={index} className='whitespace-nowrap border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.numberOfLearners}
                      </li>)
                   } )}
                </ul>
            </div>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2 whitespace-nowrap'> Weeks </h5>
                <ul>
                   {chartData?.map( (data, index) => {
                     return (<li key={index} className='whitespace-nowrap border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.numberOfWeeks}
                      </li>)
                   } )}
                </ul>
            </div>
            <div>
               <header className='flex pt-3 items-center border-t border-gray-200'>
                  {weeks.map( label => {
                     return <span className='text-[11px] font-medium text-center mr-1 w-[62px]'>
                        {`Week ${label}`}
                     </span>
                  } )}
               </header>
               <section className='mt-3'>
                  {chartData?.map( data => {
                     return <div className='flex items-center justify-between'>
                           <div className='flex w-[calc(100%-170px)]'>
                              { data?.data?.length ?
                                data?.data?.map( data => {
                                 return <DataCard isPercentage value={data?.completedCount} />
                              }) : <p className='text-xs px-2 py-3 bg-gray-200'> There is no any completion for this cohort </p>
                           }
                           </div>
                     </div>
                  })}
               </section>
            </div>
        </section>
    </div>
  )
}

export default CourseCompletionRatePerWeekChart