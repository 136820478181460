import React, { useState } from 'react'
import StarterPage from '../../../../../components/StarterPage'
import { useQuery } from 'react-query'
import { learningPath } from '../../../../../app-api/learningPath'
import { Link } from 'react-router-dom'
import CohortsTable from './components/CohortsTable'
import Summary from './components/Summary'
import Pagination from '../../../../../components/pagination/Pagination'

const ViewCohort = () => {

  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)

  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const orgId = JSON.parse(sessionStorage.getItem('user')!)?.org?.id

  const { data: cohorts, isLoading, refetch } = useQuery(
    ["cohort",  orgId, search, perPage, currentPage ],
    () => {
      return learningPath.getCohort({ id: orgId, search, perPage, currentPage });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
      onSuccess: (response: any) => {
            if(response?.meta?.total) {
              setIsEmpty(false)
              setTouched(true)
          }
          else setIsEmpty(true)
        }
    }
  );


  return (
    <div className='p-5'>
      <h5 className='flex justify-between mb-3 items-center text-lg font-semibold'>
            Cohort Overview
            <Link to={'/app/admin/cohort/create'} 
              className='btn bg-primary-blue ml-5'
              onClick={ () => sessionStorage.removeItem('active-cohort') }
            >
                Create New Cohort
            </Link>
        </h5>
        <Summary />
        {
           (!isEmpty || touched) ?
              <section>
                 <header className='flex justify-between items-center mt-1 mb-5'>
                       <h5 className='text-sm pt-2 font-semibold'>
                         Total Cohorts  ({cohorts?.meta?.total})
                       </h5>
                        <div className='flex items-center'>
                          <input 
                            type="search" 
                            className='input-style bg-primaryGray w-[400px] mt-4'
                            placeholder='&#128269; Search modules....'
                            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                              setSearch(event.target.value)
                            }}
                          />
                        </div>
                    </header>
                    <CohortsTable
                      cohorts={cohorts}
                      currentPage={currentPage}
                      perPage={perPage}
                      isLoading={isLoading}
                      search={search}
                      setSearch={setSearch}
                      setCurrentPage={setCurrentPage}
                      refetch={refetch}
                      setPerPage={setPerPage}
                    />
                    <Pagination
                      data={cohorts?.meta}
                      perPage={perPage}
                      pageNo={currentPage}
                      setPageNo={setCurrentPage}
                      setPerPage={setPerPage}
                  />
              </section> :
             <StarterPage 
                header='Create Your First Cohort'
                text='Create cohort, team, add learner and learning path'
                to='/app/admin/cohort/create'
                linkText='Create Cohort'
            /> 
         }
    </div>
  )
}

export default ViewCohort