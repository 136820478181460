import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { api as auth } from "../../../api";

const LoginForm = () => {
  
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    password: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({    
    password: Yup.string().required("Password is required"),
    email: Yup.string().email('Error! invalid email address')
    .required("Please enter your password again"),
  });

  const handleLogin = async (values, { setSubmitting }) => {
      setSubmitting(true);
     try {
        const response = await auth.login({
          email: values.email,
          password: values.password,
          accountType: 'admin'
        });
        toast.success('Logged in Successfully!');
        sessionStorage.setItem("user", JSON.stringify(response));
        if(response?.orgProfileCompletionStatus || response?.role?.slug !== "client-admin"){
          // loging to dashboard
          navigate("/admin/dashboard")
        } else{
          navigate("/account-setup");
        }
      } catch (error) {
        toast.warning(error.response.data.message);
      } finally {
        setSubmitting(false);
      }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleLogin}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="w-full grid place-content-center bg-white">
            <div className="flex  flex-col  w-full  overflow-hidden">
              <div className="mb-6">
                <p className="text-gray600 text-[28px] sm:text-3xl font-bold mb-2">
                  Log In
                </p>
                <p className="text-gray400 font-medium text-sm">
                  Choose your password to log into your account
                </p>
              </div>
            </div>
            <div className="mb-4">
              
              {/* Email */}
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="email"
                  className="mb-1 text-sm font-medium text-gray600"
                >
                  Email Address
                </label>
                <div className="flex relative">
                  <Field
                    type={"email"}
                    id="email"
                    name="email"
                    className="input-style bg-primaryGray"
                    placeholder="Enter your email"
                  />
                </div>
                <ErrorMessage
                  name="email"
                  component="p"
                  className="error-style"
                />
              </div>

              <div className="flex mt-5 flex-col gap-2">
                <label
                  htmlFor="password"
                  className="mb-1 text-sm font-medium text-gray600"
                >
                  Password
                </label>
                <div className="flex relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="input-style bg-primaryGray"
                    placeholder="Enter your password"
                  />
                  <button
                    type="button"
                    aria-label="Toggle password visibility"
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-3 text-gray-500 cursor-pointer focus:outline-none"
                  >
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </button>
                </div>
                <ErrorMessage
                  name="password"
                  component="p"
                  className="error-style"
                />
              </div>
            </div>
            <div className="">
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-primaryBlue hover:opacity-50 text-white w-full mb-4  rounded-md cursor-pointer font-bold py-3 px-6"
              >
                {isSubmitting ? (
                  <div className="flex justify-center">
                    <BeatLoader color="#fff" />
                  </div>
                ) : (
                  "Log in"
                )}
              </button>
            </div>

            <div className="flex flex-col text-center   mt-4 w-full">
              <div className="text-sm text-[#8E8E8E]">
                By signing in, you have agreed to our
              </div>
              <Link to="#" className="ml-2 font-bold text-sm  ">
                <span className="text-primaryBlue text-sm font-medium">
                  Terms <span className="text-gray400">and</span> conditions
                </span>
              </Link>
            </div>
            <br />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
