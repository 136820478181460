import React from 'react';
import Loader from '../../../../../../../../components/loader/Loader';
import Table from '../../../../../../../../components/table/Table';
import DropDown from './TableDropdown';

const TeamsTable = ({teams, isLoading, perPage }) => {

    let columns = React.useMemo( () => [
        {
          title: "ID",
          selector: null,
          key: "id",
          render: ({ index }) =>
            index +
            1 +
            ((teams?.meta?.page || 1) - 1) * (teams?.meta?.perPage || perPage ),
        },
        {
            title: "Name",
            selector: "name",
            key: "name",
            render: ({ item, row }) => {                      
                 return( 
                    <span className="">{row?.team?.name}</span>
                 )
             },
          },
        {
          title: "Learners",
          selector: null,
          key: null,
          render: ({ row }) => {                      
               return( 
                  <span className="">{row?.numberOfParticipants}</span>
               )
           },
        },
        {
          title: "Cohort Enrolled",
          selector: null,
          key: null,
          render: ({ row }) => {                      
               return( 
                  <span className="">{row?.cohortName}</span>
               )
           },
        },
        {
          title: "Points",
          selector: "points",
          key: "points",
          render: ({ item }) => {                      
            return <span className="">{item}</span>;
          }
        },
        {
            title: "Rank",
            selector: "rank",
            key: "rank",
            render: ({ item }) => {                      
              return <span className="">{item}</span>;
            }
          },
        {
          title: "Date Created",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.team?.createdAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Last Updated",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.team?.updatedAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Action",
          selector: null,
          key: null,
          render: ({row}) => {           
            return <div > 
                <DropDown id={row?.team?.id} name={row?.team?.name} cohortId={row?.team?.cohortId} />
             </div>
          },
        },
      ],
      [1, 20, 20]
    );
    
    const tableProps = { isLoading, columns, data: teams?.data }
    
    if(isLoading) return <Loader />
    
      return (
        <div>
          <h5 className='text-xs border-b border-gray-200 pb-3 py-2'>Total Teams ({teams?.meta?.total}) </h5>
          <Table {...tableProps} /> 
        </div>
      )
}

export default TeamsTable