import React from 'react'
import SideBar from './components/layout/SideBar'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './pages/dashboard/Dashboard'
import Client from './pages/clients/Client'
import Navbar from './components/layout/Navbar'
import Courses from './pages/courses/Courses'
import Modules from './pages/modules/Modules'
import SettingsRoutes from './pages/settings/SettingsRoute'
import ZoomRoutes from './pages/zoom/ZoomRoutes'
import EmailEditorRoutes from './pages/email/EmailEditorRoutes'
import Cohorts from './pages/cohorts/Cohorts'
import LearningPath from './pages/learningPath/LearningPath'
import CertificateRoutes from './pages/certificate-editor/CertificateRoutes'



const Admin: React.FC = () : React.ReactElement => {

  const org: any = JSON.parse(sessionStorage.getItem('user')!)
  const themes = sessionStorage.getItem('account-customization')

  return (
    <div>
        <div className="flex justify-between overflow-y-hidden">
        <div className="w-[250px]">
            <SideBar />
        </div>
       <div
       style={{
         backgroundColor : themes ? JSON.parse(themes)?.bodyColor :
         org?.orgAppearance?.bodyBackgroundColour || "#F2F4F7"
       }}
       className="text-gray-900 h-[calc(100vh)] overflow-y-auto px-5 pt-2 w-[calc(100%-250px)]">
        <Navbar />
        <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/clients/*" element={<Client />} />
            <Route path="/courses/*" element={<Courses />} />
            <Route path="/modules/*" element={<Modules />} />
            <Route path="/zoom/*" element={<ZoomRoutes />} />
            <Route path="/email/*" element={<EmailEditorRoutes />} />
            <Route path="/certificate/*" element={<CertificateRoutes />} />
            <Route path="/cohort/*" element={<Cohorts />} />
            <Route path="/learning-path/*" element={<LearningPath />} />
            <Route path="/settings/*" element={<SettingsRoutes />} />
        </Routes>
       </div>
    </div>
   </div>
  )
}

export default Admin