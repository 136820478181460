import React, { useState } from "react";
import toast from "react-hot-toast";
import { api } from "../../../../../../../api/billing";
import { useQuery } from "react-query";
import { BeatLoader, ScaleLoader } from "react-spinners";
import SingleSelectDropdown from "../../../../../../Admin/components/SingleSelectDropdown";
import Search from "../../../../../../../components/Inputs/Search";

const typeOptions = [
  { label: "Buy Credit", value: "credit-quantity" },
  { label: "Buy Credit Bundle", value: "credit-bundles" },
  { label: "Buy Credit with course selection", value: "credit-calculator" },
];

const Bundle = () => {
  const [quantity, setQuantity] = useState(0);
  const [unitPrice, setUnitPrice] = useState(1.0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showType, setShowType] = useState("credit-quantity");
  const [search, setSearch] = useState("");
  const user = JSON.parse(window.sessionStorage.getItem("user"));

  const handleSelectedActivity = (option) => {
    setShowType(option.value);
  };

  const getNewPrice = async (newQuantity) => {
    const payload = {
      credit: newQuantity,
    };
    try {
      const response = await api.getBundleByCreditAmount(payload);
      const { minimumCredit, discount } = response.data;
      return { totalPrice: minimumCredit * discount };

      //   return response; // Assuming the response returns the new price
    } catch (error) {
      // console.error("Error fetching new price:", error);
      throw error;
    }
  };

  const handleIncrement = async () => {
    const newQuantity = quantity + 1;
    // const newUnitPrice = newQuantity > 10 ? 0.95 : 1.0;
    setQuantity(newQuantity);
    try {
      setIsLoading(true);
      const { totalPrice } = await getNewPrice(newQuantity);
      // setUnitPrice(unitPrice);
      setTotalPrice(totalPrice);
      // setUnitPrice(newPrice);
    } catch (error) {
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
    // setUnitPrice(newUnitPrice);
    // setTotalPrice(newQuantity * newUnitPrice);
  };

  const handleDecrement = async () => {
    const newQuantity = Math.max(0, quantity - 1); // Ensure quantity does not go below 0
    setQuantity(newQuantity);

    try {
      setIsLoading(true);
      const { totalPrice } = await getNewPrice(newQuantity);
      //   setUnitPrice(unitPrice);
      setTotalPrice(totalPrice);
    } catch (error) {
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
    // if (quantity > 0) {
    //   const newQuantity = quantity - 1;
    //   const newUnitPrice = newQuantity > 10 ? 0.95 : 1.0;
    //   setQuantity(newQuantity);
    //   setUnitPrice(newUnitPrice);
    //   setTotalPrice(newQuantity * newUnitPrice);
    // }
  };

  const handleSubmit = async () => {
    const payload = {
      id: user?.org?.id,
      data: {
        createdBy: user?.userId, // userId
        credit: quantity,
        amount: totalPrice,
        type: "online", //online
        category: "pre-paid", // post-paid
      },
    };
    setIsLoading(true);
    try {
      const response = await api.topUpCredit(payload);
    
      toast.success(response.message);
    } catch (error) {

      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const buyBundleCredit = async (bundle) => {
    const payload = {
      id: user?.org?.id,
      data: {
        createdBy: user?.userId, // userId
        credit: bundle.minimumCredit,
        amount: bundle.minimumCredit * bundle.discount,
        type: "offline", //online
        category: "pre-paid", // post-paid
      },
    };
    setIsLoading(true);
    try {
      const response = await api.topUpCredit(payload);

      toast.success(response.message);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const {
    data: creditBundles,
    isLoading: isLoadingCreditBundles,
    refetch,
  } = useQuery(
    ["credit-bundles"],
    async () => {
      const response = await api.getAllBundles();
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <div className="mt-6">
      <div className="flex justify-between mb-6">
        <div className="w-1/2">
          <h1 className="font-black text-xl text-black mb-2">
            Top Up Your Credits!
          </h1>
          <p className="font-medium text-base text-[#727A90] italic">
            We offer three convenient ways to purchase credits. Choose the
            method that works best for you:
          </p>
        </div>
        <div className="w-1/3 flex flex-col justify-end  ">
          <p className="text-primary texts-sm font-normal">Choose here</p>
          <SingleSelectDropdown
            options={typeOptions}
            onSelect={handleSelectedActivity}
            placeholder="Filter"
            placeholderColor="#A5A5A5"
          />
        </div>
      </div>
      <div className="min-w-full flex justify-center items-center pb-6">
        {showType === "credit-quantity" && (
          <div className="flex justify-center gap-10 items-center">
            <div className="card-container">
              <div className="flex gap-x-2 items-center mb-4">
                <i class="bi bi-calculator text-3xl text-[#0E316E]"></i>
                <h2 className="text-3xl font-medium text-black">Buy Credit</h2>
              </div>
              <p className="text-sm text-[#727A90] font-normal">
                Fill in credit quantity required
              </p>
              <div className="flex gap-x-6 mt-6 items-center">
                <div className="flex flex-col">
                  <h5>Credit</h5>
                  <div className="inline-flex items-center mt-2">
                    <button
                      type="button"
                      onClick={handleDecrement}
                      className="bg-white  border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center py-4 px-2 border-customBlue"
                    >
                      <i class="bi bi-dash"></i>
                    </button>
                    <div className="border border-customBlue bg-white text-gray-600  inline-flex items-center p-4 select-none">
                      {quantity}
                    </div>
                    <button
                      type="button"
                      onClick={handleIncrement}
                      className="bg-white  border text-gray-600
                        hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50
                        inline-flex items-center py-4 px-2  border-customBlue"
                    >
                      <i class="bi bi-plus"></i>
                    </button>
                  </div>
                </div>

                <div className="flex flex-col w-fit">
                  <h5>Price</h5>
                  <div className="p-3 2xl:p-4 border border-[#ABAAB2] bg-[#F2F3F7]  text-[#1D1F2C] text-xl font-semibold rounded">
                    ${unitPrice.toFixed(2)}
                  </div>
                  <div>
                    {unitPrice < 1.0 && (
                      <span className="line-through text-red-500">$1.00</span>
                    )}
                  </div>
                </div>
                <div className="flex flex-col">
                  <h5>Total</h5>
                  <div className="p-3 2xl:p-4 border border-[#ABAAB2] bg-[#F2F3F7]  text-[#1D1F2C] text-xl font-semibold rounded">
                    ${totalPrice.toFixed(2)}
                  </div>
                </div>
                <div className="mt-6">
                  <button
                    onClick={handleSubmit}
                    className=" bg-customBlue text-white py-2 px-8 rounded-lg  flex justify-center items-center font-medium text-lg"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex justify-center">
                        <BeatLoader color="#fff" size={10} />
                      </div>
                    ) : (
                      "Pay"
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold text-lg text-black mb-2">
                Buy Credit by Inputting Quantity:
              </h2>
              <p className="font-medium text-base text-[#727A90] italic">
                Manually enter the number of credits you need. This method is
                perfect for precise purchases. Simply adjust the quantity using
                the plus (+) and minus (-) buttons, and the total price will be
                calculated automatically based on the unit price. When you're
                ready, click "Pay" to complete your purchase.
              </p>
            </div>
          </div>
        )}
        {showType === "credit-bundles" && (
          <div className="flex justify-center gap-10 items-start">
            <div className="card-container w-fit p-4">
              <div className="flex gap-x-2 items-center mb-4">
                <i class="bi bi-calculator text-3xl text-[#0E316E]"></i>
                <h2 className="text-3xl font-medium text-black">
                  Buy Credit Bundles
                </h2>
              </div>
              <p className="text-sm text-[#727A90] font-normal">
                Select a bundle
              </p>
              {isLoadingCreditBundles ? (
                <div className="mt-10 flex justify-center items-center">
                  <ScaleLoader color="#FB923C" />
                </div>
              ) : (
                <div className="my-6">
                  <table className=" divide-y-2 divide-gray-200 bg-white ">
                    <thead className="bg-white text-[#05182D] font-bold text-lg">
                      <tr className="">
                        <th className=" px-4 py-2">Credit Bundle</th>
                        <th className=" px-4 py-2">Price</th>
                        <th className=" px-4 py-2"></th>
                      </tr>
                    </thead>

                    <tbody className="text-xs rounded-xl border border-[#E0E2E7] shadow p-3">
                      {creditBundles?.length > 0 ? (
                        creditBundles.map((bundle, index) => (
                          <tr
                            className="rounded-xl border border-[#E0E2E7] shadow p-3"
                            key={index}
                          >
                            <td className=" px-4 py-2 text-gray-700 ">
                              {bundle?.minimumCredit} Credits
                            </td>
                            <td className=" px-4 py-2 text-gray-700 ">
                              $ {bundle.minimumCredit * bundle.discount}
                            </td>
                            <td className=" px-4 py-2 text-gray-700 ">
                              <button
                                onClick={() => buyBundleCredit(bundle)}
                                className="bg-customBlue text-white py-1.5 px-4 rounded-lg  flex justify-center items-center font-medium text-sm"
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <div className="flex justify-center">
                                    <BeatLoader color="#fff" size={10} />
                                  </div>
                                ) : (
                                  "Buy"
                                )}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="table-data" colSpan="12">
                            <p className="text-center">No Data Available</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold text-xl text-black mb-2">
                Buy Credit Bundles:
              </h2>
              <p className="font-medium text-base text-[#727A90] italic">
                Select from our pre-set credit bundles for a quick and easy
                top-up. This option allows you to choose a bundle that suits
                your needs without manually entering the quantity.
              </p>
            </div>
          </div>
        )}
        {showType === "credit-calculator" && (
          <div className="flex justify-center gap-10 items-start">
            <div className="card-container w-fit">
              <div className="flex gap-x-2 items-center mb-4 px-8">
                <i class="bi bi-calculator text-3xl text-[#0E316E]"></i>
                <h2 className="text-3xl font-medium text-black">
                  Credit Calculator
                </h2>
              </div>
              <p className="text-sm text-[#727A90] font-normal">
                Estimate credit requirements for course enrollments
              </p>

              <div className="">
                <div className="flex gap-x-10 items-center mt-6 mb-4">
                  <div className="flex-col">
                    <p className="text-sm text-[#727A90] font-normal">
                      Search for Course or Learning Path
                    </p>
                    <Search
                      onChange={(event) => setSearch(event.target.value)}
                      placeholder={"Search for courses"}
                      className={"w-[300px] border-[#9EA6B8] rounded-md py-8"}
                      showButton={false}
                      iconColor="text-blue-500"
                    />
                  </div>
                  <div className="flex-col">
                    <p className="text-sm text-[#727A90] font-normal">
                      Credit required per learner
                    </p>
                    <div className="p-3 2xl:p-4 border border-[#ABAAB2] bg-[#F2F3F7]  text-[#1D1F2C] text-xl font-semibold rounded">
                      100 Credits
                    </div>
                  </div>
                </div>
                <div className="flex gap-x-20 items-center">
                  <div className="inline-flex items-center mt-2">
                    <button
                      type="button"
                      onClick={handleDecrement}
                      className="bg-white  border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center py-4 px-2 border-customBlue"
                    >
                      <i class="bi bi-dash"></i>
                    </button>
                    <div className="border border-customBlue bg-white text-gray-600  inline-flex items-center p-4 select-none">
                      {quantity}
                    </div>
                    <button
                      type="button"
                      onClick={handleIncrement}
                      className="bg-white  border text-gray-600
                        hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50
                        inline-flex items-center py-4 px-2  border-customBlue"
                    >
                      <i class="bi bi-plus"></i>
                    </button>
                  </div>
                  <div className="">
                    <button
                      onClick={handleSubmit}
                      className=" bg-primary shadow shadow-[#05152F80] text-white py-2 px-8 rounded-lg  flex justify-center items-center font-medium text-lg"
                    >
                      Calculate
                    </button>
                  </div>
                </div>
                <div className="flex gap-x-4 mt-6">
                  <div className="inline-flex items-center mt-2">
                    <button
                      type="button"
                      onClick={handleDecrement}
                      className="bg-white  border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center py-4 px-2 border-customBlue"
                    >
                      <i class="bi bi-dash"></i>
                    </button>
                    <div className="border border-customBlue bg-white text-gray-600  inline-flex items-center p-4 select-none">
                      {quantity}
                    </div>
                    <button
                      type="button"
                      onClick={handleIncrement}
                      className="bg-white  border text-gray-600
                        hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50
                        inline-flex items-center py-4 px-2  border-customBlue"
                    >
                      <i class="bi bi-plus"></i>
                    </button>
                  </div>
                  <div className="flex flex-col w-fit">
                    <h5>Unit Price</h5>
                    <div className="p-3 2xl:p-4 border border-[#ABAAB2] bg-[#F2F3F7]  text-[#1D1F2C] text-xl font-semibold rounded">
                      ${unitPrice.toFixed(2)}
                    </div>
                    <div>
                      {unitPrice < 1.0 && (
                        <span className="line-through text-red-500">$1.00</span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <h5>Discount</h5>
                    <div className="p-3 2xl:p-4 border border-[#ABAAB2] bg-[#F2F3F7]  text-[#1D1F2C] text-xl font-semibold rounded">
                      ${totalPrice.toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center mt-6">
                  <div className="flex flex-col">
                    <h5 className="font-bold text-[#1D1F2C] text-2xl">Total</h5>
                    <div className="py-2 px-8 border border-[#ABAAB2] bg-[#F2F3F7]  text-[#1D1F2C] text-xl font-semibold rounded-l-md">
                      ${totalPrice.toFixed(2)}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <h5 className="font-bold text-[#1D1F2C] text-2xl"></h5>
                    <button
                      onClick={handleSubmit}
                      className="mt-8 mr-2 bg-customBlue shadow shadow-[#05152F80] text-white py-2.5 px-8 rounded-r-md border border-customBlue  flex justify-center items-center font-medium text-md"
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold text-lg text-black mb-2">
                Buy Credit with Credit Calculator:
              </h2>
              <p className="font-medium text-base text-[#727A90] italic">
                Use our credit calculator to estimate the credits you need for
                your specific requirements. This tool helps you find the best
                value and automatically calculates the total price based on the
                input.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Bundle;
