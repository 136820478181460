import React from 'react'
import Switch from 'react-switch';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { feedbackApi } from '../../../../../../../api/feedback';
import { errorResponse } from '../../../../../../../components/utils/errorResponse';
import DropdownMenu from './TableDropdown';
import Table from '../../../../../../../components/table/Table';


const FeedbackTable = ({ 
        perPage, templates, isLoading, refetch, openCustomizeFeedbackModal,  
        activeTemplate, setActiveTemplate, setOpenCustomizeFeedbackModal,
        openQModal, setOpenQModal, setIsCourse, setAfterCourses,
        setIsMandatory, setAfterAssessment, setJourneyId, setCourseId,
     }) => {

 const updateTemplateMutation = useMutation(feedbackApi.editTemplate, {
        onSuccess: res => {
            toast.remove()
            toast.success(res?.message)
            refetch()
         },
         onMutate: () => {
            toast.loading('Loading...')
         },
         onError: error => errorResponse(error)
      })

    let columns = React.useMemo( () => [
        {
          title: "ID",
          selector: null,
          key: "id",
          render: ({ index }) =>
            index +
            1 +
            ((templates?.meta?.page || 1) - 1) * (templates?.meta?.perPage || perPage ),
        },
        {
            title: "Name",
            selector: "name",
            key: "name",
            render: ({ item }) => {                      
                 return( 
                    <span className="">{item}</span>
                 )
             },
          },
        {
          title: "Questions",
          selector: "questionCount",
          key: "questionCount",
          render: ({ item }) => {                      
               return( 
                  <span className="pl-5">{item}</span>
               )
           },
        },
        {
          title: "Usage",
          selector: null,
          key: null,
          render: ({ row }) => {                      
               return( 
                  <span className="pl-3">
                    {row?.feedbackJournies?.length + row?.feedbackCourses?.length }
                  </span>
               )
           },
        },
        {
          title: "Status",
          selector: "status",
          key: "status",
          render: ({ item, row }) => {                      
            return <span className="">
                 <Switch
                    height={20}
                    width={40}
                    handleDiameter={18}
                    checked={ item === 'active' ? true : false }
                    onChange={() => {
                      updateTemplateMutation.mutate({ 
                         id: row?.id,
                          data: {
                            status: item === 'active' ? 'inactive' : 'active',
                            name: row?.name,
                            questionIds: row?.questions?.length ? row?.questions?.map( q => q?.id ) : []
                          }
                     })    
                    }}
                />
            </span>;
          }
        },
        {
          title: "Date Created",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.createdAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Last Updated",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.updatedAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Action",
          selector: null,
          key: null,
          render: ({row}) => {           
            return <div > 
                <DropdownMenu 
                    template={row} openQModal={openQModal}
                    setOpenQModal={setOpenQModal}
                    activeTemplate={row}
                    setActiveTemplate={setActiveTemplate}
                    setOpenCustomizeFeedbackModal={setOpenCustomizeFeedbackModal}
                    setAfterAssessment={setAfterAssessment}
                    setCourseId={setCourseId}
                    setAfterCourses={setAfterCourses}
                    setIsCourse={setIsCourse}
                    setIsMandatory={setIsMandatory}
                    setJourneyId={setJourneyId}
                />
             </div>
          },
        },
      ],
      [1, 20, 20]
    );
    
  const tableProps = { isLoading, columns, data: templates?.data }

  return (
    <div>
        <Table {...tableProps} /> 
    </div>
  )
}

export default FeedbackTable