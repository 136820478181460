export const cohortInvitation = `
  <h1><strong>Welcome to Your New Learning Journey</strong></h1>
  <p><br></p><h2>Hello {{name}},</h2><p>We are thrilled to welcome you
   to our online training program!&nbsp;&nbsp;</p>
  <p>You have taken an exciting step towards advancing your knowledge 
  and skills in the banking industry, and we are delighted to have you on 
  board.&nbsp;&nbsp;</p><p>&nbsp;</p><p><strong>About Service School House:&nbsp;</strong></p>
  <p>Service School House's library of courses provides you with the skills and knowledge 
  needed to excel in your role within the organization. These self-paced courses can be taken 
  at anytime and anywhere. Our goal is to upskill you to deliver outstanding service!!!&nbsp;</p>
  <p> </p><p> </p><p><strong>How to Get Started:&nbsp;</strong></p><p>To log into your dashboard,
   kindly click the link below:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><p> </p>
   <p><strong>URL Link:</strong>&nbsp;<a href="https://growth.serviceschoolhouse.com/" 
   rel="noopener noreferrer" target="_blank">https://growth.serviceschoolhouse.com/</a>
    &nbsp;</p><p><strong>Username: {{username}}</strong></p><p><strong>Password:</strong> 
    {{password}}</p><p><br></p><p>Once you log in, you'll receive an enrollment 
    email with all the details you need for your learning experience on the platform</p>
    <p><br></p><p><strong> Need Help?</strong></p><p>We are here to support you every step
     of the way, so please don't hesitate to reach out to us if you have any questions or 
     concerns. You can reach us via email at <a href="mailto:Support@serviceschoolhouse.com" 
     rel="noopener noreferrer" target="_blank">Support@serviceschoolhouse.com</a>.</p>
     <p>To make sure you keep getting these emails, please add 
     Support@serviceschoolhouse.com&nbsp;to your address book or
      whitelist us.&nbsp;&nbsp;</p>
      <p>Thank you for joining us on this exciting journey. We look forward
       to seeing you succeed!&nbsp;</p><p> </p>
       <p> </p><h2>Warm Regards</h2><p><br></p>
`