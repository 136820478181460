import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { learners } from "../../../../../api/learners";
import toast from "react-hot-toast";
import { LearnersContext } from "../../../../../context/learnersContext";
import useLearners from "../../../../../hooks/useLearners";

const InviteLearner = () => {
  const { register, handleSubmit } = useForm();

  const user = JSON.parse(sessionStorage.getItem("user"));

  const { mutateAsync } = useMutation(learners.InviteLearner);

  const { setOpenInviteModal } = useContext(LearnersContext);

  const { filters } = useLearners();

  //   console.log(filters);

  const onSubmit = async (values) => {
    toast.loading("Inviting Learner...");
    // console.log(payload);
    const payload = {
      participants: [
        {
          name: values?.name,
          email: values?.email,
          employeeId: values?.employeeId,
        },
      ],
    };
    try {
      const data = await mutateAsync({
        payload,
        cohortId: values?.cohortId,
        orgId: user?.org?.id,
      });
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        setOpenInviteModal(false);
      }, 500);
    } catch (error) {
      toast.remove();
      toast.error(error?.data?.message);
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full mx-auto p-4 bg-white rounded-md"
      >
        <div className="mb-4">
          <p className="text-gray-700 text-sm font-medium text-left mb-2">
            Select Cohort
          </p>
          <select
            id="cohortId"
            type="cohortId"
            {...register("cohortId")}
            className="text-xs border rounded w-full p-3 focus:outline-none focus:shadow-outline"
          >
            {filters?.map((cohort) => (
              <option key={cohort?.cohortId} value={cohort?.cohortId}>
                {cohort?.cohortName}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <p className="text-gray-700 text-sm font-medium text-left mb-2">
            Employee ID
          </p>
          <input
            id="employeeId"
            type="employeeId"
            {...register("employeeId")}
            className="appearance-none text-xs border rounded w-full p-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Employee ID"
          />
        </div>
        <div className="mb-4">
          <p className="text-gray-700 w-full text-sm font-medium text-left mb-2">
            Name
          </p>
          <input
            id="name"
            type="text"
            {...register("name")}
            className="appearance-none text-xs border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Name"
          />
        </div>

        <div className="mb-6">
          <p className="text-gray-700 text-sm font-medium text-left mb-2">
            Email
          </p>
          <input
            id="email"
            type="email"
            {...register("email")}
            className="appearance-none text-xs border rounded w-full p-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Name"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-darkBlue text-white font-medium text-sm py-2 px-4 rounded w-full"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default InviteLearner;
