import createApiClient from "./api";

const client = createApiClient();

export const api = {
  getLearners: ({ id, search, perPage, currentPage }) =>
    client
      .get(
        `/admin/org/${id}/reports/learners?perPage=${perPage}${
          search ? `&search=${search}` : ""
        }&page=${currentPage}`
      )
      .then(({ data }) => data),

    getIndividualLearnerSummary: () => client
    .get(`/admin/org/2a5838d5-426c-482c-8f25-981ce33c6d04/reports/learners/a2beb141-0e9b-455d-8171-e0bab15403d0/courses/summary`).then(({ data }) => data),

    getIndividualLearnerCourses: ({ orgId, userId}) => client
    .get(`admin/org/097f1bd8-409d-46a6-8b68-281f1a68b4fa/reports/learners/a2beb141-0e9b-455d-8171-e0bab15403d0/courses/`).then(({ data }) => data),

    getIndividualLearnerAssessment: ({ orgId, userId}) => client
    .get(`admin/org/097f1bd8-409d-46a6-8b68-281f1a68b4fa/reports/learners/a2beb141-0e9b-455d-8171-e0bab15403d0/assessments/`).then(({ data }) => data),

  generateReport: (payload) => client.post(`admin/roles`, payload).then(({ data }) => data),
 
   getLearnersSummary: ({ id, search, perPage, currentPage }) =>
        client
          .get(
            `/admin/org/${id}/reports/learners/summary?perPage=${perPage}${
              search ? `&search=${search}` : ""
            }&page=${currentPage}`
          )
          .then(({ data }) => data),
  
   getTopPerformingCourses: ({ id, search, perPage, currentPage }) =>
            client
              .get(
                `/admin/org/${id}/reports/top-performing-course?perPage=${perPage}${
                  search ? `&search=${search}` : ""
                }&page=${currentPage}`
              )
              .then(({ data }) => data),

  getCourseSummary: ({id}) => client.get(`admin/org/${id}/reports/courses/summary`).then(({ data }) => data),
   
  getCompletionSummary: ({id}) => client.get(`admin/org/${id}/reports/course-completion/summary`).then(({ data }) => data),

}
