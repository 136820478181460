import React, { useState } from 'react'
import Table from '../../../../../../../../components/table/Table';
import Loader from '../../../../../../../../components/loader/Loader';
import DeleteCreditCode from './DeleteCreditCode';
import EmptyData from '../../../../../../../../components/EmptyData';

const CreditCodeTable = ({ isLoading, courseCodes, perPage, refetch, setActiveCourse, setOpenModal }) => {

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [id, setId] = useState(null)

  let columns = React.useMemo( () => [
    {
      title: "ID",
      selector: null,
      key: "id",
      render: ({ index }) =>
        index + 1 + ((courseCodes?.meta?.page || 1) - 1) * (courseCodes?.meta?.perPage || perPage ),
    },
    {
        title: "Credit Code",
        selector: "creditCode",
        key: "creditCode",
        render: ({ item, row }) => {                      
             return( 
                <span className="">{item}</span>
             )
         },
      },
      {
        title: "Description",
        selector: "description",
        key: "description",
        render: ({ item, row }) => {                      
             return( 
                <span className="">{item}</span>
             )
         },
      },
    {
      title: "Credit",
      selector: "credit",
      key: "credit",
      render: ({ item, row }) => {                      
           return( 
              <span className="">{item}</span>
           )
       },
    },
    {
      title: "Category",
      selector: "category",
      key: "category",
      render: ({ row }) => {                      
           return( 
              <span className="">{row?.category?.name || '----------'}</span>
           )
       },
    },
    {
      title: "Date Added",
      selector: "createdAt",
      key: "createdAt",
      render: ({ item }) => {           
        return <span className="">
           {`${ new Date(item).toLocaleDateString('en-ng')}`}
        </span>;
      },
    },
    {
      title: "Date Updated",
      selector: "updatedAt",
      key: "updatedAt",
      render: ({ item }) => {           
        return <span className="">
           {`${ new Date(item).toLocaleDateString('en-ng')}`}
        </span>;
      },
    },
    {
      title: "Action",
      selector: null,
      key: null,
      render: ({row}) => {           
        return <span className="font-medium">
           <span 
             className='bi bi-pencil-square hover:cursor-pointer text-blue-500 mr-3'
             onClick={ () => {
              setActiveCourse(row)
              setOpenModal(true)
             }}
           />
           <span 
              className='bi bi-trash hover:cursor-pointer text-red-500' 
              onClick={ () => {
                 setId(row?.id)
                 setOpenDeleteModal(true)
              }}
            />
        </span>;
      },
    },
  ],
  [1, 20, 20]
);

const tableProps = { isLoading, columns, data: courseCodes?.data }

if(isLoading) return <Loader />

  return (
    <div> 
         <DeleteCreditCode 
           id={id} openModal={openDeleteModal}
           setOpenModal={setOpenDeleteModal} 
           refetch={refetch}
         />
        { 
         courseCodes?.data ?  <Table {...tableProps} /> :
         <EmptyData text={'No any course credit found.'} />
        }
    </div>
  )
}

export default CreditCodeTable