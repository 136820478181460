import React from 'react'

const ProgressBar = ({height='80px', width='100%', progress=0}) => {
  return (
    <div style={{height, width}} className='block rounded-lg bg-gray-100'>
        <div className='bg-[#351E99] h-full rounded-lg' style={{width: `${progress}%`}}>
        </div>
    </div>
  )
}

export default ProgressBar