import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { api } from "../../../../../api/reward";
import EmptyData from "../../../../../components/EmptyData";

const data = [
  {
    country: "USA",
    value: 100,
  },
  {
    country: "Germany",
    value: 90,
  },
  {
    country: "France",
    value: 140,
  },
];

const BarChart = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { id } = useParams();

  const { data: trend } = useQuery(
    ["Reward Trend"],
    ({ queryKey }) => {
      return api.getDistribution({ id: user?.org?.id, rewardId: id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  // console.log(trend);

  const chartdata = [
    {
      id: "Starting",
      data: trend?.rewards?.map((item, index) => {
        return { x: item?.date, y: item?.count };
      }),
    },
  ];

  const maxCount = chartdata[0]?.data?.reduce((max, item) => {
    return item.y > max ? item.y : max;
  }, 0);

  // console.log(maxCount);

  return (
    <div className="w-full h-[450px] mt-4">
      {trend?.rewards?.length ? (
        <ResponsiveBar
          data={trend?.rewards}
          keys={["count"]}
          indexBy="date"
          margin={{ top: 20, right: 30, bottom: 80, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={["#FF9100"]}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: "",
            legendPosition: "middle",
            legendOffset: 36,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "NUMBER OF REWARDS EARNED",
            legendPosition: "middle",
            legendOffset: -45,
            format: (value) => Math.floor(value), // Round to the nearest whole number
            tickValues: "every 1", // Show only whole numbers
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      ) : (
        <EmptyData text={"Nothing to show here..."} />
      )}
    </div>
  );
};

export default BarChart;
