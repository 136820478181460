import React from 'react'
import CenteredModal from '../Modal/CenteredModal'
import warningIcon from '../../assets/Subtract (2).png'
import errorIcon from '../../assets/Subtract (1).png'
import successIcon from '../../assets/success-check.png'

type ModalType = ('success' | 'warning' | 'error')

type ActionModalType = {
    openModal: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
    title?: string,
    type: ModalType,
    text?: string,
    actionText: string,
    cancelText?: string,
    withCancelButton?: boolean,
    onActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    onCancelClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}
const ActionModal = ({ 
    openModal, setOpenModal, title, type, text, actionText, onActionClick,
    onCancelClick, cancelText, withCancelButton=true,

 }: ActionModalType) => {

 const iconSrc = (type: ModalType)  => {
   switch(type){
      case 'success' : 
        return successIcon
       break
       case 'warning':
        return warningIcon
      break
      case 'error':
        return errorIcon
      break
      default:
        return successIcon
   }
 }

  return (
    <CenteredModal
        title={null}
        bgColor={'bg-white'}
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[400px] h-auto overflow-y-auto'}
        headerClassName={'hidden'}
        withCloseBtn={false}
        closeButtonLabel={null}
        btnBgColor={'bg-secondary'}
        onClose={null}
      >  
        <img src={iconSrc(type)} className='w-[70px] mx-auto block h-[70px]' alt="icon"/>
        <h5 className='text-md pt-3 pb-1 font-semibold'>
            {title}
        </h5>
        <p className='text-center text-sm'>{text}</p>
        <div className={`flex mt-4 justify-center`}>
           <button 
              className={`py-3 ${
                withCancelButton ? 'px-5' : 'px-16'
              } text-center bg-secondary text-white rounded-md text-xs mr-8`}
              onClick={onActionClick}
            >
               {actionText}
           </button>
        {  withCancelButton ?       
        <button 
              className='py-3 px-5 text-center bg-slate-200 rounded-md text-xs mr-8'
              onClick={onCancelClick}
            >
               {cancelText}
           </button> : null }
        </div>
      </CenteredModal>
  )
}

export default ActionModal