import React, { createContext, useContext, useState } from "react";
import CustomToast from "./CustomToast";

const CustomToastContext = createContext();

export function CustomToastProvider({ children }) {
  const [toast, setToast] = useState(null);

  const success = (message) => {
    setToast({ type: "success", message });
  };

  const error = (message) => {
    setToast({ type: "error", message });
  };

  const dismiss = () => {
    setToast(null);
  };

  return (
    <CustomToastContext.Provider value={{ success, error, dismiss }}>
      {children}
      {toast && <CustomToast toast={toast} setToast={setToast} />}
    </CustomToastContext.Provider>
  );
}

export function useCustomToast() {
  return useContext(CustomToastContext);
}
