import React, { useMemo, useContext } from "react";
import Table from "../../../../../components/table/Table";
import { useQuery } from "react-query";
import { api } from "../../../../../api/reward";
import { convertDate } from "../../../../helpers";
import TopFive from "./TopFive";
import { Link } from "react-router-dom";
import { RewardsContext } from "../../../../../context/rewardContext";

const CategoryList = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { setCurrentCategory } = useContext(RewardsContext);

  const { data, isLoading } = useQuery(
    ["Categories"],
    ({ queryKey }) => {
      return api.getCategories({ id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1,
      },
      {
        title: "Name",
        selector: "name",
        key: "name",
        render: ({ item, row }) => {
          return (
            <Link
              onClick={() => {
                setCurrentCategory(row);
              }}
              className="text-armyGreen hover:underline"
              to={`category/${row?.id}`}
            >
              {item}
            </Link>
          );
        },
      },
      {
        title: "Description",
        selector: "description",
        key: "description",
      },
      {
        title: "Created At",
        selector: "createdAt",
        key: "createdAt",
        render: ({ item }) => {
          return <span>{convertDate(item)}</span>;
        },
      },
      {
        title: "Last Modified",
        selector: "updatedAt",
        key: "updatedAt",
        render: ({ item }) => {
          return <span>{convertDate(item)}</span>;
        },
      },
    ],
    []
  );

  const tableProps = { data, isLoading, columns };

  //   console.log(data);
  return (
    <div className="w-full mt-7">
      <div className="w-full flex items-center justify-between gap-5 mb-5">
        <div className="w-full border border-white shadow-lg p-3 bg-white rounded-md">
          <p className="text-sm font-medium">Top 5 Rewards</p>
          <div className="">
            <TopFive sort={"desc"} />
          </div>
        </div>
        <div className="w-full border border-white shadow-lg p-3 bg-white rounded-md">
          <p className="text-sm font-medium">Bottom 5 Rewards</p>
          <div className="">
            <TopFive sort={"asc"} />
          </div>
        </div>
      </div>
      <div className="w-full border border-white shadow-lg p-3 bg-white rounded-md">
        <h1 className="text-sm font-medium mb-1">Categories</h1>
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default CategoryList;
