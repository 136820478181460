import React from 'react'
import flightIcon from '../../../../../../../assets/03.png'
import { Link } from 'react-router-dom'

const StarterPage = () => {
  return (
    <div className='flex flex-col justify-between'>
        <img src={flightIcon} alt="flight image" className='w-[800px] h-[300px] mx-auto block' />
        <div className='text-center mt-5'>
            <h3 className='text-md text-center text-primary font-semibold'>
              Create Your First Module
            </h3>
            <p className='text-sm mt-1 mb-4'>
              Create modules, upload module zip. etc
            </p>
            <Link to={'/admin/courses/module/create'} className='btn mt-3 bg-primary-blue'> 
              Create Module
            </Link>
        </div>
    </div>
  )
}

export default StarterPage