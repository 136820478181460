import React, {useState, useEffect} from 'react'
import CenteredModal from '../../../../../../../../../components/Modal/CenteredModal'
import CustomDropdown from '../../../../../../../../../components/dropdown/CustomDropdown'
import { useMutation, useQuery } from 'react-query'
import { api } from '../../../../../../../../../api/roles-and-permissions'
import toast from 'react-hot-toast'

const ViewPermissions = ({ openModal, setOpenModal, activeRole, refetch }) => {

  const [permissions, setPermissions] = useState([])

  const { data: data, isLoading, } = useQuery(
    ["get system permissions", /*{ search}*/],
    ({ queryKey }) => {
      return api.getPermissions(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  const assignPermissionsToRoleMutation = useMutation(api.addPermissionToARole,{
    onMutate: () => {
      toast.loading('Loading...')
    },
    onError: (error) => {
        toast.remove()
        if(error?.response?.message){
          toast.error(error?.response?.data?.message);
        } else toast.error('Error! an unknown error occured.');
    },
    onSuccess: response => {
       toast.remove()
       toast.success('Permissions added successfully!')
       refetch()
       setOpenModal(false)
    } 
  })

 useEffect( () => {
    setPermissions(activeRole?.permissions?.length ? activeRole?.permissions?.map( permission => {
      return { label: permission?.name, value: permission?.id }
    }) : [])
 }, [activeRole] )

  const assignPermissionsToRole = () => {
    if(!permissions?.length) toast.error('Error! no permission selected.')
    else{
      const payload = {
        id: activeRole?.id,
        data: {
          permissionIds: permissions?.map( permission => permission?.value )
        }
      }
      assignPermissionsToRoleMutation.mutate(payload)
    }
  }

  return (
    <> 
       <CenteredModal 
        title={ 
          <h5 className='text-md font-semibold bi bi-plus-circle'> 
              &nbsp; Add/Update Permissions  {console.log(permissions)}
          </h5> 
        }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={`w-[450px] ${ permissions?.length > 2 ? 'h-[230px]' : 'h-[auto]' } overflow-y-auto`}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Add Permissions'}
        //btnBgColor={'bg-primary'}
        onClose={ () => {
          assignPermissionsToRole()
        }}
      >
         <section className='text-left p-2'>
            <p className='text-xs'>  
              Select permissions and assign to a role.
            </p>
            <div className='w-full mt-3'>
                <CustomDropdown 
                   value={permissions}
                   width={'w-full'}
                   placeholder={'Select and add permission...'}
                   options={
                    (data?.length || isLoading ) ? 
                     data?.map( permission => {
                       return { label: permission?.name, value: permission?.id }
                     })
                   : []
                  }
                   onChange={ value => {
                     setPermissions(value)
                   } }
                   multiSelect
                />
            </div>
         </section>
      </CenteredModal>
    </>
  )
}

export default ViewPermissions