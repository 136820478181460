import React from 'react'
import { Link } from 'react-router-dom'

const OverviewCard = ({ 
       title, total, text, iconPath, className, py='py-4', 
       headerTextSize='text-lg', withNavigation = false, path,
   }) => {

  return (
    <div className={`${py} border border-gray-200 shadow rounded-lg p-4 bg-white`}>
         <header className='flex justify-between'>
            <h6 className={`font-medium mb-0 text-gray-600 text-sm`}>
                {title}
            </h6>
            <img src={iconPath} alt="icon" className='h-[30px] w-[30px]' />
         </header>
        <span className={`block py-0 font-medium ${headerTextSize}`}>
            {total}
        </span>
         {
            text && <p className='text-xs mt-2 border-t border-gray-400 pt-2 font-medium'>
            {text}
         </p>
         }
         {
           withNavigation && <Link className='flex mt-2  text-sm justify-between text-purple-700 font-medium pt-1 justfiy-between' to={path} >
              See More <span className='bi bi-caret-right' />
           </Link>       
          }
    </div>
  )
}

export default OverviewCard