import React, {useState} from "react";
import Card from "./Card";
import Button from "./Button";
import { useQuery } from "react-query";
import { api } from "../../../../../../../api/report";
import Loader from "../../../../../../../components/loader/Loader";
import { Link } from "react-router-dom";

function LearnersTable() {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(sessionStorage.getItem('user'))

  const { data: learners, isLoading, refetch } = useQuery(
    ["learners", { search, perPage, currentPage, id: user?.org?.id }],
    ({ queryKey }) => {
      return api.getLearners(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  return (
    <Card>
      <div className="relative overflow-x-auto">
        <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
          <div>
            <h2 className="text-lg text-black font-semibold">Learners</h2>
          </div>
          <div>
            <Button outlined={true}>Filters</Button>
            <Button>See More</Button>
          </div>
        </div>
        {
          isLoading ?  <Loader /> :
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Joined
              </th>
              <th scope="col" className="px-6 py-3">
                Last Active
              </th>
              <th scope="col" className="px-6 py-3">
                Enrolled
              </th>
              <th scope="col" className="px-6 py-3">
                Completed
              </th>
            </tr>
          </thead>
          <tbody>
              {learners?.data?.map( learner => {
                 return(
                    <tr key={learner?.participantId} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <Link to={`learner/${learner?.participantId}`} className="text-armyGreen underline">
                              {learner?.name}
                            </Link>
                        </th>
                        <td className="px-6 py-4">{learner?.email}</td>
                        <td className="px-6 py-4">
                          {`${new Date(learner?.joinedDate).toLocaleDateString('en-ng')}`}
                        </td>
                        <td className="pr-6 pl-12 font-bold py-4">{learner?.lastActive || '-'}</td>
                        <td className="px-6 py-4">{learner?.numberOfEnrolledCohorts}</td>
                        <td className="px-6 py-4">{learner?.numberOfCompletedCohorts}</td>
                    </tr>
                 )
              })}
          </tbody>
        </table>
        }
      </div>
    </Card>
  );
}

export default LearnersTable;
