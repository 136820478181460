import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, scales } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display: false
    },
    title: {
      display: true,
      text: 'Invoice and Pre-paid',
    },
  },
  scales: {
    y: {
      ticks: {
        callback: (value) => {
          // Convert large numbers to more readable format (e.g., 10000 to 10K)
          if (value >= 1000) {
            return (value / 1000) + 'K';
          }
          return value;
        }
      }
    }
  }
};

const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  

const TopUpTrends = ({revenueChart}) => {

  const data = {

    labels: revenueChart?.prePaid?.length ? 
    revenueChart?.prePaid?.map( invoice => invoice?.date ) : labels ,

    datasets: [
      {
        fill: true,
        label: 'Prepaid',
        data: revenueChart?.prePaid?.length ? 
          revenueChart?.prePaid?.map( invoice => invoice?.amount ) 
        : [],
        borderColor: '#FF9100',
        backgroundColor: 'transparent',
      },
      {
        fill: true,
        label: 'Invoice',
        data: revenueChart?.invoice?.length ? 
               revenueChart?.invoice?.map( invoice => invoice?.amount ) 
         : [],
        borderColor: '#091F46',
        backgroundColor: 'transparent',
      },
    ],
  };

  return (
    <Line height={100} options={options} data={data} />
  );
};

export default TopUpTrends;
