import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

const NavLinks = () => {

  const location = useLocation()
  const isLoginRoute = location.pathname.includes('/auth/login')

  return (
    <>  
         <NavLink to={'/'} className={"mr-8 font-semibold text-sm m-text-lg"}>
            <span className='bi bi-house-add text-md hidden'></span> Home
          </NavLink>
          <NavLink to={'/about'} className={
                                             ({ isActive}) => isActive ? 
                                                  "active-link text-sm mr-8 font-semibold m-text-lg" 
                                                  : "mr-8 text-sm font-semibold m-text-lg" 
                                             }>
             About
          </NavLink>
          <NavLink to={'/contact'} className={
                        ({ isActive}) => isActive ? 
                             "active-link mr-8 text-sm font-semibold m-text-lg" 
                               :
                              "font-semibold text-sm mr-8 m-text-lg" 
                       }
          >
             Contact
          </NavLink>
          <NavLink to={'/contact'} className={
                          ({ isActive}) => isActive ? 
                                 "font-semibold text-sm active-link mr-8 m-text-lg" 
                                 : "font-semibold text-sm mr-8 m-text-lg" 
                           }>
             Blog
          </NavLink>
         <Link to={ !isLoginRoute ? '/auth/login' : '/'} 
                className='font-semibold mr-4 px-8 text-xs rounded-full border border-primary py-2 m-text-lg text-primary'
          >
            { !isLoginRoute ? 'Login' : 'SignUp'}
         </Link>
    </>
  )
}

export default NavLinks