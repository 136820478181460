import toast from "react-hot-toast";

export const validateQuestions = (questions, emojis, multipleSelect) => {
    // Iterate over each question object
    for (let i = 0; i < questions?.length; i++) {
      if (!questions[i].question.trim()) {
        toast.error(`Question text is missing for question ${i + 1}`);
        return false;
      }
      // If type is 'emojis', ensure the emojis array has at least one item
      if (questions[i].type === emojis) {
        if (questions[i].emojis.length < 1 ) {
          toast.error(`Emojis are missing for question ${ i + 1 }, Please select reaction emojis`);
          return false;
        }
      }
      if(questions[i].type === multipleSelect){
          questions[i].answers?.forEach( (answer, index) => {
             if(answer?.title?.trim() === ''){
                toast.error(`Error! empty answer field provided in question ${i+1}`)
                return false
             }
          });
      }
    }
    return true;
  };