import React from "react";

const ReleaseNote = () => {
  return (
    <div className="w-full py-3 flex items-center flex-col justify-center gap-3">
      <h1 className="underline text-xl">Release Note</h1>
      <p>Service School house v1.0</p>
      <p> last deployment time: 17/7/2024 9:49am</p>
    </div>
  );
};

export default ReleaseNote;
