import createApiClient from "../api/api";

const client = createApiClient()

export const cohorts = {

    createCohort: (payload: any) =>
        client
          .post(`admin/org/${payload?.id}/cohorts`, payload?.data)
          .then(({ data }) => data),
  
   addLearnersToCohort: (payload: any) : Promise<any> =>  
      client
        .post(
          `admin/org/${payload?.id}/cohorts/${payload?.cohortId}/assign-participants`,
          payload?.data
        )
        .then(({ data }) => data),
  
  addCohortInvitationEmail: ({orgId, payload, cohortId} : any) => client
  .patch(`/admin/org/${orgId}/cohorts/${cohortId}/invite-email`, payload)
  .then(({ data }) => data),

  addWelcomePage: ({orgId, payload, cohortId} : any) => client
  .patch(`/admin/org/${orgId}/cohorts/${cohortId}/message`, payload)
  .then(({ data }) => data),

  addMultiLanguageWelcomeMessage: ({orgId, payload, cohortId} : any) => client
  .patch(`/admin/org/${orgId}/cohorts/${cohortId}`, payload)
  .then(({ data }) => data),

  addTeamInvitationEmail: ({orgId, payload, cohortId} : any) => client
        .patch(`/admin/org/${orgId}/cohorts/${cohortId}/welcome-email`, payload)
        .then(({ data }) => data),

  getCohortSummary: (orgId: string) =>
    client
      .get(`/admin/learners/profile/summary?orgId=${orgId}`)
      .then(({ data }) => data),
  
  getCohortById: (orgId: string, id: string) =>
    client
      .get(`/admin/org/${orgId}/cohorts/${id}`).then(({ data }) => data),
}