import { AxiosInstance } from "axios";
import createApiClient from "../api/api";
import { getClientsType } from "./types/clients.types";

const client: AxiosInstance = createApiClient()

export const clients = {
    getClients: ({ search, perPage, currentPage}: getClientsType) : Promise<any> => 
        client.get(`admin/organizations?perPage=${perPage}${ 
                  search ? `&search=${search}` : ''}&page=${currentPage}`
                 )
       .then(({ data }) => data),
}