import createApiClient from "./api";

const client = createApiClient();
// const formDataClient = createApiClient(true);

export const api = {
  getUsers: ({ id }) =>
    client.get(`admin/org/${id}/users`).then(({ data }) => data),

  getTeams: ({ id }) =>
    client.get(`admin/org/${id}/teams`).then(({ data }) => data),

  createTeam: ({ id, payload }) =>
    client.post(`admin/org/${id}/teams`, payload).then(({ data }) => data),

  updateTeam: ({ id, payload, teamId }) =>
    client
      .patch(`admin/org/${id}/teams/${teamId}`, payload)
      .then(({ data }) => data),

  deleteTeam: ({ id, teamId }) =>
    client.delete(`admin/org/${id}/teams/${teamId}`).then(({ data }) => data),
};
