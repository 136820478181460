import React, { useState } from 'react';

const MultiSelectInput = ({ selectedValues, onValuesChange }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === ' ' && inputValue.trim() !== '') {
      onValuesChange([...selectedValues, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveValue = (value) => {
    const updatedValues = selectedValues.filter((selectedValue) => selectedValue !== value);
    onValuesChange(updatedValues);
  };

  return (
    <div className="border rounded-lg group h-[48px] py-3 px-4 overflow-y-auto">
      <div className="space-y-2 space-x-2 flex flex-wrap">
        {selectedValues.map((value) => (
          <span
            key={value}
            className="flex items-center bg-transparent rounded-full px-2 py-1"
          >
            {value}
            <button
              onClick={() => handleRemoveValue(value)}
              className="ml-2 text-[#413659]"
            >
              &times;
            </button>
          </span>
        ))}
        <input
          type="text"
          placeholder="Input email address and press space to add"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          className="w-full bg-transparent focus:outline-none   focus:border-transparent"
        />
      </div>
    </div>
  );
};

export default MultiSelectInput;
