import React from 'react'
import CenteredModal from '../../../../../../components/Modal/CenteredModal'

type PreviewModuleModalType = {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  modulePath: string | null
}

const PreviewModuleModal: React.FC<PreviewModuleModalType> = (props): React.ReactElement => {

 const {openModal, setOpenModal} = props

  return (
    <>
       <CenteredModal
          title={
            <h5 className='text-md font-semibold bi bi-eye'> 
                &nbsp;Preview Module
           </h5>
          }
        bgColor={'bg-white'}
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[650px] h-[500px] overflow-y-auto'}
        headerClassName={'flex justify-between px-3 pt-2 sticky'}
        withCloseBtn
        closeButtonLabel={'Close'}
        btnBgColor={'bg-secondary'}
        onClose={ () : void => {
           setOpenModal(false)
        }}
      >  
         <section className='text-left px-3 my-3 border-2 border-slate-400 border-dotted w-full h-[360px]'>
              
         </section>
      </CenteredModal>
    </>
  )
}

export default PreviewModuleModal