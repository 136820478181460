import React from "react";
import Search from "../../../../../components/Inputs/Search";
import Button from "../../../../../components/Buttons/Button";
import { useQuery } from "react-query";
import { api } from "../../../../../api/users";

const Overview = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { data } = useQuery(
    ["Users"],
    () => {
      return api.getUsers({ id: user?.org?.id });
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: true }
  );

  // console.log(data);

  return (
    <div className="w-full p-10">
      <h1 className="text-2xl font-bold">Overview</h1>
      <p className="text-xs text-slate-500">
        Manage access to courses, settings e.t.c
      </p>
      <div className="w-full flex items-center justify-between my-4">
        <div className="w-[300px]">
          <input
            type="search"
            className="input-md"
            // value={search}
            placeholder="Search..."
            // onChange={(e) => {
            //   setCurrentPage(1);
            //   setSearch(e.target.value);
            // }}
          />
        </div>
        <div className="flex items-center gap-3 justify-between">
          <Button
            bgColor={"bg-transparent"}
            borderColor={"border-darkBlue"}
            textColor={"text-darkBlue"}
            text={"Invite user"}
            width={"w-[150px]"}
          />
          <Button bgColor={"bg-darkBlue"} text={"Create user"} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
