import React, {useState, useEffect} from 'react'
import { ErrorMessage, Field } from 'formik'
import FormHeader from './components/FormHeader'
import Input from './components/Input'
import CompanyRoleButton from './components/CompanyRoleButton'
import Button from '../../components/Buttons/Button'
import toast from 'react-hot-toast'

const CompanyInfo = ({setFieldError, setFieldValue, values, setCurrentStep, currentStep, setIsActive }) => {

 const [role, setRole] = useState(null)

 const roles = [
    { id:1 , title : 'Agent'},
    { id:2 , title : 'Manager'},
    { id:3 , title : 'Supervisor'},
 ]
 
 const isNotValidated = !values?.agreed || !values?.fname || !values?.lname || !values?.email
                        || !values?.phone_number || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email) ;

  useEffect( () => {
      if(isNotValidated){
         setCurrentStep(0)
         //toast.error('Error! complete the previous step before you proceed.')
      }
  }, [currentStep] )

 const changeRole = id => setRole(id)

  return (
    <div className='pb-8'>
        <section className='mt-0 rounded-lg shadow w-[96%] md:w-[450px] mx-auto bg-lightOrange py-5 md:px-8'>
            <FormHeader text='Add Company Details' />
                <Input
                       label={'Company Name'}
                       type='text'
                       name={'company_name'} 
                />
                <Input
                  label={'Company Address'}
                  type='text'
                  name={'company_address'}
                 />
                {/*<label className='text-xs block mt-5'> Company Role </label>*/}
                {/*<div className='flex items-center justify-between mt-3'>
                    {/
                         roles.map( role_ => {
                            return (
                                <CompanyRoleButton 
                                     text={role_.title} key={role_.title}
                                      active={ 
                                           (role === role_.id || role_.title === values.role) ?
                                            true : false
                                      } 
                                      onClick={ () => {
                                        changeRole(role_.id)
                                        setFieldValue('role', role_.title)
                                      } }
                                />
                            )
                         } )
                    }
                </div>*/}
                {/*<ErrorMessage name='role' component={'p'} className='text-xs text-red-500 mt-3' />*/}
                <Input
                  label={'Domain (unique organisational identifier)'}
                  type='text'
                  name={'role'}
                 />
                 <div>
                 <label className='text-xs'> Payment Type </label> <br />
                    <Field 
                      name={'paymentType'}
                      className='auth-input font-semibold'
                      as='select'
                    >
                      <option value="pre-paid"> Pre Paid </option>
                      <option value="post-paid">Post Paid</option>
                    </Field>
                 </div>
                <div className='mt-8 pb-4'>
                    <Button
                       text={'Continue'}
                       onClick={ () => {
                          if(!values?.company_name || !values?.company_address || !values?.role){
                            document.querySelector('#submit-btn').click()
                          }
                         else {
                          setIsActive(true)
                          setTimeout( () => setCurrentStep(2) , 100 )
                         }
                         
                       } }
                    />
                </div>
        </section>
    </div>
  )
}

export default CompanyInfo