import React, { useEffect, useState } from 'react'
import BundlesTable from './BundlesTable'
import CreateBundle from './CreateBundle'
import { api } from '../../../../../../../../api/billing';
import Button from '../../../../../../../../components/Buttons/Button';
import { useQuery } from 'react-query';
import Pagination from '../../../../../../../../components/pagination/Pagination';

const BundleTab = ({openBundleModal, setOpenBundleModal}) => {

  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeBundle, setActiveBundle] = useState(null)

  const {
    data: bundles,
    isLoading,
    refetch,
  } = useQuery(
    ["billing bundles"],
    ({ queryKey }) => {
      return api.getBundle({ ...queryKey[1], perPage, currentPage, search });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount:false,
      select: (data) => data,
    })

    useEffect( () => {
        refetch()
    }, [ currentPage, perPage, search ] )

  return (
    <section className='p-3 bg-white'>
        <header className='flex justify-between items-center'>
        <h2 className='text-sm'>
            Bundles ({bundles?.meta?.total}) 
        </h2>
        <div className='mt-2'>
            {/*<Search 
                className={'mb-0 hidden w-[300px] border border-gray-200 text-xs pl-2'}
                placeholder={'Search Client Volume Discount'} 
            />*/}
            <Button
                text={'Add Bundle'} width={'w-[200px]'}
                bgColor={'bg-[#F86624] shadow'} 
                onClick={ () => setOpenBundleModal(true) }
            />
            <CreateBundle 
                openModal={openBundleModal}
                setOpenModal={setOpenBundleModal}
                refetch={refetch}
                activeBundle={activeBundle}
                setActiveBundle={setActiveBundle}
            />
        </div>
      </header>
        <BundlesTable
          isLoading={isLoading}
          data={bundles}
          perPage={perPage}
          setOpenBundleModal={setOpenBundleModal}
          openBundleModal={openBundleModal}
          activeBundle={activeBundle}
          setActiveBundle={setActiveBundle}
        />
        <Pagination 
            data={bundles?.meta}
            perPage={perPage}
            pageNo={currentPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage}
    />
  </section>
  )
}

export default BundleTab