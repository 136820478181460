import React from 'react'

const FormHeader = ({
      text=' Welcome to Service School House', 
      className='text-primary font-bold text-2xl'
}) => {
  return (
    <>
      <h5 className={className}>
         {text}
     </h5>
    <div className='flex pt-3'>
        <span className='h-[5px] rounded-full w-[40px] bg-customBlue'></span>
        <span className='h-[5px] rounded-full w-[40px] ml-4 bg-gray-300'></span>
        <span className='h-[5px] rounded-full w-[40px] bg-gray-100 ml-4'></span>
    </div>
    </>
  )
}

export default FormHeader