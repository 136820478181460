export const teamInvitation = `
   <h1>
       <strong> Welcome to Service School House Cohort 7</strong>!&nbsp;</h1>
       <p> Hello  {{name}}</p><p><br></p><p>   
       <span class="ql-font-serif">We are excited to welcome you as a part of</span> 
       Service School House Cohort 7. You have been successfully profiled into our training
        platform and are now officially onboarded into our training program. 
         </p><p><br></p><p><strong>Next Steps</strong></p><p><strong>1.</strong>
          <strong>Enroll into Your Learning Path</strong>&nbsp;</p><p>Click on
           the link below to get started with the {{path}} Learning Path. 
           Each module is crafted to build your skills and knowledge, 
           ensuring that by the end of your journey, you'll be well-equipped 
           to apply what you've learned in real-world scenarios.</p><p><br></p><p>
           <strong>2.</strong> <strong>Meet Your Team</strong></p><p>You are now a
            proud member of the {{team}} Team! As a team, you'll have the opportunity to 
            compete in various games and challenges against other teams on the platform
             to top the leaderboard. Your participation and progress will contribute to
              the success of your team.</p><p><br></p><p><strong>3.</strong> <strong>Stay
               Connected</strong> </p><p>To ensure you don't miss any updates, announcements,
                or support, please join our WhatsApp group by clicking the link below:</p><p><br>
                </p><p>WhatsApp Group:<a href=" https://chat.whatsapp.com/H1igdPpRDPZHlsnPXOs0x9"
                 rel="noopener noreferrer" target="_blank"> 
                https://chat.whatsapp.com/H1igdPpRDPZHlsnPXOs0x9 </a>
                </p><p><a href=" https://chat.whatsapp.com/H1igdPpRDPZHlsnPXOs0x9  
                "rel="noopener noreferrer" target="_blank"> </a></p><p>Warm regards,&nbsp;</p>
                <p>Service School House</p><p> </p><p><br></p>
`