import React, { useState } from 'react';
import { v4 } from 'uuid';
import AnswerTypeRenderer from './components/AnswerTypeRenderer';
import toast from 'react-hot-toast';
import { validateQuestions } from './utils/validateQuestions';
import PreviewFeedback from './components/PreviewFeedback';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { feedbackApi } from '../../../../../../api/feedback';
import { errorResponse } from '../../../../../../components/utils/errorResponse';
import Button from '../../../../../../components/Buttons/Button';

export const questionTypes = [
    'Rating (5 stars)', 'Open-Ended Comments', 'Satisfaction Rating',
    'Emotional Feedback', 'Yes Or No', 'Multiple Choice',
]

const CreateFeedback = () => {

  const initialQuestion = {
     id: v4(),
     type: questionTypes[0],
     answer: '',
     question: '',
     mandatory: true,
     enableComment: true,
     useAsRating: true,
     emojis: [],
     answers: [{ id: v4(), title: '' }],
  };

  const [questions, setQuestions] = useState([initialQuestion])
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get('redirect');

  const createFeedbackQuestionMutation = useMutation(feedbackApi.createQuestions, {
     onSuccess: res => {
        toast.remove()
        toast.success(res?.message)
        navigate(`/admin/feedback/${ redirect === 'edit' ? 
             'edit-feedback?refresh=1'
            :
            'create-feedback-template?refresh=1'
        }`)
     },
     onMutate: () => {
        toast.loading('Loading...')
     },
     onError: error => errorResponse(error)
  })

  const updateQuestionType = (id, value, type, emoji,) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) => {
        if (question?.id === id) {
          if (type === questionTypes[3]) {
            if (question.emojis.includes(emoji)) {
              toast.error("Emoji Already Selected");
              return question;
            }
            return { ...question, emojis: [...question.emojis, emoji] };
          }
          return { ...question, [type]: value };
        }
        return question;
      })
    );
  };  

  const deleteQuestion = id => {
     setQuestions( questions.filter( question => question?.id !== id ) );
  }

  const deleteEmoji = (id, emoji) => {
    const updatedQuestions = questions?.map( question => {
        if(question?.id === id){
            return {...question, emojis: question.emojis.filter( emoji_ => emoji_ !== emoji )}
        }
        else return question;
     })
     setQuestions(updatedQuestions)
  }

  const createFeedbackQuestion = () => {
    if(validateQuestions(questions, questionTypes[3], questionTypes[5])){
        const payload = questions?.map( question => {
            switch(question?.type){
                case questionTypes[0] : 
                    return { 
                             type: 'rating', text: question.question, isMandatory: question.mandatory,
                             hasComment: question.enableComment, useAsRating: question.useAsRating,
                             answers: [{ text: '5' }]
                          };
              break;
              case questionTypes[1] : 
                    return { 
                             type: 'text', text: question.question, isMandatory: question.mandatory,
                             hasComment: question.enableComment, useAsRating: question.useAsRating,
                             answers: [{ text: question.answer }]
                          };
              break;
              case questionTypes[2] : 
                    return { 
                             type: 'satisfaction', text: question.question, isMandatory: question.mandatory,
                             hasComment: question.enableComment, useAsRating: question.useAsRating,
                             answers: [...[1,2,3,4,5,6,7,8,9,10].map( level => {
                                return { text: `${level}` }
                             })]
                          };
              break;
              case questionTypes[3] : 
                    return { 
                             type: 'likert', text: question.question, isMandatory: question.mandatory,
                             hasComment: question.enableComment, useAsRating: question.useAsRating,
                             answers: question.emojis.map( emoji => {
                               return { text: emoji }
                             })
                          };
              break;
              case questionTypes[4] : 
                    return { 
                             type: 'yes or no', text: question.question, isMandatory: question.mandatory,
                             hasComment: question.enableComment, useAsRating: question.useAsRating,
                             answers: [{text: 'yes', text: 'no'}]
                          };
              break;
              case questionTypes[5] : 
                    return { 
                             type: 'multiple', text: question.question, isMandatory: question.mandatory,
                             hasComment: question.enableComment, useAsRating: question.useAsRating,
                             answers: question.answers.map( answer => {
                               return { text: answer.title }
                             })
                          };
              break;
            }
        })
        createFeedbackQuestionMutation.mutate({ questions: payload})
    }
  }

  return (
    <div className='bg-lightlue p-3 px-5'>  
        <PreviewFeedback 
            open={open} setOpen={setOpen} questions={questions} 
            questionTypes={questionTypes}
        />
        <header className='text-lg flex justify-between pr-2 items-center font-semibold'>
              <div className='bi bi-plus-circle'> &nbsp;Create Feedback Questions</div>
              <div className='flex'>
                    <Button 
                        bgColor={' shadow mr-3'}
                        text={'Preview'}
                        width={'w-[170px]'}
                        textColor={'text-secondary bi bi-eye'}
                        onClick={ () => setOpen(true) }
                    /> &nbsp;&nbsp;&nbsp;
                    <Button 
                        bgColor={'bg-customBlue'}
                        text={'Save Questions'}
                        width={'w-[170px]'}
                        onClick={createFeedbackQuestion}
                    />
              </div>
        </header>
        <section className='bg-white rounded-lg shadow-md p-5 mt-0'>
            <h5 className='text-sm'> Feedback Questions ({questions.length}) </h5>
             { questions.map( (question, index) => {
                 return(
                    <section className={`border shadow py-5 ${ index > 0 ? 'pt-3' : 'pt-0'} p-3 mt-5 border-gray-300 rounded-lg md:w-[75%] mx-auto`}>
                     { index > 0 ?
                        <header className='flex justify-end pt-2 pr-2'>
                           <i 
                             className='bi bi-trash text-red-500 text-lg hover:cursor-pointer' 
                              onClick={ () => deleteQuestion(question.id) }
                            />
                        </header> : null
                     }
                 <div className={`items-center ${ index > 0 ? '-mt-5}' : 'mt-3'}`}>
                     <label htmlFor="q-type" className='text-sm'>
                        Question Type:
                     </label>
                     <div className='w-[450px] mt-2'>  
                        <select
                          value={question.type}
                          onChange={ event => updateQuestionType(question?.id, event.target.value,'type') }
                          className="input-md shadow"
                        >
                          {
                            questionTypes?.map( type => {
                                 return (
                                    <option value={type} key={type}>{type}</option>
                                 )
                            } )
                          }
                        </select>
                    </div>
                 </div>
                 <div className='mt-5 items-center'>
                     <label htmlFor="q" className='text-sm'>
                        Question:
                     </label>
                     <div className='w-[450px] mt-2'>  
                        <input
                           placeholder={'e.g Will you recommend this course to your colleague?'}
                           onChange={ event => updateQuestionType(question?.id, event.target.value,'question') }
                          className="input-md shadow"
                          value={question.question}
                        />
                    </div>
                 </div>
                 <div className='mt-5'>
                     <label htmlFor="q" className='text-sm block'>
                        Answer Format:
                     </label>
                      <AnswerTypeRenderer 
                         type={question.type} questionTypes={questionTypes}
                         id={question.id} updateQuestionType={updateQuestionType}
                         question={question} deleteEmoji={deleteEmoji}
                         setQuestions={setQuestions} questions={questions}
                     />
                    <ul className='flex flex-wrap mt-5'>
                        <li className='flex items-center'>
                            <input 
                               type="checkbox" className='w-[20px] h-[20px]' 
                               checked={question.mandatory} 
                               onClick={ () => updateQuestionType(question.id, !question.mandatory, 'mandatory') }
                            />
                            <label 
                                className='text-sm ml-2'
                            >
                                Mark as mandatory
                            </label>
                        </li>
                        <li className='flex items-center ml-5'>
                            <input 
                                type="checkbox" className='w-[20px] h-[20px]'
                                checked={question.enableComment} 
                                onClick={ () => updateQuestionType(question.id, !question.enableComment, 'enableComment') }
                            />
                            <label className='text-sm ml-2'>
                                Enable comments
                            </label>
                        </li>
                        <li className='flex items-center mt-3'>
                            <input 
                                type="checkbox" className='w-[20px] h-[20px]'
                                checked={question.useAsRating} 
                                onClick={ () => updateQuestionType(question.id, !question.useAsRating, 'useAsRating') }
                            />
                            <label className='text-sm ml-2'>
                               Use this question to calculate and display the average 
                               course rating.
                            </label>
                        </li>
                    </ul>
                 </div>
            </section>
                 )
             })}
            <div className='flex justify-end pr-5 mt-3'>
             <Button 
                bgColor={'bg-customBlue'}
                text={'Add More Question'}
                width={'w-[170px]'}
                onClick={ () => setQuestions([...questions, initialQuestion]) }
            />
         </div>
        </section>
    </div>
  )
}

export default CreateFeedback