import React from 'react'
import ProfileInformation from '../../../accountSetup/components/ProfileInformation'

const CreateClient = () => {
  return (
    <div className='p-5'>
         <ProfileInformation 
           isClient={false}
         />
    </div>
  )
}

export default CreateClient