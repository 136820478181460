import React from "react";
import { Link } from "react-router-dom";

const Button = ({
  renderAs = "button",
  to = "",
  className,
  width,
  bgColor,
  onClick,
  text,
  btn_type = "button",
  textColor,
  ySpacing,
  icon,
  borderColor,
  type = "text",
  ...rest
}) => {
  const _className = className
    ? className
    : `${ySpacing || "py-3"} rounded-lg block ${
        width ? width : "w-[80%]"
      } text-center text-xs ${icon} ${bgColor ? bgColor : "bg-primary"} ${
        textColor ? textColor : "text-white"
      } border ${borderColor ? borderColor : null}`;

  return (
    <>
      {renderAs === "button" ? (
        <button
          type={btn_type}
          onClick={onClick}
          className={_className}
          {...rest}
        >
          &nbsp;{text}
        </button>
      ) : (
        <Link to={to} className={_className} {...rest}>
          {text}
        </Link>
      )}
    </>
  );
};

export default Button;
