import React from 'react'
import { v4 } from 'uuid'

type CounterType = {
  value: number
  increment: () => void
  decrement: () => void
}

const Counter: React.FC<CounterType> = ({value, increment, decrement}) => {

  return (
    <div className='flex items-center'>
       <button type='button' className='active:bg-primary active:text-white  py-0 px-3 bg-gray-100 text-lg border border-gray-300'
        onClick={increment}
       >
         +
        </button>
       <input 
          type="number" disabled className='py-2 text-center text-xs rounded-lg w-[50px] focus:outline-none focus:border-none px-2 border border-gray-200' 
          value={value} 
        />
       <button type='button' className='active:bg-primary active:text-white py-0 px-3 bg-gray-100 text-lg border border-gray-300'
        onClick={decrement}
       >
        -
        </button>
    </div>
  )
}

export default Counter