import React, { useState } from "react";

import Progress from "../../../../../../components/progress/Progress";
// import Search from '../../../../../components/Inputs/Search';
import { useQuery } from "react-query";
import { api } from "../../../../../../api/action-plan";
import ConfirmModal from "../../../zoom-integration/components/ConfirmModal";
import { Link, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import moment from "moment";
import toast from "react-hot-toast";
import Pagination from "../../../../../../components/pagination/Pagination";


const ActionPlanTable = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const {
    data: actionPlans,
    isLoading,
    refetch,
  } = useQuery(
    ["get-all-plans", search, perPage, currentPage],
    async () => {
      const response = await api.getAllActionPlans({
        orgId,
        perPage,
        currentPage,
        search,
      });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteMeeting = async () => {
    if (!selectedAction) return;
    try {
      const response = await api.deleteActionPlan({
        orgId,
        actionPlanId: selectedAction?.plan.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg shadow mt-6">
      <div>
        <h2 className="text-lg text-[#0C0D0F] font-semibold mb-4 border-b">
          Action Plans
        </h2>
      </div>
      <div className="">
        {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#FF9100" />
          </div>
        ) : (
          <div className="mt-4">
            <div className="overflow-x-auto">
              <table className="table-container">
                <thead className="table-header">
                  <tr>
                    <th className="table-head">ID</th>
                    <th className="table-head">Name</th>
                    <th className="table-head">No of Goals</th>
                    <th className="table-head">Key Learnings</th>
                    <th className="table-head">Submit Goals</th>
                    <th className="table-head">Achievement</th>
                    <th className="table-head">Date</th>
                    <th className="table-head">Action</th>
                  </tr>
                </thead>

                <tbody className="table-body text-xs">
                  {actionPlans?.length > 0 ? (
                    actionPlans?.map((action, index) => (
                      <tr key={index}>
                        <td className="table-data ">{index + 1}</td>
                        <td className="table-data ">{action?.plan.name}</td>
                        <td className="table-data ">
                          {action?.plan.numberOfGoals}
                        </td>
                        <td className="table-data ">
                          {action?.plan.keyLearningPerGoal}
                        </td>

                        <td className="table-data">
                          <Progress
                            width={80}
                            value={parseInt(action?.goalPercentage)}
                          />
                        </td>
                        <td className="table-data">
                          <Progress
                            width={80}
                            value={parseInt(action?.achievementPercentage)}
                          />
                        </td>
                        <td className="table-data ">
                          {moment(action.plan.updatedAt).format(
                            "YYYY/MM/DD, h:mm a"
                          )}
                        </td>

                        <td className="table-data flex gap-x-4 items-center">
                          <div className="flex gap-x-4 items-center">
                            <button
                              onClick={() => {
                                sessionStorage.setItem(
                                  "action-plan",
                                  JSON.stringify(action)
                                );
                                navigate(
                                  `/admin/action-plan/edit-action/${action?.plan?.id}`
                                );
                            
                              }}
                            ><i className="bi bi-pencil-fill cursor-pointer"></i></button>
                            {/* <Link
                              to={`/admin/action-plan/edit-action/${action?.plan?.id}`}
                            >
                              <i className="bi bi-pencil-fill cursor-pointer"></i>
                            </Link> */}
                            <i
                              className="bi bi-trash3-fill cursor-pointer"
                              onClick={() => {
                                setSelectedAction(action);
                                setDeleteModal(true);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="table-data" colSpan="12">
                        <p className="text-center">No Data Available</p>
                      </td>
                    </tr>
                  )}
                </tbody>
                {deleteModal && (
                  <ConfirmModal
                    title="Delete Action Plan"
                    description={`Are you sure you want to delete ‘‘${selectedAction?.plan?.name}”?`}
                    onYes={deleteMeeting}
                    onClose={() => setDeleteModal(false)}
                  />
                )}
              </table>
            </div>
          </div>
        )}
      </div>
      <section className={`mt-1 px-3 rouded-lg bg-white`}>
        <Pagination
          data={actionPlans?.meta}
          perPage={perPage}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          setPerPage={setPerPage}
        />
      </section>
    </div>
  );
};

export default ActionPlanTable;
