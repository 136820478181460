import React from "react";
import InvoiceProfile from "./InvoiceProfile";
import SummarySection from "./SummarySection";

import Bundle from "./Bundle";

const Checkout = () => {
  return (
    <div className="">
      <div className="flex">
        <div className="flex flex-col p-4">
          <h3 className="text-xl font-semibold text-[#1D1F2C] mb-4">
            Top Up Credit
          </h3>
          <div className="flex gap-x-2 items-center">
            <p className="text-xs text-[#667085] font-medium">Dashboard</p>
            <p className="text-xs text-[#667085] font-medium">Billing</p>
            <p className="text-xs text-[#667085] font-medium">Invoice</p>
            <p className="text-xs text-[#667085] font-medium">Client Invoice</p>
          </div>
        </div>
      </div>

      <div className="p-4">
        <SummarySection />
      </div>

      <div className="flex gap-x-4 p-4">
        <div className="w-1/4">
          <InvoiceProfile />
        </div>
        <div className="w-3/4">
          <Bundle />
          {/* <Tab tabsData={tabsData} /> */}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
