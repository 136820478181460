import React, {useState} from 'react'
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal'
import { api } from '../../../../../../../../api/billing';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useCustomToast } from '../../../../../../../../components/customtoast/CustomToastProvider';


const DeleteCreditCode = ({openModal, setOpenModal, id, refetch}) => {

    const customToast = useCustomToast()

    const deleteCourseCodeMutation = useMutation(api.deleteCourseCreditCodes, {
        onSuccess: (response) => {
            toast.remove();
            toast.success(response?.message);
            refetch()
            setOpenModal(false)
        },
        onMutate: () => {
            toast.loading("Loading...");
        },
        onError: ({ response }) => {
            toast.remove();
            customToast.error(response.data?.message)
        },
    });

  return (
    <>
      <CenteredModal 
        title={<h5 className='text-md font-semibold bi bi-trash'> &nbsp; Delete Course Credit Code </h5> }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[380px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Yes,  Proceed'}
        btnBgColor={'bg-red-500 w-full ml-3'}
        onClose={ () => deleteCourseCodeMutation.mutate(id) }
      >
         <section className='text-left pb-3 px-5'>
             <h5 className='bi bi-exclamation-triangle text-center text-red-500 text-7xl'></h5>
             <p className='text-sm text-center'>
                 Are you sure you want to delete this course credit code.
                 Note that all courses with this credit code will be affected.
             </p>
         </section>
      </CenteredModal>
    </>
  )
}

export default DeleteCreditCode