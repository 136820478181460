import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Loader from '../../../../../components/loader/Loader';
import EmptyData from '../../../../../components/EmptyData';
import Pagination from '../../../../../components/pagination/Pagination';
import { api } from '../../../../../api/billing';
import userImg from '../../../../../assets/user.PNG';
import { formatCurrency } from '../../billing/site-admin/pages/summary/Summary';

const MonthlyRevenue = () => {

const [perPage, setPerPage] = useState(5);
const [currentPage, setCurrentPage] = useState(1);

const { data: clientsRevenue, isLoading, refetch } = useQuery(
    ["clients monthly revenue"],
    () => { 
        return api.getBillingTransactions({ perPage, currentPage });
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        select: res => res?.data
  });

  useEffect( () => {
    refetch()
  }, [ currentPage, perPage, isLoading] )

  return (
    <div> 
      {
        isLoading ? <Loader /> : 
        clientsRevenue?.data?.length ? 
          clientsRevenue?.data?.map( org => {
            return (
              <div className='flex justify-between items-center  shadow-md p-2 mt-3'>
                <div className='flex items-center'>
                    <img src={userImg} alt="user" className='w-[35px] h-[35px] rounded-full object-cover' />   
                    <h5 className='text-xs pl-1 font-medium'>
                      {org?.user?.name} 
                        <span className='text-[11px] block -mt-1 text-gray-400'>
                          {org?.organization?.name}
                        </span>
                    </h5>
                </div>
                <span className='text-xs font-bold text-green-500'>
                  &nbsp;{formatCurrency(org?.amount)} 
                </span>
             </div>
            )
          })
        :
        <EmptyData text={'There is no any monthly revenue available.'} />
      }
      <Pagination 
          data={clientsRevenue?.meta}
          perPage={perPage}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          setPerPage={setPerPage}
       />
    </div>
  )
}

export default MonthlyRevenue