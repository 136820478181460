import React, { useState } from "react";

const Counter = () => {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  return (
    <div className="flex ml-2 shadow-inner items-center rounded-md border border-gray-200">
      <button
        onClick={handleDecrement}
        className="px-4 py-2 text-sm text-gray-700 border border-gray-200 rounded-l-md focus:outline-none focus:ring-2 focus:ring-gray-300"
      >
        -
      </button>
      <span className="px-4 py-2 text-sm text-gray-700">{count}</span>
      <button
        onClick={handleIncrement}
        className="px-4 py-2 text-sm text-gray-700 border border-gray-200 rounded-r-md focus:outline-none focus:ring-2 focus:ring-gray-300"
      >
        +
      </button>
    </div>
  );
};

export default Counter;
