import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Summary from '../client-admin/pages/summary/Summary'
import Pricing from '../client-admin/pricing/Pricing'
import Invoice from './Invoice/Invoice'
import Checkout from './Invoice/components/Checkout'

const BillingRoutes = () => {
  return (
    <section>
      <Routes>
          <Route path='/' element={ <Navigate to={'/admin/billing/summary'} /> } />
          <Route path='/summary' element={<Summary />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/invoice' element={<Invoice />} />
          <Route path='/top-up' element={<Checkout />} />
      </Routes>
    </section>
  )
}

export default BillingRoutes