import createApiClient from "./api";
import { createDemoGraphicType, createOeganisationProfileType, createOrganisationAppearanceType, createRootAccountType, deleteDemographicType, updateDemograhicType } from "./types/auth.types";

const client = createApiClient()

export const auth = {
    // LOGIN
    login: (payload: any) => client.post("auth/login",{
      ...payload, accountType: 'admin'
    }).then(({ data }) => data),
    //REGISTER
    register: (payload: any) => client.post("auth/register", payload).then(({ data }) => data),
  
    resendEmailVerification: (payload: any) => client.post("auth/resend-email-verification", payload).then(({ data }) => data),
  
    verifyEmail: (payload: any) => client.post("auth/email-verification", payload).then(({ data }) => data),
  
    forgotPassword: (payload: any) => client.post("auth/forgot-password", payload).then(({ data }) => data),
  
    resetPassword: (payload: any) => client.post("auth/reset-password", payload)
    .then(({ data }) => data),
   
    getCountries: () => {
        //external API to get countries and with their states
        const fetchCountries = async () : Promise<any> => {
          const response = await fetch('https://countriesnow.space/api/v0.1/countries/states');
          if (!response.ok) {
            throw new Error('Failed to fetch states');
          }
          return response.json();
        };
        return fetchCountries()
    },

    createRootAccount: (payload: createRootAccountType) => client
    .post("/admin/organizations/root-account", payload)
    .then(({ data }) => data),

    updateRootAccount: (payload: createRootAccountType, id: string) => client
    .patch(`/admin/organizations/root-account/${id}`, payload)
    .then(({ data }) => data),

    createOrganisationProfile: (payload: createOeganisationProfileType): Promise<any> => client
    .post("/admin/organizations", payload).then(({ data }) => data),

    createOrganisationAppearance: (payload: createOrganisationAppearanceType, orgId: string): Promise<any> => client
    .post(`/admin/organizations/${orgId}/appearances`, payload)
    .then(({ data }) => data),

    createDemoGraphics: ({orgId, payload} : createDemoGraphicType): Promise<any> => client
    .post(`/admin/organizations/${orgId}/demographics`, payload)
    .then(({ data }) => data),

    updateDemoGraphics: ({orgId, payload, id} : updateDemograhicType): Promise<any> => client
    .patch(`/admin/organizations/${orgId}/demographics/${id}`, payload)
    .then(({ data }) => data),

    deleteDemoGraphics: ({orgId, id} : deleteDemographicType): Promise<any> => client
    .delete(`/admin/organizations/${orgId}/demographics/${id}`)
    .then(({ data }) => data),

    getDemoGraphics: (orgId: string): Promise<any> => client
    .get(`/admin/organizations/${orgId}/demographics`)
    .then(({ data }) => data),

    setupConfirmation: ({orgId, id} : deleteDemographicType): Promise<any> => client
    .post(`/admin/organizations/${orgId}/confirm-setup`, {userId: id})
    .then(({ data }) => data),

  }; 