import React, { Component } from "react";
import { ErrorRounded } from "@material-ui/icons";

class errorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: { message: "", stack: "" },
      info: { componentStack: "" },
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="w-full flex items-center flex-col justify-center gap-1 text-customOrange"
          style={{ height: "100vh" }}
        >
          <ErrorRounded style={{ fontSize: "70px" }} />
          <h1 className="text-slate-500">
            Something went wrong! Kindly reload the page or contact site
            admin...
          </h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export default errorBoundary;
