import React from 'react'
import Button from '../../../../../../components/Buttons/Button'
import EmptyData from '../../../../../../components/EmptyData'


const ViewResponse = () => {
  return (
    <div className='p-3  h-[100vh]'>
        <header className='flex py-2 rounded-lg px-2 items-cente justify-between'>
            <h5 className='text-md'>
                View Feedback Response  
            </h5>
             <Button 
                bgColor={'bg-customBlue'}
                text={'Back'}
                width={'w-[170px]'} 
                renderAs='link'
                to={-1}
              />
         </header>
         <section className='mt-16'>
             <EmptyData 
                height={500} width={500} 
                text={'No any feedback response recieved.'} 
            />
         </section>
    </div>
  )
}

export default ViewResponse