import createApiClient from "../api/api";
import { assignModuleToCourseType, createCourseType, createModuleType, getModulesType } from "./types/courses.type";

const client = createApiClient()

export const courses = {

    getModules: ({ search, perPage, currentPage }: getModulesType) : Promise<any> => client
    .get(`admin/modules?perPage=${perPage}${ search ? `&search=${search}` : ""
         }&page=${currentPage}`)
    .then(({ data }) => data),

    getCategories: ({perPage}: {perPage: number}) : Promise<any> => client.get(`admin/categories?perPage=${perPage}`)
    .then(({ data }) => data),

    getCreditCodes: ({perPage}: {perPage: number}) : Promise<any> => client.get(`/admin/credit-codes?perPage=${perPage}`)
    .then(({ data }) => data),

    createCourse: (payload: createCourseType): Promise<any> => client.post(`admin/courses`, payload)
    .then(({ data }) => data),

    createModule: (payload: createModuleType) : Promise<any>  => client.post(`admin/modules`, payload)
    .then(({ data }) => data ),

    assignModuleToCourse: (payload: assignModuleToCourseType) => 
        client.post(`admin/courses/${payload?.id}/assign-modules`, payload?.data)
        .then(({ data }) => data),

    getCourses: (
          { search, perPage, currentPage, status, category} :
           getModulesType & { status: 'published' | 'draft' | null, category: string | null }
        ) => 
        client.get(`admin/courses?perPage=${perPage}${ search ? `&search=${search}` : ''}&page=${
            currentPage
        }${ status ? `&status=${status}` : ''}${ category ? `&category=${category}` : ''}`)
        .then(({ data }) => data),  

    getCoursesSummary: () : Promise<any> => client.get(`/admin/courses/get/summary`)
                       .then(({ data }) => data),

    getIndividualCourse: (id: string) : Promise<any> => client.get(`/admin/courses/${id}`)
    .then(({ data }) => data),

    updateCourse: (payload: createCourseType, id: string) => client.patch(`admin/courses/${id}`, payload)
                              .then(({ data }) => data),
                              
    updateModule: (payload: createModuleType, id: string) => client.patch(`admin/modules/${id}`, payload)
                               .then(({ data }) => data),
}