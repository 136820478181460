import createApiClient from "./api";

const client = createApiClient();

export const learners = {
  getLearners_Summary: (orgId) =>
    client
      .get(`/admin/learners/profile/summary?orgId=${orgId}&type=''`)
      .then(({ data }) => data),

  ResetPassword: (payload) =>
    client.post(`/auth/reset-password`, payload).then(({ data }) => data),

  InviteLearner: ({ payload, orgId, cohortId }) =>
    client
      .post(
        `/admin/org/${orgId}/cohorts/${cohortId}/assign-participants`,
        payload
      )
      .then(({ data }) => data),

  getLearners_Profile: ({ orgId, page, perPage, statusFilter, search }) =>
    client
      .get(
        `/admin/learners/profile?orgId=${orgId}&page=${page}&perPage=${perPage}${
          statusFilter && `&statusFilter=${statusFilter}`
        }${search && `&search=${search}`}`
      )
      .then(({ data }) => data),

  DownloadLearners_Profile: ({ orgId }) =>
    client
      .get(`/admin/learners/profile?orgId=${orgId}&page=1&perPage=1000000`)
      .then(({ data }) => data),

  getLearners_Cohort: ({ cohortId, page, perPage, statusFilter, search }) =>
    client
      .get(
        `/admin/learners/cohort?cohortId=${cohortId}&page=${page}&perPage=${perPage}${
          statusFilter && `&statusFilter=${statusFilter}`
        }${search && `&search=${search}`}`
      )
      .then(({ data }) => data),

  DownloadLearners_Cohort: ({ cohortId }) =>
    client
      .get(`/admin/learners/cohort?cohortId=${cohortId}&page=1&perPage=1000000`)
      .then(({ data }) => data),

  getLearners_Path: ({ cohortId, pathId, page, perPage, statusFilter }) =>
    client
      .get(
        `/admin/learners/learning-path?cohortId=${cohortId}&learningPathId=${pathId}&page=${page}&perPage=${perPage}${
          statusFilter && `&statusFilter=${statusFilter}`
        }`
      )
      .then(({ data }) => data),

  DownloadLearners_Path: ({ cohortId, pathId }) =>
    client
      .get(
        `/admin/learners/learning-path/list?cohortId=${cohortId}&learningPathId=${pathId}&page=1&perPage=1000000`
      )
      .then(({ data }) => data),

  getLearners_PathList: ({
    cohortId,
    pathId,
    page,
    perPage,
    statusFilter,
    search,
  }) =>
    client
      .get(
        `/admin/learners/learning-path/list?cohortId=${cohortId}&learningPathId=${pathId}&page=${page}&perPage=${perPage}${
          statusFilter &&
          `&statusFilter=${statusFilter}${search && `&search=${search}`}`
        }`
      )
      .then(({ data }) => data),

  getLearners_Course: ({
    cohortId,
    pathId,
    courseId,
    page,
    perPage,
    statusFilter,
  }) =>
    client
      .get(
        `/admin/learners/course?cohortId=${cohortId}&learningPathId=${pathId}&courseId=${courseId}&page=${page}&perPage=${perPage}${
          statusFilter && `&statusFilter=${statusFilter}`
        }`
      )
      .then(({ data }) => data),

  DownloadLearners_Course: ({ cohortId, pathId, courseId }) =>
    client
      .get(
        `/admin/learners/course/list?cohortId=${cohortId}&learningPathId=${pathId}&courseId=${courseId}&page=1&perPage=1000000`
      )
      .then(({ data }) => data),

  getLearners_CourseList: ({
    cohortId,
    pathId,
    courseId,
    page,
    perPage,
    statusFilter,
    search,
  }) =>
    client
      .get(
        `/admin/learners/course/list?cohortId=${cohortId}&learningPathId=${pathId}&courseId=${courseId}&page=${page}&perPage=${perPage}${
          statusFilter &&
          `&statusFilter=${statusFilter}${search && `&search=${search}`}`
        }`
      )
      .then(({ data }) => data),

  getProfile_Summary: (orgId) =>
    client
      .get(`/admin/learners/profile/summary?orgId=${orgId}`)
      .then(({ data }) => data),

  getCohort_Summary: (cohortId) =>
    client
      .get(`/admin/learners/cohort/summary?cohortId=${cohortId}`)
      .then(({ data }) => data),

  getPath_summary: ({ cohortId, pathId }) =>
    client
      .get(
        `/admin/learners/learning-path/summary?cohortId=${cohortId}&learningPathId=${pathId}`
      )
      .then(({ data }) => data),

  getCourse_Summary: ({ cohortId, pathId, courseId }) =>
    client
      .get(
        `/admin/learners/course/summary?cohortId=${cohortId}&learningPathId=${pathId}&courseId=${courseId}`
      )
      .then(({ data }) => data),

  getFilters: (orgId) =>
    client
      .get(`/admin/learners/grand/filters?orgId=${orgId}`)
      .then(({ data }) => data),
};
