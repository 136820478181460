import LoginForm from "./components/LoginForm";
import BackgroundImage from "../../assets/images/login-img.png";

const Login = () => {

  return (
    <div className={`min-h-screen md:flex`}>
      <div className=" flex justify-center items-center bg-[#0A3059]  w-1/2">
        <img src={BackgroundImage} alt="login" className="h-[450px] w-[350px] border border-white p-4 rounded-[40px]" />{" "}
      </div>
      <div className="grid md:w-1/2 place-content-center bg-white  h-screen">
         <LoginForm />
      </div>
    </div>
  );
};

export default Login;
