// import React from 'react';
// import classNames from 'classnames';

// const ToggleSwitch = ({ checked, onChange }) => {
//   const switchClasses = classNames(
//     'relative inline-block w-12 h-5 rounded-full ',
//     checked ? 'bg-[#4D4D4D]' : 'bg-white border border-[#333333]'
//   );

//   const thumbClasses = classNames(
//     'absolute  w-5 h-5 bg-white rounded-full transform transition-transform border border-[#333333]',
//     checked ? 'translate-x-full' : 'translate-x-0'
//   );
 
//   return (
//     <label className="flex items-center cursor-pointer">
//       <span className={switchClasses}>
//         <input
//           type="checkbox"
//           className="opacity-0 w-0 h-0"
//           checked={checked}
//           onChange={onChange}
//         />
//         <span className={thumbClasses} />
//       </span>
//     </label>
//   );
// };



// export default ToggleSwitch;

import React from 'react';
import classNames from 'classnames';

const ToggleSwitch = ({ checked, onChange }) => {
  const switchClasses = classNames(
    'relative inline-block w-10 h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer'
  );

  const thumbClasses = classNames(
    'absolute  w-6 h-6 bg-white rounded-full border-2 appearance-none cursor-pointer transform transition-transform duration-200 ease-in',
    checked ? 'right-0' : 'right-4'
  );

  return (
    <label className="flex items-center cursor-pointer">
      <span className={switchClasses}>
        <input
          type="checkbox"
          name="toggle"
          className="opacity-0 w-0 h-0"
          checked={checked}
          onChange={onChange}
        />
        <span className={thumbClasses} />
      </span>
      {/* <span className="ml-2 font-medium text-gray-400">Blue</span> */}
    </label>
  );
};

export default ToggleSwitch;

