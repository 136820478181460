import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, scales } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatCurrency } from '../../billing/site-admin/pages/summary/Summary';
import { colors } from './LearnersStage';
import { useQuery } from 'react-query';
import { dashboardApi } from '../../../../../api/dashboard';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display: false
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        callback: (value) => {
          // Convert large numbers to more readable format (e.g., 10000 to 10K)
          if (value >= 1000) {
            return (value / 1000) + 'K';
          }
          return value;
        }
      }
    }
  }
};

const weeks = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];

const Enrollment = ({ cohorts, data, isClient }) => {

  const cohortIds = ( cohorts?.length >= 2 ) ? cohorts?.map( (cohort) => cohort?.value ).join(',') : ''
  const uniqueKey = "client-dashboard-enrollment-rate"
  const { data: enrollmentRate, isLoading, refetch } = useQuery(
    [uniqueKey, { cohorts: cohortIds } ],
    ({queryKey}) => { 
        return cohortIds && dashboardApi.getClientEnrollmentRate(queryKey[1]);
    },
    {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        select: response => response?.cohortData
    });

    useEffect( () => {
      refetch({queryKey: [uniqueKey, { cohorts: cohortIds } ]})
    },[cohorts, cohortIds])

    const _data = [
      [4000, 100, 700, 600, 3000, 3648, 8475, 1576, 7000, 380, 520, 998,300,1000,4000,1000,8000],
      [1000, 1200, 7100, 6100, 2000, 368, 845, 156, 3000, 3830, 5320, 998,1000, 1200, 7100, 6100],
      [4200, 100, 730, 640, 4000, 3748, 8375, 1976, 8000, 5380, 5520, 7098,4000, 3748, 8375, 1976,],
      [7000, 7100, 7700, 6700, 3000, 3648, 8475, 1976, 7000, 3880, 5820, 998,3648, 8475, 1576, 7000],
    ]

    const data_ = {
        labels: weeks.map( week => `Week ${week}`),
        datasets: [...cohorts?.map( (cohort, index) => {
               const cohortEnrollment = enrollmentRate?.find( enrollment => enrollment?.cohort === cohort?.label )
               return {
                fill: true,
                label: cohortEnrollment?.cohort,
                data:  cohortEnrollment?.weeks?.length ? 
                       cohortEnrollment?.weeks?.map( week => week?.count )
                : [],
                borderColor: colors[index],
                borderWidth: 1.5,
                backgroundColor: 'transparent'
              }
          })
        ],
      };

  return (
    <div className='bg-white p-4 rounded-lg shadow'>
        <header className='flex justify-between'>  
             <h5 className='font-bold'>Enrollment</h5>
        </header>
        <p className='text-xs mb-3'>
          Number of learners enrolled
        </p>
        <div className='flex items-center my-3 xl:ml-12'>
          {cohorts?.map( (cohort, index) => {
            const cohortEnrollment = enrollmentRate?.find( enrollment => enrollment?.cohort === cohort?.label )
           return <div className='flex bg-slate-200 px-2 py-1 items-center mr-5'>
                    <span 
                       className={`w-[15px] rounded-full h-[15px]`}
                       style={{backgroundColor: colors[index]}}
                    /> 
                    <span className='ml-2 text-xs capitalize'>
                      {
                        !isLoading ? cohortEnrollment?.cohort : '---' 
                      }
                      </span>
                    <span className='ml-2 text-sm font-medium'>
                       = { !isLoading && formatCurrency( 
                          cohortEnrollment?.weeks?.reduce((acc, item) => acc + item.count, 0) 
                        , false )}
                    </span>
                </div>
           })}        
        </div>
         <Line height={100} options={options} data={data_} />
    </div>
  )
}

export default Enrollment