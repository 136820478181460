import React from 'react';

const ProgressBar = ({ progress }) => {
  const progressBarColor = progress === 100 ? 'bg-green-500' : 'bg-orange-500';

  return (
    <div className="flex items-center">
      <div className=" bg-gray-200 rounded-full h-2 w-10">
        <div
          className={`h-2 rounded-full ${progressBarColor}`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <span className={`ml-3 font-medium ${progress === 100 ? 'text-green-500' : 'text-orange-500'}`}>
        {progress}%
      </span>
    </div>
  );
};

export default ProgressBar;
