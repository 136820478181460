import React, { useState, useMemo } from 'react';
import CenteredModal from '../../../../../../components/Modal/CenteredModal';
import { useQuery } from 'react-query';
import Table from '../../../../../../components/table/Table';
import Pagination from '../../../../../../components/pagination/Pagination';
import EmptyPlaceHolder from '../../../../../../components/EmptyPlaceHolder';
import { learningPath } from '../../../../../../app-api/learningPath';

interface ViewUsersProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  activeCohortId: string | null;
}

interface User {
  participant: {
    user: {
      name: string;
      email: string;
    };
  };
  cohort: {
    status: string;
    enrolledAt: string;
    createdAt: string;
    updatedAt: string;
  };
}

interface Meta {
  page: number;
  perPage: number;
  total: number;
}

interface UsersResponse {
  data: User[];
  meta: Meta;
}

const ViewUsers: React.FC<ViewUsersProps> = ({ openModal, setOpenModal, activeCohortId }) => {

  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const orgId = JSON.parse(sessionStorage.getItem('user') || '{}')?.org?.id;
  
  const { data: users, isLoading, refetch } = useQuery(
    ["cohort users", orgId, activeCohortId, activeCohortId, search, perPage, currentPage ],
    () => {
      return activeCohortId ? learningPath.getCohortUsers(
        { id: orgId, cohortId: activeCohortId, search, perPage, currentPage }
      ) : null;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );


  const columns = useMemo(() => [
    {
      title: "ID",
      selector: null,
      key: "id",
      render: ({ index }: { index: number }) =>
        index + 1 + ((users?.meta?.page || 1) - 1) * (users?.meta?.perPage || perPage),
    },
    {
      title: "Learner Name",
      selector: "name",
      key: "name",
      render: ({ row }: { row: User }) => (
        <span className="">{row?.participant?.user?.name}</span>
      ),
    },
    {
      title: "Email Address",
      selector: "email",
      key: "email",
      render: ({ row }: { row: User }) => (
        <span className="">{row?.participant?.user?.email}</span>
      ),
    },
    {
      title: "Status",
      selector: null,
      key: "status",
      render: ({ row }: { row: User }) => (
        <span className="">{row?.cohort?.status || <EmptyPlaceHolder count={5} />}</span>
      ),
    },
    {
      title: "Enrolled At",
      selector: null,
      key: "enrolledAt",
      render: ({ row }: { row: User }) => (
        <span className="">
          {!row?.cohort?.enrolledAt
            ? <EmptyPlaceHolder count={5} />
            : new Date(row?.cohort?.enrolledAt).toLocaleDateString('en-ng')}
        </span>
      ),
    },
    {
      title: "Date Added",
      selector: null,
      key: "createdAt",
      render: ({ row }: { row: User }) => (
        <span className="">
          {new Date(row?.cohort?.createdAt).toLocaleDateString('en-ng')}
        </span>
      ),
    },
    {
      title: "Last Updated",
      selector: null,
      key: "updatedAt",
      render: ({ row }: { row: User }) => (
        <span className="">
          {new Date(row?.cohort?.updatedAt).toLocaleDateString('en-ng')}
        </span>
      ),
    },
  ], [users?.meta?.page, users?.meta?.perPage, perPage]);

  const tableProps = { isLoading: isLoading, columns, data: users?.data || [] };

  return (
    <>
      <CenteredModal
        title={
          <h5 className='text-md font-semibold bi bi-pencil-square'> 
              &nbsp;View Cohort Users
         </h5>
        }
        bgColor={'bg-white'}
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[850px] h-auto overflow-y-auto'}
        headerClassName={'flex justify-between px-3 pt-2 sticky'}
        withCloseBtn = {false}
        closeButtonLabel={'close'}
        btnBgColor={'bg-secondary'}
        onClose={ null }
      >
        <section className='text-left pb-3 px-5'>
          <div className='flex items-center justify-between mt-2 mb-4'>
            <h4 className='text-sm mt-3 font-semibold'>Total Learners ({users?.meta?.total})</h4>
            <div className='w-[300px]'>
            <input 
                type="search" 
                className='input-style bg-primaryGray w-[300px] mt-4'
                placeholder='&#128269; Search modules....'
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <section>
            <Table
              {...tableProps}
              style={{
                overflowX: 'auto',
              }}
              headerClassName={'border-t bg-gray-100'}
            />
          </section>
          <section className='mt-1 px-3 rounded-lg bg-white'>
            <Pagination
              data={users?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
          </section>
        </section>
      </CenteredModal>
    </>
  );
}

export default ViewUsers;
