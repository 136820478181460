import React from "react";
import Button from "./components/Button";
import Card from "./components/Card";
import ProgressBar from "../../components/ProgressBar";
import CoursesTable from "./components/CoursesTable";
import TopLearners from "./components/TopLearners";
import LearnersTable from "./components/LearnersTable";
import ActiveLearners from "./components/ActiveLearners";
import TopPerformingCoursesSection from "../courses/components/TopPerformingCoursesSection";
import CompletionSection from "../courses/components/CompletionSection";

const Overview = () => {
  return (
    <div className="p-3 space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-black text-lg font-bold">Overview</h3>
        <div>
          <Button>Generate Report</Button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-5">
        <Card className="space-y-1">
          <div className="flex items-center justify-between">
            <h5 className="text-sm font-semibold text-gray-500">
              Active Learners
            </h5>
            <div className="bg-gray-500 rounded-full h-8 w-8"></div>
          </div>
          <h4 className="font-bold text-black text-xl">1000</h4>
          <h5 className="text-sm font-semibold text-green-500">
            10%{" "}
            <span className="font-light text-gray-500 text-xs">+150 today</span>
          </h5>
        </Card>
        <Card className="space-y-1">
          <div className="flex items-center justify-between">
            <h5 className="text-sm font-semibold text-gray-500">
              Total Courses
            </h5>
            <div className="bg-blue-500 rounded-full h-8 w-8"></div>
          </div>
          <h4 className="font-bold text-black text-xl">1000</h4>
          <h5 className="text-sm font-semibold text-green-500">
            10%{" "}
            <span className="font-light text-gray-500 text-xs">+150 today</span>
          </h5>
        </Card>
        <Card className="space-y-1">
          <div className="flex items-center justify-between">
            <h5 className="text-sm font-semibold text-gray-500">
              Total Learners
            </h5>
            <div className="bg-green-500 rounded-full h-8 w-8"></div>
          </div>
          <h4 className="font-bold text-black text-xl">1000</h4>
          <h5 className="text-sm font-semibold text-green-500">
            10%{" "}
            <span className="font-light text-gray-500 text-xs">+150 today</span>
          </h5>
        </Card>
        <Card className="space-y-1">
          <div className="flex items-center justify-between">
            <h5 className="text-sm font-semibold text-gray-500">
              Total Cohorts
            </h5>
            <div className="bg-orange-500 rounded-full h-8 w-8"></div>
          </div>
          <h4 className="font-bold text-black text-xl">1000</h4>
          <h5 className="text-sm font-semibold text-green-500">
            10%{" "}
            <span className="font-light text-gray-500 text-xs">+150 today</span>
          </h5>
        </Card>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <Card className={"grid col-span-1 space-y-5"}>
          <div>
            <h3 className="text-black text-xl font-semibold">Completions</h3>
            <h5 className="text-sm font-semibold text-gray-500">
              Completion Target
            </h5>
          </div>
          <div className="space-y-2">
            <CompletionSection />
          </div>
        </Card>
        <Card className={"col-span-2"}>
          <h5 className="text-lg font-semibold">Active Learners</h5>
          <p className="text-xs text-gray-800">Active and inactive</p>
          <section>
            <ActiveLearners />
          </section>
        </Card>
      </div>
      <div className="grid grid-cols-6 space-x-3">
        <div className="col-span-4 shadow p-3 rounded-lg">
          <TopPerformingCoursesSection />
        </div>
        <div className="col-span-2 shadow h-auto">
          <TopLearners />
        </div>
      </div>
      <div className="mt-3">
        <LearnersTable />
      </div>
    </div>
  );
};

export default Overview;
