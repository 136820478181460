import React from 'react'
import CenteredModal from '../../../../../../../../../components/Modal/CenteredModal'
import { api } from '../../../../../../../../../api/roles-and-permissions'
import toast from 'react-hot-toast'
import { useMutation } from 'react-query'

const DeleteRoleModal = ({openModal, setOpenModal, activeRoleId, refetch}) => {

    const deleteRoleMutation = useMutation(api.deleteRole, {
        onMutate: () => {
          toast.loading('Loading...')
        },
        onError: (error) => {
            toast.remove()
            if(error?.response?.message){
              toast.error(error?.response?.data?.message);
            } else toast.error('Error! an unknown error occured.');
        },
        onSuccess: response => { 
           toast.remove()
           toast.success('User Role deleted successfully!')
           refetch()
           setOpenModal(false)
        } 
      })

  return (
    <>
      <CenteredModal 
        title={<h5 className='text-md font-semibold bi bi-trash'> &nbsp; Delete User Role </h5> }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[380px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Yes,  Proceed'}
        onClose={ () => deleteRoleMutation.mutate(activeRoleId) }
        btnBgColor={'bg-red-500 w-full ml-3'}
      >
         <section className='text-left pb-3 px-5'>
             <h5 className='bi bi-exclamation-triangle text-center text-red-500 text-7xl'></h5>
             <p className='text-md text-center'> Are you sure you want to delete this role  </p>
         </section>
      </CenteredModal>
    </>
  )
}

export default DeleteRoleModal